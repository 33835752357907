import TenantIcon from '@material-ui/icons/ViewList';

import TenantList from './TenantList';
import TenantCreate from './TenantCreate';
import TenantEdit from './TenantEdit';

export default {
  list: TenantList,
  create: TenantCreate,
  edit: TenantEdit,
  icon: TenantIcon,
};

import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {ReferenceArrayInput, Title} from 'react-admin';
import {downloadFile, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import NotifyForm from "../../components/NotifyForm";

class FullDayCalendarOffTemplate extends React.Component {
    state = {
        unitIds: [],
        loading: false
    };

    getReportUrl = () => {
        this.setState({loading: true})
        downloadFile(`${env.baseURL}/reports/full-day-calendar-off-template?` +
            `unitIds=` + this.state.unitIds, 'tkb_nghi_ban_tru.xlsx')
            .then(() => {
                this.setState({loading: false})
            }).catch(() => {
            this.setState({loading: false})
        })
    }
    validate = (values) => {
        const errors = {};
        this.setState({
            unitIds: values.unitIds
        })

        return errors;
    }

    fullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl()
    }

    render() {
        return (
            <Card>
                <Title title="resources.full-day-calendar-off-template.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                isLoading={this.state.loading}
                                fullDayClazzes={this.fullDayOnly}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp*</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                         filter={{
                                                             clazzOnly: true,
                                                             schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''
                                                         }}
                                                         perPage={999} {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceArrayInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default FullDayCalendarOffTemplate


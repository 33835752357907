import React from 'react';
import { List, Datagrid, Filter, TextField, TextInput, BooleanField } from 'react-admin';
import {DateTimeField} from "../../components";

const SchoolYearFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const SchoolYearList = props => (
  <List filters={<SchoolYearFilter />} {...props} exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="enable" />
      <DateTimeField source="startDate" format="DD-MM-YYYY"/>
      <DateTimeField source="endDate" format="DD-MM-YYYY"/>
    </Datagrid>
</List>
);

export default SchoolYearList

import React from 'react';
import { connect } from 'react-redux';
import inflection from 'inflection';
import compose from 'recompose/compose';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';

import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/ViewList';
import SubMenuIcon from '@material-ui/icons/AddBox';
import withWidth from '@material-ui/core/withWidth';

import { DashboardMenuItem, MenuItemLink, getResources, withDataProvider, GET_LIST, translate } from 'react-admin';
import { withRouter } from 'react-router-dom';

import { menus } from './menu-data';

const styles = {
  menu: {
    paddingLeft: '6px',
    paddingRight: '6px',
    maxHeight: '44px',
  },
  menuIcon: {
    marginRight: '0px',
  },
  menuContainer: {
    height: window.innerHeight - 100,
    overflowY: 'scroll'
  }
};

const translateMenuTitle = (menu, translate) =>
  translate(`menus.${menu.name}.title`, {
    smart_count: 2,
    _: inflection.humanize(inflection.pluralize(menu.name)),
  });

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
          smart_count: 2,
          _: resource.options.label,
        })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

class CustomMenuItem extends React.Component {
  state = { open: false };

  handleClick = e => {
    e.preventDefault()
    this.setState({ open: !this.state.open });
  };

  render() {
    const { menu, dense, onMenuClick, classes, translate, width } = this.props;
    if ((width === 'sm' || width === 'md') && window.menuSideBarOpen === false) {
      return null;
    }

    return (
      <React.Fragment >
        <ListItem button key={menu.name} onClick={this.handleClick} className={classes.menu} >
          <ListItemIcon className={classes.menuIcon} >
            {menu.icon ? <menu.icon /> : <MenuIcon />}
          </ListItemIcon>
          <ListItemText primary={translateMenuTitle(menu, translate)} />
          { this.state.open ? <ArrowDownIcon /> : <ArrowRightIcon /> }
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {
            menu.children && menu.children.map(resource => (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translatedResourceName(resource, translate)}
                leftIcon={
                  // resource.icon ? <resource.icon /> : <SubMenuIcon />
                  <SubMenuIcon />
                }
                onClick={onMenuClick}
                dense={dense}
              />
            ))
          }
        </Collapse>
      </React.Fragment>
    )
  }
}

class CustomMenu extends React.Component {
  state = {
    loaded: false,
    mySubMenus: [],
  };

  componentDidMount() {
    const { loaded } = this.state;
    if (loaded) {
      return;
    }

    const { dataProvider } = this.props;
    const options = {
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'not-used', order: 'DESC'},
      filter: {}
    }
    
    dataProvider(GET_LIST, 'my-submenus', options)
    .then((response) => {
      this.setState({ loaded: true, mySubMenus: response.data })
    })
  }

  render() {
    const { loaded } = this.state;
    const { onMenuClick } = this.props;

    if (!loaded) {
      return null;
    }

    const mySubMenuNames = this.state.mySubMenus.map(subMenu => subMenu.name);

    let myMenus = menus.map(menu => {
      const cloned = JSON.parse(JSON.stringify(menu))
      cloned.children = menu.children && menu.children.filter(child => mySubMenuNames.includes(child.name))
      return cloned;
    })

    myMenus = myMenus.filter(menu => (menu.children && menu.children.length > 0) )

    return (
      <React.Fragment>
        <div id={'menuContainer'} className={this.props.classes.menuContainer} >
         <DashboardMenuItem style={{paddingLeft: 6}} onClick={onMenuClick} />
        {
          myMenus.map(menu => (
            <CustomMenuItem key={menu.name} menu={menu} {...this.props} />
          ))
        }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(compose(
    translate,
    withStyles(styles),
    withDataProvider,
    connect(mapStateToProps),
    withWidth()
)(CustomMenu))


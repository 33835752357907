import React from 'react';
import {CardActions, CreateButton, Datagrid, DatagridBody, Filter, List, TextField, TextInput} from 'react-admin';
import {
    BillAuthStatusField,
    DateTimeField,
    DownloadFileContentButton,
    EditButtonWithAuthStatus
} from "../../components";
import ApproveDisplayBtn from "./ApproveDisplayBtn";
import DeleteBillBatchButton from "../../components/DeleteBillBatchButton";
import EditPriorityBtn from "./EditPriorityBtn";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BillBatchPayBtn from "./BillBatchPayBtn";
import {connect} from "react-redux";

const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath}) => (
    <TableRow key={id}>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const BillBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const BillBatchList = ({permissions, ...props}) => (
    <List filters={<BillBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <CustomDatagrid>
            <TextField source="id"/>
            <DateTimeField source="month" format="DD-MM-YYYY"/>
            <DateTimeField source="dueDate" format="DD-MM-YYYY"/>
            <TextField source="description"/>
            <TextField source="fullDescription" width={'20%'}/>
            <EditPriorityBtn label={'Độ ưu tiên'}/>
            <BillAuthStatusField source="authStatus"/>
            <DownloadFileContentButton width={'20px'}/>
            <EditButtonWithAuthStatus width={'20px'}/>
            <ApproveDisplayBtn width={'20px'}/>
            <BillBatchPayBtn permissions={permissions} width={'20px'}/>
            <DeleteBillBatchButton width={'20px'}/>
        </CustomDatagrid>
    </List>
);

export default connect(null, null)(BillBatchList);

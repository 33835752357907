import React from 'react';
import { List, Datagrid, Filter, TextField, TextInput } from 'react-admin';

const EventGroupFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const EventGroupList = props => (
  <List filters={<EventGroupFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
</List>
);

export default EventGroupList

import React, {useEffect, useState} from 'react';
import env from "../../env";
import {refreshView} from "react-admin";
import {compose} from 'redux';
import {connect} from 'react-redux';

const ViewFile = (props) => {

    const [fileName, setFileName] = useState(null);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        const {fileName} = props.record;
        if (fileName != null) {
            setFileName(fileName)
        }
        setShowBtn(fileName != null)
    }, [props.record.fileName])

    return (
        <>
            {showBtn &&
            <a className='button' onClick='event.preventDefault();' target="_blank" rel="noopener noreferrer" href={`${env.baseURL}/introduction-files/${fileName}`} download="file">
                Xem File
            </a>
            }
        </>
    )
}


export default compose(connect(null, {refreshView}))(
    ViewFile
);

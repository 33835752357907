import React from 'react';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';

/** 
 * 1. @material-ui/core/Grid does not pass 'translate' to its children 
 * 2. ==> <Input> components (TextInput, etc): its label is *NOT* translated
 * 3. CustomGrid will pass all props, (including 'translate' prop) to its children 
*/
const CustomGrid = ({children, ...props}) => (
  <Grid {...props} >
    { 
      React.Children.map(
        children, 
        (child) => child ? React.cloneElement(child, { ...props }) : null
      ) 
    }
  </Grid>
)

export default translate(CustomGrid)

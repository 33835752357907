import SchoolYearIcon from '@material-ui/icons/ViewList';

import SchoolYearList from './SchoolYearList';
import SchoolYearCreate from './SchoolYearCreate';
import SchoolYearEdit from './SchoolYearEdit';

export default {
  list: SchoolYearList,
  create: SchoolYearCreate,
  edit: SchoolYearEdit,
  icon: SchoolYearIcon,
};

import AttendanceResultSummaryIcon from '@material-ui/icons/ViewList';

import AttendanceResultSummaryList from './AttendanceResultSummaryList';
import AttendanceResultSummaryEdit from './AttendanceResultSummaryEdit';

export default {
  list: AttendanceResultSummaryList,
  edit: AttendanceResultSummaryEdit,
  icon: AttendanceResultSummaryIcon,
};

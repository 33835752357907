import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import CustomerHealthFields from './CustomerHealthFields'

export const CustomerHealthCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list">
            <Grid container spacing={8}>
                <CustomerHealthFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default CustomerHealthCreate

import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears} from '../../components';
import env from '../../env';
import moment from "moment";

const SoLuongAnBanTru = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        unitId: '',
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/soLuongAnBanTru?unitId=${state.unitId}&fromDate=` + moment(state.fromDate).format('YYYY-MM-DD')
            + '&toDate=' + moment(state.toDate).format('YYYY-MM-DD');
    };

    const excelReport = () => {
        downloadFile(getReportUrl(), 'so_luong_suat_an.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.soLuongAnBanTru.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <ReferenceInput label="resources.soLuongAnBanTru.unitId" source="unitId" reference="baseUnits"
                                        allowEmpty
                                        {...props}
                                        filter={{schoolYear: schoolYears[0].id, isFullDay: true}}
                                        sort={{field: 'name', order: 'ASC'}}
                                        perPage={999}
                        >
                            <AutocompleteInput optionText="name"/>
                        </ReferenceInput>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default SoLuongAnBanTru


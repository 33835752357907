import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  icon: {
    fontSize: 18,
  },
};

class RelationshipMigrationBatchValidation extends Component {

  render() {
    return (
        <div style={classes.root}>
          <Paper style={classes.paper}>
            <Table style={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Hợp Lệ</TableCell>
                  <TableCell align="right">Mô tả lỗi</TableCell>
                  <TableCell align="right">STT</TableCell>
                  <TableCell align="right">Mã VinaID</TableCell>
                  <TableCell align="right">Mã Bộ GD&ĐT</TableCell>
                  <TableCell align="right">Họ Tên</TableCell>
                  <TableCell align="right">Ngày sinh</TableCell>
                  <TableCell align="right">Giới tính</TableCell>
                  <TableCell align="right">Lớp</TableCell>
                  <TableCell align="right">Họ Tên Cha</TableCell>
                  <TableCell align="right">SĐT Cha</TableCell>
                  <TableCell align="right">Ho Tên Mẹ</TableCell>
                  <TableCell align="right">SĐT Mẹ</TableCell>
                  <TableCell align="right">Họ Tên Giám hộ</TableCell>
                  <TableCell align="right">SĐT Giám hộ</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        {row.valid &&
                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                        }
                        {!row.valid &&
                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                        }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.errorMessage}
                      </TableCell>
                      <TableCell align="right">{row.stt}</TableCell>
                      <TableCell align="right">{row.customerId}</TableCell>
                      <TableCell align="right">{row.internalCode}</TableCell>
                      <TableCell align="right">{row.customerName}</TableCell>
                      <TableCell align="right">{row.dob}</TableCell>
                      <TableCell align="right">{row.gender}</TableCell>
                      <TableCell align="right">{row.unitName}</TableCell>
                      <TableCell align="right">{row.fatherName}</TableCell>
                      <TableCell align="right">{row.fatherPhone}</TableCell>
                      <TableCell align="right">{row.motherName}</TableCell>
                      <TableCell align="right">{row.motherPhone}</TableCell>
                      <TableCell align="right">{row.guardianName}</TableCell>
                      <TableCell align="right">{row.guardianPhone}</TableCell>

                    </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={this.props.total}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                backIconButtonprops={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonprops={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          </Paper>
        </div>
    );
  }
}

export default RelationshipMigrationBatchValidation;

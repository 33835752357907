import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

class CustomerSubjectResultBatchValidation extends Component {

    renderTableHeader(subjects) {
        let table = [];
        for (const [key, value] of Object.entries(subjects)) {
            table.push(<TableCell align="right">
                {key}
            </TableCell>);
            for (let i = 0; i < value.length - 1; i++) {
                table.push(<TableCell>
                </TableCell>);
            }
        }
        return table;
    }
    renderTableSubHeader(subjects) {
        let table = [];
        for (const [key, value] of Object.entries(subjects)) {
            for (let i = 0; i < value.length; i++) {
                table.push(<TableCell>
                    {value[i]}
                </TableCell>);
            }
        }
        return table;
    }
    renderTableRow(subjectResults) {
        let table = [];
        for (let j = 0; j < subjectResults.length; j++) {
            let match = false;

            let length = 0;
            for (const [key, value] of Object.entries(this.props.subjects)) {
                length = value.length
                const subjectResult = subjectResults[j];
                if (subjectResult.subjectName === key) {
                    match = true
                    const results = subjectResult.resultMap;
                    for (let i = 0; i < length; i++) {
                        const displayResult = results[value[i]]
                        table.push(<TableCell align="right">
                            {displayResult ? displayResult : ''}
                        </TableCell>);
                    }
                    break;
                }
            }

            if (!match) {
                for (let k = 0; k < length; k++) {
                    table.push(<TableCell align="right">
                    </TableCell>);
                }
            }
        }

        return table;
    }

    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell align="right">Hợp Lệ</TableCell>
                                <TableCell align="right">Mã lớp</TableCell>
                                <TableCell align="right">Lớp</TableCell>
                                <TableCell align="right">Mã học sinh</TableCell>
                                <TableCell align="right">Họ Tên</TableCell>
                                {this.renderTableHeader(this.props.subjects)}

                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                {this.renderTableSubHeader(this.props.subjects)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                        {row.order}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.valid &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {!row.valid &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="right">{row.unitId}</TableCell>
                                    <TableCell align="right">{row.unitName}</TableCell>
                                    <TableCell align="right">{row.customerId}</TableCell>
                                    <TableCell align="right">{row.fullName}</TableCell>
                                    {this.renderTableRow(row.subjects)}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default CustomerSubjectResultBatchValidation;

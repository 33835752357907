import React, {Component} from 'react';
import {Button} from 'react-admin';
import env from "../env";
import PrintIcon from '@material-ui/icons/Print';
import {printContent, showErrorNotify} from '../components'

const PrintConfirmPaymentButton = (props) => {
    const {record} = props
    const handleClick = () => {
        if (record) {
            printContent(`${env.baseURL}/api/paymentViews/print-confirm?paymentId=` + record.id)
        } else {
            showErrorNotify("Vui lòng chọn hoá đơn")
        }
    };

    return (
        <Button style={{fontSize: '0.7rem'}} onClick={handleClick} label="ra.action.printConfirmPayment">
            <PrintIcon/>
        </Button>
    );
}

export default PrintConfirmPaymentButton;

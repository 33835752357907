import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, TextInput, required } from 'react-admin';

const EventLocationFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="detail" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="enabled" {...props} />
    </Grid>
  </React.Fragment>
)

export default EventLocationFields
import AccessDeviceIcon from '@material-ui/icons/ViewList';

import FoodMenuBatchList from './FoodMenuBatchList';
import IntroductionFileCreate from './FoodMenuBatchCreate';
import IntroductionFileEdit from './FoodMenuBatchEdit';

export default {
  list: FoodMenuBatchList,
  create: IntroductionFileCreate,
  edit: IntroductionFileEdit,
  icon: AccessDeviceIcon,
};

import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import {required, TextInput, translate} from 'react-admin';

const styles = {
    checkboxGroup: {
        "& > fieldset > div ": {
            flexDirection: 'column',
        }
    }
}

const EventBatchFields = ({classes, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>

    </React.Fragment>
)

export default compose(
    translate,
    withStyles(styles)
)(EventBatchFields)

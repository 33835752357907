import React, {Fragment} from 'react';
import {Button, fetchEnd, fetchStart, refreshView, showNotification,} from 'react-admin';
import {Grid} from '../components'
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './popup.css';
import {change, submit} from "redux-form";
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';

const styles = {
    suggestDialog: {
        zIndex: '10 !important',
    }
};
const BillDetailDialog = (props) => {
    const {showDialogDetail, billDetail, handleCloseDialogDetail, classes,} = props;
    const formatNumber = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
    }

    return (
        <Fragment>
            <Dialog
                className={classes.suggestDialog}
                fullWidth={true}
                disableBackdropClick={true}
                maxWidth='md'
                open={showDialogDetail}
                onClose={handleCloseDialogDetail}
                aria-label="Chi tiết"
            >
                {billDetail &&
                <>
                    <DialogTitle>Chi tiết khoản thu {billDetail.description} </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0}>
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Họ Tên: <span style={{fontWeight: 'bold'}}>{billDetail.customerName}</span>
                                    </span>
                                <span style={{width: '25%', display: 'inline-block'}}>
                                         Lớp: <span style={{fontWeight: 'bold'}}>{billDetail.clazzName}</span>
                                    </span>
                                <span style={{width: '25%', display: 'inline-block'}}>
                                         Mã VinaID: {billDetail.customerId}
                                    </span>
                            </div>
                            {billDetail.fullDay &&
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Vắng tháng trước: {billDetail.absentDay}
                                    </span>
                                <span style={{width: '50%', display: 'inline-block'}}>
                                         Dự thu tháng này: {billDetail.payDay}
                                    </span>
                            </div>
                            }

                            <table id={'tblPopup'}>
                                <tr>
                                    <th style={{width: '30%'}}>Nội dung</th>
                                    <th style={{width: '10%'}}>Số lượng</th>
                                    <th style={{width: '10%'}}>Số tiền</th>
                                    <th style={{width: '10%'}}>Miễn giảm</th>
                                    <th style={{width: '10%'}}>Thành tiền</th>
                                </tr>
                                {billDetail.billItems && billDetail.billItems.map((bi, idx) => (
                                    <tr>
                                        <td>{bi.productName}</td>
                                        <td>{bi.quantity}</td>
                                        <td>{formatNumber(bi.itemPrice)}</td>
                                        <td>{formatNumber(bi.itemReduce)}</td>
                                        <td>{formatNumber(bi.itemTotal)}</td>
                                    </tr>
                                ))
                                }
                                <tr style={{fontWeight: 'bold'}}>
                                    <td colSpan={4}>Tổng thu</td>
                                    <td>{formatNumber(billDetail.totalPayment)}</td>
                                </tr>
                            </table>
                        </Grid>
                    </DialogContent>
                </>
                }

                <DialogActions>
                    <Button label="ra.action.close" onClick={handleCloseDialogDetail}>
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView,
    showNotification
};
export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    BillDetailDialog
);

import React from 'react';
import {CardActions, CreateButton, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {
    BillAuthStatusField,
    DateTimeField,
    DownloadFileContentButton,
    EditButtonWithAuthStatus
} from "../../components";
import ApproveDisplayBtn from "./ApproveDisplayBtn";
import DeleteBillBatchtButton from "../../components/DeleteBillBatchButton";


const BillBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const ContributionBillBatchList = props => (
    <List filters={<BillBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <DateTimeField source="month" format="DD-MM-YYYY"/>
            <DateTimeField source="dueDate" format="DD-MM-YYYY"/>
            <TextField source="description"/>
            <TextField source="fullDescription"/>
            <BillAuthStatusField source="authStatus"/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
            <ApproveDisplayBtn />
            <DeleteBillBatchtButton />
        </Datagrid>
    </List>
);

export default ContributionBillBatchList

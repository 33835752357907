import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CircularProgress, Grid} from '@material-ui/core'
import {
    AutocompleteInput, DateInput,
    Edit,
    REDUX_FORM_NAME,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {
    CustomToolbar,
    FileUploadComponent,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import {post} from "axios";
import Swal from "sweetalert2";
import {change} from "redux-form";
import CustomerHealthBatchValidation from "./CustomerHealthBatchValidation";
import {Redirect} from 'react-router-dom';

export class CustomerHealthBatchEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            batchId: props.id,
            showValidateBtn: false,
            showValidationData: false,
            validationData: [],
            page: 0,
            schoolYearId: '',
            ordinal: 1,
            rowsPerPage: 25,
            showData: [],
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
        this.setShowData = this.setShowData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeSchoolYear = (e, val) => {
        this.setState({schoolYearId: val});
    }

    changeOrdinal = (e,val) => {
        this.setState({ordinal: val});
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (this.state.file == null) {
            return;
        }
        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        this.fileUpload(this.state.file).then((response) => {
            if (response.data.success) {
                const responseData = response.data;
                this.setState({
                    showValidationData: true,
                    showValidateBtn: true,
                    validationData: responseData.data,
                    foodTypes: responseData.foodTypes,
                    isLoading: false
                });
                // one of record is not valid
                if (!responseData.valid) {
                    // show noti  fication here
                    showWarningNotify("Có dữ liệu lỗi hoặc chưa đúng thông tin, vui lòng kiểm tra lại thông tin dữ liệu!");
                    this.setState({
                        showValidateBtn: false,
                    });
                } else {
                    showSuccessNotify("Toàn bộ dữ liệu đều đúng, ấn nút Duyệt để lưu!");
                }
                const startIdx = this.state.page * this.state.rowsPerPage;
                const endIdx = (this.state.page + 1) * this.state.rowsPerPage;
                this.setShowData(startIdx, endIdx, null);
            } else {
                this.setState({
                    isLoading: false,
                    showValidationData: false,
                    validationData: [],
                });
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
            }
        })
            .catch(error => {
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                this.setState({
                    isLoading: false,
                    showValidationData: false,
                    validationData: [],
                })
            });
    }

    fileUpload(file) {
        const url = `${env.baseURL}/api/customerHealthBatchUpload/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', this.state.batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    handleChangePage(event, newPage) {
        const startIdx = newPage * this.state.rowsPerPage;
        const endIdx = (newPage + 1) * this.state.rowsPerPage;
        this.setShowData(startIdx, endIdx);
        this.setState({page: newPage});
    }

    handleChangeRowsPerPage(event) {
        this.setState({rowsPerPage: event.target.value});
        const startIdx = this.state.page * event.target.value;
        const endIdx = (this.state.page + 1) * event.target.value;
        this.setShowData(startIdx, endIdx);
    }

    setShowData(startIdx, endIdx) {
        const data = [];
        let filteredData = this.state.validationData;
        if (endIdx > filteredData.length) {
            endIdx = filteredData.length;
        }
        for (let i = startIdx; i < endIdx; i++) {
            data.push(filteredData[i]);
        }

        this.setState({showData: data});
    }

    onSubmit(e) {
        e.preventDefault();
        const data = this.state.validationData
        const rq = {
            data,
            batchId: this.state.batchId,
            ordinal: this.state.ordinal
        };
        if (data.length === 0) {
            showInfoNotify("Không có dữ liệu hợp lệ để duyệt");
            return;
        }
        Swal.fire({
            title: "Bạn chắc chắn muốn duyệt lô khám sức khoẻ?",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                if (result.value) {
                    this.setState({
                        isLoading: true
                    });
                    showInfoNotify("Đang duyệt, vui lòng đợi trong giây lát!");
                    this.state.showValidateBtn = false;
                    this.forceUpdate()
                    this.saveBatchItem(rq).then(rs => {
                        if (rs.data.success) {
                            const {dispatch} = this.props;
                            dispatch(change(REDUX_FORM_NAME, 'authStatus', 'AUTHORIZED'));
                            showSuccessNotify("Đã duyệt file thành công!");
                            this.setState({
                                showUploadFile: false,
                                isLoading: false,
                                showValidationData: false,
                                validationData: [],
                                showData: [],
                                isRedirect: true
                            })
                        }
                    })
                        .catch(error => {
                            showErrorNotify("Duyệt file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                            this.setState({
                                isLoading: false,
                            })
                        });
                }
            });
    }

    saveBatchItem(data) {
        const url = `${env.baseURL}/api/customerHealthBatchUpload/save-item`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        return post(url, data, config)
    }

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };


    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/customerHealthBatches'/>;
        }
        const ordinalChoices = Array(5).fill().map((_, i) => ({id: i + 1, name: `${i + 1}`}));

        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="description" validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <ReferenceInput source="schoolYearId"
                                            validate={required()}
                                            {...this.props}
                                            reference="schoolYears"
                                            sort={{field: 'name', order: 'DESC'}}
                                            onChange={(e, value) => this.changeSchoolYear(e, value)}
                                            perPage={999}>
                                <AutocompleteInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectInput label='Lần khám thứ'
                                         defaultValue={1}
                                         source='ordinal'
                                         validate={required()}
                                         choices={ordinalChoices}
                                         onChange={this.changeOrdinal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="Ngày khám" source="medicalExaminationDate" validate={required()} {...this.props} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        {
                            this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                     onFileUpload={this.onFileUpload}
                                                     showValidateBtn={this.state.showValidateBtn}
                                                     onSubmit={this.onSubmit}
                                />
                                {
                                    this.state.isLoading && <CircularProgress color="secondary"/>
                                }
                            </Grid>
                        }

                        {
                            this.state.showValidationData && <CustomerHealthBatchValidation
                                data={this.state.showData}
                                foodTypes={this.state.foodTypes}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                handleChangePage={this.handleChangePage}
                                total={this.state.validationData.length}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                        }
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default connect(null, null)(CustomerHealthBatchEdit);

import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, TextInput, required, DateInput } from 'react-admin';

const SchoolYearFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="enable" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <DateInput source="startDate" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <DateInput source="endDate" validate={required()} {...props} />
    </Grid>
  </React.Fragment>
)

export default SchoolYearFields
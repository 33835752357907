import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import {showErrorNotify, showSuccessNotify} from "../../components";
import axiosInstance from "../../api/axios";

const AuthorizesSelectedProfileButton = (props) => {

    useEffect(() => {

    }, [props.selectedIds])

    function handleAuthorized() {
        const url = `/api/profileChangeLogs/accept`;
        axiosInstance.put(url, props.selectedIds).then(rs => {
            if (rs.data.success) {
                showSuccessNotify("Duyệt dữ liệu thành công");
                window.location.reload()
            }
        }, err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        });
    }

    function handleReject() {
        const url = `/api/profileChangeLogs/reject`;
        axiosInstance.put(url, props.selectedIds).then(rs => {
            if (rs.data.success) {
                showSuccessNotify("Từ chối duyệt thành công");
                window.location.reload()
            }
        }, err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        });
    }


    return (
        <>
            <Button variant={'outlined'} style={{marginRight: 10}}
                    onClick={handleAuthorized} label={'Duyệt'}>
            </Button>
            <Button variant={'outlined'}
                    onClick={handleReject} label={'Từ chối'}>
            </Button>
        </>
    )
}


export default connect(undefined, {refreshView})(AuthorizesSelectedProfileButton)

import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';

class BaoCaoThuHocPhiDoiTacOnline extends React.Component {
    state = {
        htmlReportUrl: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        paymentGatewayId: '',
    };

    getReportUrl = (type) => {
        return `${env.baseURL}/reports/baoCaoThuHocPhiDoiTacOnline?type=` + type +
            `&fromDate=` + this.state.fromDate +
            `&paymentGatewayId=` + this.state.paymentGatewayId +
            `&toDate=` + this.state.toDate;
    }

    excelReport = () => {
        downloadFile(this.getReportUrl('XLS'), 'bao_cao_thu_hoc_phi_doi_tac_online.xlsx')
    };

    validate = (values) => {
        const errors = {};
        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }
        // Copy input values to params
        this.setState(values);

        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="resources.baoCaoThuHocPhiDoiTacOnline.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput
                                label="resources.paymentGatewayConfigurations.name"
                                source="paymentGatewayId" reference="paymentGatewayConfigurations"
                                perPage={50} validate={required()} allowEmpty
                            >
                                <AutocompleteInput optionText="paymentGateway"/>
                            </ReferenceInput>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default BaoCaoThuHocPhiDoiTacOnline;


import InternalCodeListIcon from '@material-ui/icons/ViewList';

import CustomerInternalCodeList from './CustomerInternalCodeList';
import CustomerInternalCodeEdit from './CustomerInternalCodeEdit';

export default {
    list: CustomerInternalCodeList,
    edit: CustomerInternalCodeEdit,
    icon: InternalCodeListIcon,
};

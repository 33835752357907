import React from 'react';
import { Create, SimpleForm, BooleanInput, TextInput } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'

export const CustomerCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} >
          <TextInput source="name" />
        </Grid>
        <Grid item xs={12} sm={6} >
          <BooleanInput source="enabled" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CustomerCreate

import MessageIcon from '@material-ui/icons/ViewList';

import MessageList from './MessageList';
import MessageCreate from './MessageCreate';
import MessageEdit from './MessageEdit';

export default {
  list: MessageList,
  create: MessageCreate,
  edit: MessageEdit,
  icon: MessageIcon,
};

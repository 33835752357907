import React from 'react';
import {downloadFile, ReportForm, showErrorNotify, TreeCheckboxInput} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {AutocompleteInput, DateInput, ReferenceArrayInput, ReferenceInput, required, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class AttendanceInOutReport extends React.Component {

  state = {
    unitIds: [],
    eventId: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  };

  excelReport = () => {
    if (!this.state.unitIds || this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phòng ban")
      return;
    }
    downloadFile(this.getReportUrl(), 'bao_cao_vao_ra.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/in-out-report?` +
          `unitIds=` + this.state.unitIds +
          `&eventId=` + this.state.eventId +
          `&fDate=` + this.state.fromDate +
          `&tDate=` + this.state.toDate;
  }

  validate = (values) => {
    const errors = {};

    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }
    values.eventId = this.state.eventId;

    this.setState({
      unitIds: values.unitIds,
      fromDate: values.fromDate,
      toDate: values.toDate
    })

    return errors;
  }

  onUnitChanged(e, value) {
    this.setState({
      eventId: ''
    })
  }

  onEventChanged(e, value) {
    this.setState({
      eventId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="resources.inOutReport.name" />
          <CardContent>
            <ReportForm validate={this.validate}
                        excelReport={this.excelReport}
                        params={this.state}
            >
              <span>
                Mô tả: Báo cáo dựa trên dữ liệu duyệt điểm danh, cần thiết lập sự kiện duyệt điểm danh để có dữ liệu trong báo cáo
              </span>
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                  <DateInput label="resources.common.fromDate" source="fromDate"
                             validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Phòng ban *</label>
                  <ReferenceArrayInput source="unitIds"
                                  reference="units"
                                  perPage={999}
                                  filter={{departmentWithParents: true}}
                                  onChange={(e, value) => this.onUnitChanged(e, value)}
                                  {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true} />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <ReferenceInput source="eventId"
                                  reference="events"
                                  label="resources.inOutReport.eventId"
                                  perPage={50}
                                  filter={{filterPath: 'filterSimple', unitIds: this.state.unitIds}}
                                  onChange={(e, value) => this.onEventChanged(e, value)}
                                  sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput source="name" />
                  </ReferenceInput>
                </Grid>

              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default AttendanceInOutReport;

import React from 'react';
import { translate, SelectInput } from 'react-admin';

export const permissions = [
  { id: 'VIEWER', name: 'XEM' },
  { id: 'CHECKER', name: 'SỬA' },
  { id: 'MAKER', name: 'TẠO' },
]

const PermissionSelectInput = ({translate, source, ...props}) => {

  return (
    <SelectInput source={source} choices={permissions} />
  )
}

export default PermissionSelectInput;

import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, required, TextInput} from 'react-admin';

const ContributionProductFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="price" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enable" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="priority" {...props} />
        </Grid>
    </React.Fragment>
)

export default ContributionProductFields

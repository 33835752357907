import React from "react";
import {Datagrid, DatagridBody} from "react-admin";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath, showSelectBox}) => (
    <TableRow key={id}  rowClick="edit" >
        {showSelectBox &&
        <TableCell style={{width: "2%"}} padding="none">
            <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
        </TableCell>
        }

        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
export const BaseCustomDatagrid = props => <Datagrid rowClick="edit" {...props} body={<CustomDatagridBody/>}/>;

import React from 'react';
import {AutocompleteInput, ReferenceInput, required, SelectInput} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {HocKiInput} from "../../components";

const CongBoDiemFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <HocKiInput source="hocKy" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="unitId" reference="baseUnits" validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionValue="id" optionText="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()} allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
    </React.Fragment>
)

export default CongBoDiemFields

import CardApplicationIcon from '@material-ui/icons/ViewList';

import CardApplicationList from './CardApplicationList';
import CardApplicationEdit from './CardApplicationEdit';

export default {
  list: CardApplicationList,
  edit: CardApplicationEdit,
  icon: CardApplicationIcon,
};

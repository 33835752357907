import RolePrivilegesIcon from '@material-ui/icons/ViewList';

import RolePrivilegesList from './RolePrivilegesList';
import RolePrivilegesCreate from './RolePrivilegesCreate';
import RolePrivilegesEdit from './RolePrivilegesEdit';

export default {
  list: RolePrivilegesList,
  create: RolePrivilegesCreate,
  edit: RolePrivilegesEdit,
  icon: RolePrivilegesIcon,
};

import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import BlockFields from './BlockFields'

export const BlockEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <BlockFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default BlockEdit

import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import SchoolFields from './SchoolFields'
import ImageUploadWrapper from "../../components/ImageUploadWrapper";

export const SchoolEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <SchoolFields />
          <ImageUploadWrapper {...props} url={'/api/photo/unit/'}/>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default SchoolEdit

import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {showNotification, SimpleForm, Toolbar, translate} from 'react-admin';

const buttonStyle = {
    margin: '0 8px',
}

const styles = {
    form: {
        // "& > div > div > div ": {
        //   width: '100%',
        // }
    },
};

const noop = () => {
}

class ReportButton extends React.Component {
    handleClick = e => {
        const {
            handleSubmitWithRedirect,
            invalid,
            redirect,
            showNotification,
            onClick,
        } = this.props;
        if (invalid) {
            showNotification('ra.message.invalid_form', 'warning');
        }

        if (e) {
            e.preventDefault();
        }
        const errors = handleSubmitWithRedirect(redirect)();
        if (!errors) {
            if (typeof onClick === 'function') {
                onClick();
            }
        }
    }

    render() {
        const {label, translate, disabled} = this.props
        return (
            <Button disabled={disabled} color="secondary" variant="raised" style={buttonStyle}
                    onClick={this.handleClick}>
                {label && translate(label, {_: label})}
            </Button>
        )
    }
}

const ReportToolbar = ({htmlReport, pdfReport, excelReport, zipReport, isLoading, ...props}) => (
    <Toolbar {...props} style={{marginTop: 50}}>
        {htmlReport &&
        <ReportButton
            disabled={isLoading}
            label='app.label.viewHtmlReport'
            onClick={htmlReport}
            {...props}
        />
        }

        {pdfReport &&
        <ReportButton
            disabled={isLoading}
            label='app.label.viewPdfReport'
            onClick={pdfReport}
            {...props}
        />
        }

        {excelReport &&
        <ReportButton
            disabled={isLoading}
            label='app.label.viewExcelReport'
            onClick={excelReport}
            {...props}
        />
        }
        {zipReport &&
        <ReportButton
            disabled={isLoading}
            label='app.label.exportZip'
            onClick={zipReport}
            {...props}
        />
        }
        {
            isLoading && <CircularProgress style={{marginLeft: 10}} color="secondary"/>
        }
    </Toolbar>
)

const ReportForm = ({params, validate, children, classes, ...props}) => (
    <SimpleForm
        toolbar={<ReportToolbar {...props} />}
        record={params}
        validate={validate}
        save={noop}
        className={classes.form}
        {...props}
    >
        {children}
    </SimpleForm>
)

export default compose(
    translate,
    connect(undefined, {showNotification}),
    withStyles(styles),
)(ReportForm);

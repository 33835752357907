import React from 'react';

import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';

import {showNotification, Title} from 'react-admin';
import {downloadFile, ReportForm, showErrorNotify} from '../../components';
import env from '../../env';
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import cachedApis from "../../api/cachedApis";

const DsMaQrNv = (props) => {

    const [values, setValues] = React.useState({
        customerIds: [],
        customers: [],
        unitIds: [],
    });

    const [loadingCustomers, setLoadingCustomers] = React.useState(true);

    React.useEffect(() => {
        setValues({...values});
        setLoadingCustomers(true);
        getEmployeesByUnit();
    }, [values.isRedirect]);

    function getEmployeesByUnit() {
        cachedApis.getEmployeeUnits('e').then(rs => {
            setValues({...values, customers: rs});
            setLoadingCustomers(false);
        })
    }

    const getReport = () => {
        const customerIds = values.customerIds
        if (!customerIds || customerIds.length === 0) {
            showErrorNotify("Vui lòng chọn nhân viên")
            return;
        }
        downloadFile(`${env.baseURL}/reports/dsMaQr?type=e&customerIds=${values.customerIds}&unitIds=${values.unitIds}`, 'ds_ma_qr.xlsx')
    };

    function onCustomerSelected(value) {
        const customerIdIds = [];
        const unitIds = [];
        value.forEach(i => {
            const temp = "" + i;
            let zeroIdx = temp.indexOf("0000");
            let lastZeroIdx = -1;
            while (zeroIdx >= 0) {
                lastZeroIdx = zeroIdx;
                zeroIdx = temp.indexOf("0000", zeroIdx + 1);
            }
            if (lastZeroIdx !== -1 && lastZeroIdx < temp.length - 1) {
                let stdId = Number(temp.substr(lastZeroIdx + 4));
                if (!customerIdIds.includes(stdId)) {
                    customerIdIds.push(stdId);
                }
                let unitId = Number(temp.substr(0, lastZeroIdx));
                if (!unitIds.includes(unitId)) {
                    unitIds.push(unitId);
                }
            }
        });
        setValues(
            {...values, customerIds: customerIdIds, unitIds: unitIds});
    }

    return (
        <Card>
            <Title title="resources.dsMaQrNv.name"/>
            <CardContent>
                <ReportForm params={values}
                    // validate={validate}
                            excelReport={getReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <label>Danh sách nhân viên *</label>
                            <div style={{
                                maxHeight: '600px',
                                overflow: 'auto',
                                marginTop: 20
                            }}>
                                {loadingCustomers ?
                                    <CircularProgress color="secondary"/>
                                    :
                                    <TreeCheckBoxCustomInput data={values.customers}
                                                             selected={values.cuzIds}
                                                             expandTopLevel={true}
                                                             onChange={onCustomerSelected}/>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    connect(null, {showNotification})
)(DsMaQrNv);

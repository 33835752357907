import React from 'react';
import {Edit, SimpleForm, TextInput} from "react-admin";
import {CustomToolbar, Grid} from "../../components";

const CustomerInternalCodeEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="customerId" {...props} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="customerName" {...props} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="cardNumber" {...props} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="internalCode" {...props} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default CustomerInternalCodeEdit

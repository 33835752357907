import CardApplicationBatchIcon from '@material-ui/icons/ViewList';

import CardApplicationBatchList from './CardApplicationBatchList';
import CardApplicationBatchCreate from './CardApplicationBatchCreate';
import CardApplicationBatchEdit from './CardApplicationBatchEdit';

export default {
  list: CardApplicationBatchList,
  create: CardApplicationBatchCreate,
  edit: CardApplicationBatchEdit,
  icon: CardApplicationBatchIcon,
};

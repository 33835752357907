import AccessDeviceIcon from '@material-ui/icons/ViewList';

import CustomerCertificateBatchList from './CustomerCertificateBatchList';
import CustomerCetificateCreate from './CustomerCertificateBatchCreate';
import CustomerCetificateFileEdit from './CustomerCertificateBatchEdit';

export default {
  list: CustomerCertificateBatchList,
  create: CustomerCetificateCreate,
  edit: CustomerCetificateFileEdit,
  icon: AccessDeviceIcon,
};

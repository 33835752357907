import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import EventUnitFields from './EventUnitFields'

export const EventUnitCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <EventUnitFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EventUnitCreate

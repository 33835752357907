import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import CalendarFields from './CalendarFields'

export const CalendarEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />} >
      <Grid container spacing={8}>
        <CalendarFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default CalendarEdit

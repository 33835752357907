import React from "react";
import CheckboxTree from 'react-checkbox-tree';
import get from 'lodash/get';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

function TreeCheckBoxUnitInput(props) {

  const [values, setValues] = React.useState({
    checked: [],
    expanded: [],
    nodes: []
  });
  const {data, expandTopLevel, selected} = props;

  React.useEffect(() => {
    if (data && data.length > 0) {
      const uniqueNodes = dedupChoices(data);
      const choices = groupByParentId(uniqueNodes, null);
      const checked = [];
      const nodes = choices.map(choice => {
        return toTreeCheckboxNode(choice, 'id', 'name')
      });
      if (selected) {
        uniqueNodes.map(node => {
          if (selected.includes(node.id)) {
            checked.push(node.id);
          }
        })
      }
      const topLevel = nodes.map(node => node.value);
      const expanded = expandTopLevel ? values.expanded.concat(topLevel) : values.expanded;
      setValues({nodes: nodes, expanded: expanded, checked: checked});
    }
  }, [data, selected]);

  /**
   * CheckboxTree uses (value, label) tube, so we need convert (id, name) ==> (value, label)
   */
  function toTreeCheckboxNode(choice, optionValue, optionText) {
    if (choice.children === undefined || choice.children.length === 0) {
      return {
        value: get(choice, optionValue),
        label: get(choice, optionText),
      }
    }
    return {
      value: get(choice, optionValue),
      label: get(choice, optionText),
      children: choice.children.map(child => {
        return toTreeCheckboxNode(child, optionValue, optionText)
      }),
    }
  }

  function dedupChoices(data) {
    let tmp = [];
    let rs = [];
    for (let i = 0; i < data.length; ++i) {
      if (tmp.includes(data[i].id)) {
        continue;
      }
      tmp.push(data[i].id);
      rs.push(data[i]);
    }
    return rs;
  }

  function groupByParentId(choices, parentId) {
    const nodes = choices.filter(choice => choice.parentId === parentId )

    if (nodes.length > 0) {
      nodes.map(node => {
        node.children = groupByParentId(choices, node.id);
        return false
      })
    }

    return nodes;
  }

  const handleChange = prop => e => {
    setValues({...values, [prop]: e});
    if (prop === 'checked') {
      props.onChange(e);
    }
  };

  return (
      <CheckboxTree
          nodes={values.nodes}
          checked={values.checked}
          expanded={values.expanded}
          onCheck={handleChange('checked')}
          onExpand={handleChange('expanded')}
          showNodeIcon={false}
      />
  )
}

export default compose(
    withStyles({})
)(TreeCheckBoxUnitInput)

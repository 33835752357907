import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import FoodsFields from './FoodsFields'

export const FoodCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar simpleSaveRefresh={true}/>} redirect="list">
            <Grid container spacing={8}>
                <FoodsFields/>
            </Grid>
    </SimpleForm>
  </Create>
);

export default FoodCreate

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, EditButton, refreshView} from 'react-admin';
import {compose} from 'redux';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import Swal from "sweetalert2";
import {Dialog, DialogActions, DialogContent, DialogTitle,} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import env from "../../env";
import './style.css';

const styles = theme => ({
    dialogPaper: {
        width: '75%',
        maxWidth: '76%',
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    suggestDialog: {
        zIndex: '1 !important',
    },
    pContent: {
        minHeight: 25,
    },
    input: {
        padding: 4
    },
    red: {
        color: 'red'
    }
});

const CardApplicationActions = (props) => {
    const {classes} = props;
    const [id, setId] = useState(null);
    const [showBtn, setShowBtn] = useState(false);
    const [showBtnDelete, setShowBtnDelete] = useState(false);
    const [showCompareBtn, setShowCompareBtn] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [currentInfo, setCurrentInfo] = useState({});
    const [existInfo, setExistInfo] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [ddcn, setDdcn] = useState();
    const [phone, setPhone] = useState('');
    const [hasChange, setHasChange] = useState(false);

    useEffect(() => {
        const {status, id, existCustomerId} = props.record
        setId(id)
        setShowBtn(status === 'A_DIFF_DDCN' || status === 'A_DIFF_PHONE' || status === 'A_SAME_DDCN' || status === 'A_SAME_PHONE');
        setShowBtnDelete(status === 'A_DIFF_DDCN' || status === 'A_DIFF_PHONE' || status === 'A_SAME_DDCN' || status === 'A_SAME_PHONE');
        setShowCompareBtn((status === 'A_DIFF_DDCN' || status === 'A_DIFF_PHONE' || status === 'A_SAME_DDCN' || status === 'A_SAME_PHONE') && existCustomerId != null);
    }, [props.record])

    function showCompareDialog(e) {
        const url = `/api/cardApplicationNeedConfirm/compare/${id}`;
        axiosInstance.get(url).then(rs => {
            if (rs.data.success) {
                setShowDialog(true)
                const exist = rs.data.existInfo
                if (exist.photoUrl) {
                    const s = exist.photoUrl
                    exist.photoUrl = s.startsWith('/') ? s.substr(1) : s
                }
                setExistInfo(exist)

                const current = rs.data.currentInfo
                if (current.photoUrl) {
                    const s = current.photoUrl
                    current.photoUrl = s.startsWith('/') ? s.substr(1) : s
                }
                setCustomerName(current.customerName)
                setPhone(current.phone)
                setDdcn(current.documentNumber)

                setCurrentInfo(current)
            }
        }, err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        });
    }

    function handleDelete(e) {
        e.stopPropagation();
        if (id) {
            Swal.fire({
                title: "Bạn chắc chắn muốn xoá thông tin này?",
                text: "Sau khi xoá, không thể khôi phục.",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
            })
                .then((result) => {
                    if (result.value) {
                        const url = `/api/cardApplicationNeedConfirm?id=${id}`;
                        axiosInstance.delete(url).then(rs => {
                            if (rs.data.success) {
                                showSuccessNotify("Xoá thành công");
                                setShowBtnDelete(false)
                                props.refreshView()
                            }
                        }, err => {
                            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                        });
                    }
                });
        }
    }

    const closeDialog = () => {
        setShowDialog(false)
    }

    const partialUpdate = () => {
        const data = {
            documentNumber: ddcn,
            phone
        }
        const url = `/api/cardApplicationNeedConfirm/partialUpdate?id=${id}`;
        axiosInstance.put(url, data).then(rs => {
            if (rs.data.success) {
                showSuccessNotify("Cập nhật thành công");
                props.refreshView()
            }
        }, err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        });
    }

    const overwriteNew = () => {
        Swal.fire({
            title: "Bạn chắc chắn muốn sử dụng thông tin có sẵn?",
            text: "Thông tin khách hàng sẽ được thay đổi theo thông tin có sẵn.",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                setShowDialog(false)
                if (result.value) {
                    const url = `/api/cardApplicationNeedConfirm/overwriteNew?id=${id}`;
                    axiosInstance.put(url).then(rs => {
                        if (rs.data.success) {
                            showSuccessNotify("Cập nhật thành công");
                            props.refreshView()
                        }
                    }, err => {
                        showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                    });
                }
            });
    }

    const removeExistCustomerId = () => {
        if (!ddcn && !phone && !existInfo.documentNumber && !existInfo.phone) {
            Swal.fire({
                title: "Không hợp lệ",
                text: "VUi lòng thay đổi thông tin số điện thoại hoặc định danh cá nhân khác với dữ liệu hệ thống.",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
            })
            return
        }
        console.log('aaaaa', ddcn, existInfo.documentNumber)
        if (ddcn && ddcn === existInfo.documentNumber) {
            Swal.fire({
                title: "Không hợp lệ",
                text: "VUi lòng thay đổi thông tin định danh cá nhân khác với dữ liệu hệ thống.",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
            })
            return
        }
        console.log('aaaaa', phone, existInfo.phone)
        if (phone && phone === existInfo.phone) {
            Swal.fire({
                title: "Không hợp lệ",
                text: "VUi lòng thay đổi thông tin số điện thoại khác với dữ liệu hệ thống.",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
            })
            return
        }
        Swal.fire({
            title: "Bạn chắc chắn đây không phải đối tượng nhập liệu?",
            text: "Thông tin khách hàng sẽ được tạo mới.",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                setShowDialog(false)
                if (result.value) {
                    const url = `/api/cardApplicationNeedConfirm/removeExistCustomerId?id=${id}`;
                    const data = {
                        documentNumber: ddcn,
                        phone
                    }
                    axiosInstance.put(url, data).then(rs => {
                        if (rs.data.success) {
                            showSuccessNotify("Cập nhật thành công");
                            props.refreshView()
                        }
                    }, err => {
                        showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                    });
                }
            });
    }

    const changeName = (e) => {
        setCustomerName(e.target.value)
        setHasChange(true)
    }
    const changePhone = (e) => {
        setPhone(e.target.value)
        setHasChange(true)
    }
    const changeDdcn = (e) => {
        setDdcn(e.target.value)
        setHasChange(true)
    }

    return (
        <div>
            {showBtn &&
            <EditButton label={""} variant="outlined" {...props} />
            }
            {showBtnDelete &&
            <Button style={{marginLeft: '5px', color: 'red'}} variant="outlined" label="Xoá" onClick={handleDelete}/>
            }
            {showCompareBtn &&
            <Button style={{marginLeft: '5px'}} variant="outlined" label="So sánh" onClick={showCompareDialog}/>
            }
            <Dialog open={showDialog}
                    fullWidth
                    aria-labelledby="form-dialog-title"
                    disableBackdropClick={true}
                    classes={{paper: classes.dialogPaper}}>
                <DialogTitle id="form-dialog-title">
                    <div>So sánh thông tin</div>
                </DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', 'justify-content': 'space-between'}}>
                        <div style={{width: '20%'}}>
                            <p className={classes.pContent}></p>
                            <p style={{height: 140}}>Ảnh thẻ</p>
                            <p className={classes.pContent}>Mã VinaID</p>
                            <p className={classes.pContent}>Họ tên</p>
                            <p className={classes.pContent}>Ngày sinh</p>
                            <p className={classes.pContent}>Giới tính</p>
                            <p className={classes.pContent}>SĐT đại diện</p>
                            <p className={classes.pContent}>Định danh cá nhân</p>
                            <p className={classes.pContent}>Trường</p>
                            <p className={classes.pContent}>Lớp</p>
                            <p className={classes.pContent}>Niên khoá</p>
                            <p className={classes.pContent}>Thông tin quan hệ</p>
                        </div>
                        <div style={{width: '40%'}}>
                            <p className={classes.pContent}>Thông tin KH đang xử lý</p>
                            <p style={{height: 140}}>
                                {currentInfo.photoUrl &&
                                <img src={`${env.baseURL}/${currentInfo.photoUrl}`}
                                     alt="" style={{maxHeight: '140px'}}
                                />
                                }
                            </p>
                            <p className={classes.pContent}></p>
                            <p className={classes.pContent}>{currentInfo.customerName}</p>
                            <p className={classes.pContent}>{currentInfo.dob}</p>
                            <p className={classes.pContent}>{currentInfo.gender === 'FEMALE' ? 'Nữ' : 'Nam'}</p>
                            <p className={classes.pContent}>
                                <input className={classes.input} value={phone} onChange={changePhone}/>
                            </p>
                            <p className={classes.pContent}>
                                <input className={classes.input} value={ddcn} onChange={changeDdcn}/>
                            </p>
                            <p className={classes.pContent}></p>
                            <p className={classes.pContent}></p>
                            <p className={classes.pContent}></p>
                        </div>
                        <div style={{width: '40%'}}>
                            <p className={classes.pContent}>Thông tin KH đã có trên hệ thống</p>
                            <p style={{height: 140}}>
                                {existInfo.photoUrl &&
                                <img src={`${env.baseURL}/${existInfo.photoUrl}`}
                                     alt="" style={{maxHeight: '140px'}}
                                />
                                }
                            </p>
                            <p className={classes.pContent}>{existInfo.customerId}</p>
                            <p className={classes.pContent}
                               style={{color: (existInfo.customerName == currentInfo.customerName) ? 'black' : 'red'}}>{existInfo.customerName}</p>
                            <p className={classes.pContent}
                               style={{color: (existInfo.dob == currentInfo.dob) ? 'black' : 'red'}}>{existInfo.dob}</p>
                            <p className={classes.pContent}
                               style={{color: (existInfo.gender == currentInfo.gender) ? 'black' : 'red'}}>{existInfo.gender === 'FEMALE' ? 'Nữ' : 'Nam'}</p>
                            <p className={classes.pContent}
                               style={{color: (existInfo.phone == phone) ? 'black' : 'red'}}>{existInfo.phone}</p>
                            <p className={classes.pContent}
                               style={{color: (existInfo.documentNumber == ddcn) ? 'black' : 'red'}}>{existInfo.documentNumber}</p>
                            <p className={classes.pContent}>{existInfo.schoolName}</p>
                            <p className={classes.pContent}>{existInfo.clazzName}</p>
                            <p className={classes.pContent}>{existInfo.schoolYear}</p>
                            {existInfo.relations && existInfo.relations.length > 0 &&
                            existInfo.relations.map((r) => (
                                <p className={classes.pContent}>{r.customerName1 + ' -- ' + r.relationshipType}</p>
                            ))
                            }
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={removeExistCustomerId} style={{padding: 10, marginLeft: '10px', color: 'red'}}
                            variant="outlined"
                            label={'Tạo mới khách hàng'}/>
                    <Button onClick={overwriteNew} style={{padding: 10, marginLeft: '10px', marginRight: '10px'}}
                            variant="outlined" label={'Sử dụng thông tin hệ thống'}/>
                    {hasChange &&
                    <Button onClick={partialUpdate} style={{padding: 10, marginLeft: '10px', marginRight: '10px'}}
                            variant="outlined" label={'Lưu thay đổi'}/>
                    }
                    <Button onClick={closeDialog} style={{padding: 10, marginLeft: '10px', marginRight: '10px'}}
                            variant="outlined" label={'Đóng'}/>
                </DialogActions>
            </Dialog>
        </div>
    )
}
const mapStateToProps = state => ({});
const mapDispatchToProps = {
    refreshView
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps),)(CardApplicationActions);

import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const authStatuses = [
  { id: 'NOT_AUTHORIZED', name: 'authStatus.NOT_AUTHORIZED' },
  { id: 'AUTHORIZED',   name: 'authStatus.AUTHORIZED' },
]

export const AuthStatusField = (props) => (
  <SelectField source={props.source} choices={authStatuses} {...props} />
)

export const AuthStatusInput = (props) => (
  <SelectInput source={props.source} choices={authStatuses} {...props} />
)

const billAuthStatuses = [
  { id: 'DRAFT', name: 'authStatus.DRAFT' },
  { id: 'AUTHORIZED',   name: 'authStatus.AUTHORIZED' },
]

export const BillAuthStatusField = (props) => (
    <SelectField source={props.source} choices={billAuthStatuses} {...props} />
)

export const BillAuthStatusInput = (props) => (
    <SelectInput source={props.source} choices={billAuthStatuses} {...props} />
)

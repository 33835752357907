import React from 'react';
import {Redirect} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Button, Grid} from '@material-ui/core'
import {showNotification, translate} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

const styles = {
  button: {
    margin: 1,
  },
  cancelBtn: {
    marginLeft: 20
  },
  checkboxGroup: {
    "& > div ": {
      flexDirection: 'column',
    }
  }
};

const CustomerProductField = props => {
  const [values, setValues] = React.useState({
    isRedirect: false,
    refreshList: false
  });
  const [customers, setCustomers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const {defaultValue, save} = props;

  React.useEffect(() => {
    if (!defaultValue.isRedirect) {
      getCustomersWithUnits();
      getProducts();
    } else {
      setValues({isRedirect: true, refreshList: true});
    }
  }, [defaultValue.isRedirect]);


  function getCustomersWithUnits() {
    cachedApis.getCustomerUnits(true).then(rs => {
      setCustomers(rs);
    })
  }

  function getProducts() {
    cachedApis.getProducts().then(rs => {
      setProducts(rs)
    })
  }

  function cancel() {
    setValues({isRedirect: true, refreshList: false});
  }

  if (values.isRedirect) {
    return <Redirect to={'/customerProducts?refreshView=' + values.refreshList} />;
  } else {
    // console.log('pro', products)
    return (
        <React.Fragment>
          <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 30}}>
            <label><b>Danh sách Học sinh *</b></label>
            <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
              <TreeCheckBoxCustomInput data={customers}
                                       selected={defaultValue.customerIds}
                                       expandTopLevel={true}
                                       onChange={props.onChange('cuzIds')}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 30}}>
            <label><b>Danh sách phí *</b></label>
            <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
              <TreeCheckBoxCustomInput data={products}
                                       expandTopLevel={true}
                                       selected={defaultValue.productIds}
                                       onChange={props.onChange(
                                           'tmpProductIds')}/>
            </div>
          </Grid>


            <Grid item xs={12} sm={12} style={{marginTop: 15}}>
            <Button variant="contained"
                    color="primary"
                    style={styles.button}
                    disabled={defaultValue.saveDisabled}
                    onClick={save}>
              <SaveIcon/> Lưu
            </Button>
            <Button variant="contained" style={styles.cancelBtn}
                    onClick={cancel}>
              <CancelIcon/> Hủy
            </Button>
          </Grid>
        </React.Fragment>
    )
  }
};

export default compose(
    translate,
    connect(null, {showNotification}),
    withStyles(styles)
)(CustomerProductField)

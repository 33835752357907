import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import BillBatchFields from './BillBatchFields'

const defaultValue = {
    priority: 100
}

export const BillBatchCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} defaultValue={defaultValue} redirect="list" >
      <Grid container spacing={8}>
        <BillBatchFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default BillBatchCreate

import React from "react";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {connect} from 'react-redux';
import {Button, refreshView,} from 'react-admin';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

class ApproveDisplayBtn extends React.Component {

    state = {
        showBtn: false,
        id: 0
    }

    constructor(props) {
        super(props);
        if (props.record) {
            this.state.showBtn = !props.record.active;
            this.state.id = props.record.id
        }
    }

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.id) {
            const url = `/api/billBatch/active?id=${this.state.id}`;
            axiosInstance.put(url)
                .then((res) => {
                    if (res.data && res.data.success) {
                        showSuccessNotify("Đã kích hoạt kế hoạch");
                        this.setState({showBtn: false})
                    } else {
                        showWarningNotify("Kích hoạt kế hoạch không thành công.");
                    }
                }).catch(err => {
                console.log(err)
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        }

    };

    render() {
        return (
            <>
                {this.state.showBtn &&
                <Button color="primary" style={{width: 150}}
                        onClick={this.handleClick} label={'Kích hoạt'}>
                    <PlayArrowIcon/>
                </Button>
                }
            </>
        )
    }
}


export default compose(
    connect(undefined, {refreshView}),
    withStyles({})
)(ApproveDisplayBtn)

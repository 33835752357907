import CalendarIcon from '@material-ui/icons/ViewList';

import GoogleCalendarList from './GoogleCalendarList';
import GoogleCalendarCreate from './GoogleCalendarCreate';
import GoogleCalendarEdit from './GoogleCalendarEdit';

export default {
  list: GoogleCalendarList,
  create: GoogleCalendarCreate,
  edit: GoogleCalendarEdit,
  icon: CalendarIcon,
};

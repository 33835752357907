import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import PaymentGatewayFeesFields from './PaymentGatewayFeesFields'

export const PaymentGatewayFeesEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />}>
      <Grid container spacing={8}>
        <PaymentGatewayFeesFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default PaymentGatewayFeesEdit

import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {AutocompleteInput, BooleanInput, DateInput, ReferenceInput, required, SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";
import {
    FileUploadComponent,
    schoolYears,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import {post} from "axios";
import {CalendarTypeInput} from "../../components/CalendarType";

export class CalendarBatchCreate extends React.Component {

    state = {
        name: '',
        schoolYearId: schoolYears[0].id,
        type: 'SCHEDULE',
        isRedirect: false,
        useImage: false,
        file: null,
        images: []
    }

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            useImage: false,
            schoolYearId: schoolYears[0].id,
            type: 'SCHEDULE',
            isLoading: false,
            showUploadFile: true,
            startDate: new Date(),
            endDate: new Date(),
            images: []
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    changeDescription = (val) => {
        this.setState({name: val});
    }

    changeType = (val) => {
        this.setState({type: val});
    }

    changeUseImage = (val) => {
        this.setState({useImage: val, images: []});
    }

    changeStartDate = (val) => {
        this.setState({startDate: val});
    }

    changeEndDate = (val) => {
        this.setState({endDate: val});
    }
    changeEvent = (e, val) => {
        this.setState({schoolYearId: val});
    }
    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        const {name, schoolYearId, type, startDate, endDate} = this.state;

        const batchData = {
            name,
            schoolYearId,
            type,
            startDate,
            endDate
        }
        const url = `/api/calendarBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            const batchId = rs.data.id
            this.setState({
                batchId: batchId
            })
            this.fileUpload(this.state.file, batchId).then((response) => {
                if (response.data.success) {
                    this.setState({
                        isLoading: false,
                        isRedirect: true
                    });
                    showSuccessNotify("Đã xử lý thành công");
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                }
            })
                .catch(error => {
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                    this.setState({
                        isLoading: false,
                    })
                });

        })
    }

    fileUpload(file, batchId) {
        const url = `${env.baseURL}/api/calendarBatchUpload/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    doImageUpload(batchId) {
        const url = `${env.baseURL}/api/calendarBatchUpload/upload-image`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        const {images} = this.state;
        for (let i = 0; i < images.length; i++) {
            formData.append('file', images[i]);
        }
        formData.append('batchId', batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    saveBatchItem(data) {
        const url = `${env.baseURL}/api/calendarBatchUpload/save-item`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        return post(url, data, config)
    }

    save = () => {
        if (!this.validate()) {
            return;
        }
        const {name, schoolYearId, type, images, startDate, endDate} = this.state;

        const batchData = {
            name,
            schoolYearId,
            type,
            startDate,
            endDate
        }
        const url = `/api/calendarBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            if (images && images.length > 0) {
                const batchId = rs.data.id
                this.setState({
                    batchId: batchId
                })
                this.doImageUpload(batchId).then((response) => {
                    if (response.data.success) {
                        this.setState({
                            isLoading: false,
                            isRedirect: true
                        });
                        showSuccessNotify("Đã xử lý thành công");
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                    }
                })
                    .catch(error => {
                        showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                        this.setState({
                            isLoading: false,
                        })
                    });
            } else {
                this.cancel();
            }

        }, err => {
            showWarningNotify(err.message);
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };
    changeImage = (e) => {
        const files = e.target.files
        if (files && files.length > 3) {
            showErrorNotify('Chọn tối đa 3 hình')
            return
        }
        this.setState({images: files})
    }
    validate = () => {
        const {name, schoolYearId, type} = this.state;
        if (!name) {
            showWarningNotify('Vui lòng nhâp mô tả');
            return false;
        }
        if (!type) {
            showWarningNotify('Vui lòng chọn loại');
            return false;
        }
        if (!schoolYearId) {
            showWarningNotify('Vui lòng chọn niên khoá');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/calendarBatches'/>;
        }
        const {description, schoolYearId, type} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo TKB/Lịch thi"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <ReferenceInput source="schoolYearId"
                                                    validate={required()}
                                                    {...this.props}
                                                    reference="schoolYears"
                                                    allowEmpty
                                                    defaultValue={schoolYearId}
                                                    sort={{field: 'name', order: 'DESC'}}
                                                    onChange={(e, value) => this.changeEvent(e, value)}
                                                    perPage={999}>
                                        <AutocompleteInput optionText="name"/>
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <DateInput source="startDate"
                                               onChange={(e, value) => this.changeStartDate(value)}
                                               defaultValue={this.state.startDate}
                                               validate={required()} {...this.props}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateInput source="endDate" onChange={(e, value) => this.changeEndDate(value)}
                                               defaultValue={this.state.endDate}
                                               validate={required()} {...this.props} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <CalendarTypeInput source="type" defaultValue={this.state.type}
                                                       validate={required()}
                                                       onChange={(e, value) => this.changeType(value)} {...this.props}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <BooleanInput onChange={(e, value) => this.changeUseImage(value)}
                                                  label="Sử dụng hình" defaultValue={this.state.useImage}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                {this.state.useImage &&
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <form style={{width: '90%'}}>
                                            <div style={{flexDirection: 'row'}}>
                                                <h4>Chọn hình (tối đa 3 hình)</h4>
                                                <input id="contained-button-file" type="file" multiple
                                                       accept=".png,.jpg,.jpeg"
                                                       onChange={(e) => this.changeImage(e)}/>
                                            </div>
                                            <ol>
                                                {[...this.state.images].map((f, i) => (
                                                    <li key={i}>{f.name}</li>
                                                ))}
                                            </ol>
                                        </form>
                                    </Grid>
                                </>
                                }
                                {!this.state.useImage &&
                                this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                    <FileUploadComponent
                                        onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                        onFileUpload={this.onFileUpload}
                                        showValidateBtn={this.state.showValidateBtn}
                                    />
                                    {
                                        this.state.isLoading && <CircularProgress color="secondary"/>
                                    }
                                </Grid>
                                }
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(CalendarBatchCreate);

import React from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, ReferenceInput, required, TextInput} from 'react-admin';

const TenantBankAccountFields = props => (
  <React.Fragment>
      <Grid item xs={12} sm={6}>
          <TextInput source="accountName" validate={required()} {...props} />
      </Grid>
      <Grid item xs={12} sm={6}>
          <ReferenceInput source="bankId" reference="bankInfoes" validate={required()} allowEmpty {...props} >
              <AutocompleteInput optionText="displayName"/>
          </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
          <TextInput source="branch" {...props} />
      </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="accountNumber" validate={required()} {...props} />
    </Grid>
  </React.Fragment>
)

export default TenantBankAccountFields

import React from 'react';

class TestPage extends React.Component {
  state = {
    readerName: null,
    uid: null,
    fullscreen: false,
  }

  componentWillMount(){
    const electron = window.electron || (window.require ? window.require("electron") : null)
    if (electron){
      electron.ipcRenderer.on('reader-attached', this.onReaderAttached)
      electron.ipcRenderer.on('reader-detached', this.onReaderDetached)
      electron.ipcRenderer.on('uid', this.onUID)
    }    
  }

  componentWillUnmount(){
    const electron = window.electron || (window.require ? window.require("electron") : null)
    if (electron){
      electron.ipcRenderer.removeListener('reader-attached', this.onReaderAttached)
      electron.ipcRenderer.removeListener('reader-detached', this.onReaderDetached)
      electron.ipcRenderer.removeListener('uid', this.onUID)
    }
  }

  onReaderAttached = (event, arg) => {
    this.setState({readerName: arg})
  }
  onReaderDetached = (event, arg) => {
    this.setState({readerName: null, uid: null})
  }
  onUID = (event, arg) => {
    this.setState({uid: arg})
  }

  render() {
    return (
      <div>
        <div>Reader name: {this.state.readerName}</div>
        <div>Card UID: {this.state.uid}</div>
      </div>
    )
  }
}

export default TestPage

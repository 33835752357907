import React from 'react';
import { translate, SelectInput } from 'react-admin';

const RecurringTypeInput = ({translate, source, ...props}) => {
  const daysOfWeek = [
    { id: 'DAILY',     name: translate('recurring.DAILY') },
    { id: 'WEEKLY',    name: translate('recurring.WEEKLY') },
    // TODO: Later
    // { id: 'MONTHLY',   name: translate('recurring.MONTHLY') },
    // { id: 'YEARLY',    name: translate('recurring.YEARLY') },
  ]
  
  return (
    <SelectInput source={source} choices={daysOfWeek} {...props} />
  )
}

export default translate(RecurringTypeInput)
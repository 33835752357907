import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import {AutocompleteInput, ReferenceInput, required, SelectInput, TextInput, translate} from 'react-admin';
import {HocKiInput, schoolYears} from "../../components";

const styles = {
    checkboxGroup: {
        "& > fieldset > div ": {
            flexDirection: 'column',
        }
    }
}

const CustomerSubjectResultBatchFields = ({classes, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="unitId"
                            reference="clazzes"
                            label="resources.clazzes.name"
                            filter={{schoolYear: schoolYears[0].id}}
                            perPage={10}
                            validate={required()}
                            {...props} >
                <AutocompleteInput source="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <HocKiInput source="hocKi" validate={required()}
                        {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="description"  {...props} />
        </Grid>
    </React.Fragment>
)

export default compose(
    translate,
    withStyles(styles)
)(CustomerSubjectResultBatchFields)

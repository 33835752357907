import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, TextInput, required } from 'react-admin';

const BankStatementTemplateFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput type="number" source="startRowIdx" validate={required()}{...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput type="number" source="transactionNumberColIdx" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="haveTransactionNumber" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput type="number" source="amountColIdx" validate={required()}{...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="formatMoneyAsString" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput type="number" source="descriptionColIdx" validate={required()}{...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput type="number" source="transactionDateColIdx" validate={required()}{...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput source="dateTimeFormatter" validate={required()}{...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="haveTime" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <BooleanInput source="active" {...props} />
    </Grid>
  </React.Fragment>
)

export default BankStatementTemplateFields
import React from 'react';

import moment from 'moment'

import {Card, CardContent, Grid} from '@material-ui/core';

import {AutocompleteInput, ReferenceInput, required, Title, translate} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import {compose} from 'redux';

const DiemDanhLopHocPhan = ({...props}) => {

    const [values, setValues] = React.useState({
        unitId: null,
        eventGroupId: null,
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    });


    const getReportUrl = () => {
        return `${env.baseURL}/reports/diemDanhLopHocPhan?fromDate=` + values.fromDate +
            `&toDate=` + values.toDate +
            `&eventGroupId=` + values.eventGroupId +
            `&unitId=` + values.unitId;
    }
    const excelReport = () => {
        downloadFile(getReportUrl(), 'diem_danh_lop_hoc_phan.xlsx')
    };

    const validate = (val) => {
        const errors = {};

        if (val.fromDate && val.toDate && (val.fromDate > val.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        setValues({
            unitId: val.unitId,
            eventGroupId: val.eventGroupId,
            fromDate: val.fromDate,
            toDate: val.toDate
        })

        return errors;
    };

    return (
        <Card>
            <Title title="resources.diemDanhLopHocPhan.name"/>
            <CardContent>
                <ReportForm params={values}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="eventGroupId"
                                            reference="eventGroups"
                                            label="resources.eventGroups.name"
                                            perPage={50}
                                            validate={required()}
                                            sort={{field: 'name', order: 'ASC'}} {...props} >
                                <AutocompleteInput source="name"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    translate
)(DiemDanhLopHocPhan)


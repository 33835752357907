import React from 'react';
import Grid from '@material-ui/core/Grid'
import {ReferenceInput, required, SelectInput, TextInput} from 'react-admin';

const GroupFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()}
                            allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="contactName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="phone" {...props} />
        </Grid>
    </React.Fragment>
)

export default GroupFields

import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, ReferenceInput, SelectInput, TextInput, required } from 'react-admin';
import {schoolYears, TreeCheckboxInput} from "../../components";
import PermissionSelectInput from "../../components/PermissionSelectInput";

class CardPermissionFields extends React.Component {
  state = {
    schoolYear: schoolYears[0].id,
  }
  render() {
    return (
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <TextInput source="cardNo" validate={required()}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PermissionSelectInput source="permission"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput source="schoolYear"
                         choices={schoolYears}
                         onChange={(e, value) => {
                           this.setState({schoolYear: value})
                         }}
                         validate={required()} allowEmpty/>
            <div style={{
              maxHeight: '300px',
              overflow: 'auto',
              marginTop: 20,
              width: 400
            }}>
              <ReferenceInput source="clazzIds"
                              reference="units"
                              perPage={999}
                              filter={{schoolYear: this.state.schoolYear}}>
                <TreeCheckboxInput optionValue="id" optionText="name"
                                   expandTopLevel={true}/>
              </ReferenceInput>
            </div>
          </Grid>
        </React.Fragment>
    )
  }
}

export default CardPermissionFields

import FoodIcon from '@material-ui/icons/ViewList';

import FoodList from './FoodList';
import FoodCreate from './FoodCreate';
import FoodEdit from './FoodEdit';

export default {
  list: FoodList,
  create: FoodCreate,
  edit: FoodEdit,
  icon: FoodIcon,
};

import React from 'react';
import {downloadFile, ReportForm, showErrorNotify, TreeCheckboxInput} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {
  DateInput,
  ReferenceInput,
  required,
  AutocompleteInput,
  ReferenceArrayInput,
  Title
} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class AttendanceTimeKeepingReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unitIds: [],
      eventId: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD')
    };
  }

  excelReport = () => {
    if (!this.state.unitIds || this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phòng ban")
      return;
    }
    downloadFile(this.getReportUrl(), 'bao_cao_cham_cong.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/time-keeping-report?` +
      `unitIds=` + this.state.unitIds +
        `&eventId=` + this.state.eventId +
        `&fDate=` + this.state.fromDate +
        `&tDate=` + this.state.toDate;
  }

  validate = (values) => {
    const errors = {};

    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }
    values.eventId = this.state.eventId;

    // Copy input values to params
    this.setState({
      unitIds: values.unitIds,
      fromDate: values.fromDate,
      toDate: values.toDate
    });

    return errors;
  }
  onUnitChanged(e, value) {
    this.setState({
      eventId: ''
    })
  }

  onEventChanged(e, value) {
    this.setState({
      eventId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="resources.timeKeepingReport.name" />
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                  <DateInput label="resources.common.fromDate" source="fromDate"
                             validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateInput label="resources.common.fromDate" source="toDate"
                             validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Phòng ban *</label>
                  <ReferenceArrayInput source="unitIds"
                                       reference="units"
                                       perPage={999}
                                       filter={{departmentWithParents: true}}
                                       onChange={(e, value) => this.onUnitChanged(e, value)}
                                       {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReferenceInput source="eventId"
                                  reference="events"
                                  label="resources.timeKeepingReport.eventId"
                                  perPage={50}
                                  filter={{filterPath: 'filterSimple'}}
                                  validate={required()}
                                  onChange={(e, value) => this.onEventChanged(e, value)}
                                  sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput source="name"/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default AttendanceTimeKeepingReport;

import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import { DateInput, Title, required } from 'react-admin';
import {ReportForm, IFrameHtmlReport, downloadFile} from '../../components';
import env from '../../env';

class PaymentPaidByProduct extends React.Component {
    state = {
        openHtmlReport: false,
        htmlReportUrl: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    };

    getReportUrl = (type) => {
        const token = localStorage.getItem('token');
        return `${env.baseURL}/reports/paidByProducts` +
        `?type=` + type +
        `&pFromDate=` + this.state.fromDate +
        `&pToDate=` + this.state.toDate +
        `&token=` + token
    }

    getReportUrlToDownload = (type) => {
        return `${env.baseURL}/reports/paidByProducts` +
        `?type=` + type +
        `&pFromDate=` + this.state.fromDate +
        `&pToDate=` + this.state.toDate;
    }

    htmlReport = () => {
        const htmlReportUrl = this.getReportUrl('HTML')
        this.setState({openHtmlReport: true, htmlReportUrl })
    }

    pdfReport = () => {
        downloadFile(this.getReportUrlToDownload('PDF'), 'bao_cao_thu_theo_danh_muc.pdf')
    }


    excelReport = () => {
        downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_thu_theo_danh_muc.xlsx')
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
            errors.toDate = 'To date must be greater than From date'
        }

        this.setState({
            fromDate: values.fromDate,
            toDate: values.toDate
        })

        return errors;
    }


    render() {
        return (
            <Card>
                <Title title="resources.paymentPaidByProduct.name" />
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                htmlReport={this.htmlReport}
                                excelReport={this.excelReport}
                                pdfReport={this.pdfReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6} >
                                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>

                    {/* The iframe to show HTML report */}
                    {
                        this.state.openHtmlReport &&
                        <IFrameHtmlReport src={this.state.htmlReportUrl} />
                    }
                </CardContent>
            </Card>
        )
    }
}

export default PaymentPaidByProduct


import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {refreshView, showNotification} from 'react-admin';
import axiosInstance from "../../api/axios";
import ImageUploadComponent2 from "../../components/ImageUploadComponent2";
import ImageProfile from "../ProfileChangeLog/ImageProfile";


const ImageUploadWrapperWithRefresh = (props) => {

    const [file, setFile] = useState(null);
    const [showBtn, setShowBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const {status} = props.record;
        setShowBtn(status === 'A_NOT_READY' || status === 'B_TEMP_READY' || status === 'B_READY' || status === 'L_REVIEW_REJECTED')
    }, [props.record])

    const onFileUpload = (e) => {
        e.preventDefault(); // Stop form submit
        if (file == null) {
            return;
        }
        setIsLoading(true)
        fileUpload(file).then((response) => {
            props.showNotification('Cập nhật hình thành công');
            props.refreshView()
        }).finally(() => {
            setIsLoading(false)
        })
    };

    const fileUpload = (file) => {
        const {id} = props.record;
        const url = props.url + id;
        const formData = new FormData();
        formData.append('file', file)
        return axiosInstance.post(url, formData);
    }

    return (
        <>
            <ImageProfile {...props}/>
            {showBtn &&
            <ImageUploadComponent2 onFileChanged={(e) => setFile(e.target.files[0])}
                                   showBtn={file != null} loading={isLoading}
                                   onFileUpload={onFileUpload}/>
            }
        </>

    );
}

export default compose(
    connect(null, {showNotification, refreshView})
)(ImageUploadWrapperWithRefresh)

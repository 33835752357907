import BillViewIcon from '@material-ui/icons/ViewList';

import BillViewList from './BillViewList';
import BillViewShow from "./BillViewShow";

export default {
  list: BillViewList,
  show: BillViewShow,
  icon: BillViewIcon,
};

import React, {useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {connect} from 'react-redux';
import {Button, refreshView,} from 'react-admin';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PaymentsIcon from '@material-ui/icons/Payment';
import Swal from "sweetalert2";
import {CircularProgress} from '@material-ui/core';

const BillBatchPayBtn = ({permissions, ...props}) => {
    const [showBtn, setShowBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);
    useEffect(() => {
        if (permissions && props.record) {
            setShowBtn(permissions.includes('CHECKER') && props.record.authStatus === "AUTHORIZED")
            setId(props.record.id)
        }
    }, [permissions, props.record])

    const handleClick = (e) => {
        e.stopPropagation();
        Swal.fire({
            title: "Bạn có chắc chắn muốn thanh toán toàn bộ kế hoạch?",
            text: 'Chọn phương thức thanh toán hoặc đóng nếu không muốn thanh toán',
            showDenyButton: true,
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: "Tiền mặt",
            denyButtonText: `Chuyển khoản`,
            cancelButtonText: 'Đóng',
            denyButtonColor: '#0b872c'
        }).then((result) => {
            if (result.isDismissed) {
                return
            }
            setLoading(true)
            let method = result.isConfirmed ? 'CASH' : 'BANK_TRANSFER'
            const url = `/api/billBatch/pay?id=${id}&method=${method}`;
            axiosInstance.post(url)
                .then((res) => {
                    setLoading(false)
                    if (res.data && res.data.success) {
                        showSuccessNotify("Đã thanh toán kế hoạch");
                        setShowBtn(false)
                    } else {
                        showWarningNotify("Thanh toán kế hoạch không thành công.");
                    }
                }).catch(err => {
                setLoading(false)
                console.log(err)
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        });
    };

    return (
        <>
            {showBtn &&
            <div style={{display: 'flex', flexDirection: 'row', width: 165}}>
                <Button color="primary" disabled={loading}
                        onClick={handleClick} label={'Thanh toán'}>
                    <PaymentsIcon/>
                </Button>
                {loading && <CircularProgress color="secondary" size={20}/>}
            </div>
            }
        </>
    )
}


export default compose(connect(undefined,
    {
        refreshView
    }
), withStyles(
    {}
))(BillBatchPayBtn)

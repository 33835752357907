import React from 'react';
import Grid from '@material-ui/core/Grid'

import {TextInput, ReferenceInput, SelectInput, required} from 'react-admin';

const BankStatementFields = ({classes, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6}>
      <TextInput source="description" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <ReferenceInput source="bankStatementTemplateId" reference="bankStatementTemplates" validate={required()} {...props} >
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </Grid>
  </React.Fragment>
)

export default BankStatementFields

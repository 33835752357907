import React from 'react';
import compose from 'recompose/compose';
import {Checkbox, FormControlLabel, FormGroup, Grid} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {BooleanInput} from 'react-admin';

const styles = theme => ({
    araContainer: {
        width: '100% !important'
    }
});

const AttendanceResultAuthorizationSummaryDetails = (props) => {

    const {
        classes,
        summaryName,
        summaryDate,
        totalLates,
        totalOnTimes,
        totalMembers,
        totalAbsentWithoutPermissions,
        totalAbsentWithPermissions,
        totalMissingCards,
        totalOffs,
        totalCms,
        lastChangedName,
        lastChangedDate,
        onChangedNotification,
        handleCheckAll,
        enabled
    } = props;

    React.useEffect(() => {
    }, [props]);

    const [state, setState] = React.useState({
        KT: false,
        CP: false,
        KP: false,
        CM: false,
    });

    const handleChange = name => event => {
        const val = event.target.checked;
        if (val) {
            setState({
                KT: name === 'KT',
                CP: name === 'CP',
                KP: name === 'KP',
                CM: name === 'CM'
            });
        } else {
            setState({...state, [name]: false});
        }
        if (handleCheckAll) {
            handleCheckAll(name, val);
        }
    };

    return (
        <Grid className={classes.araContainer} container spacing={1}>
            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                    <div><b>{summaryName} [{summaryDate}]</b></div>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Đúng giờ (ĐG): <b>{totalOnTimes}</b></div>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                    <div>Hiện diện:</div>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Đi Trễ (TR): <b>{totalLates}</b></div>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                    <div><b>{totalOnTimes + totalLates + totalMissingCards + totalCms}/{totalMembers}</b>
                    </div>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Có mặt (CM): <b>{totalCms}</b></div>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                    {lastChangedName &&
                    <div><b>{lastChangedName}, cập nhật lúc {lastChangedDate}</b></div>
                    }
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Không thẻ (KT): <b>{totalMissingCards}</b></div>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Vắng có phép (CP): <b>{totalAbsentWithPermissions}</b></div>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6}>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <div>Vắng không phép (KP): <b>{totalAbsentWithoutPermissions}</b></div>
                </Grid>
            </Grid>


            <Grid item xs={12} sm={5}>
                <BooleanInput label="Gửi tin nhắn" defaultValue={true}
                              onChange={(e) => onChangedNotification(e.target.checked)}/>
            </Grid>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox disabled={!enabled} checked={state.CM} onChange={handleChange('CM')} value="CM"/>
                    }
                    label="CM"
                />
                <FormControlLabel
                    control={
                        <Checkbox disabled={!enabled} checked={state.KT} onChange={handleChange('KT')} value="KT"/>
                    }
                    label="KT"
                />

                <FormControlLabel
                    control={
                        <Checkbox disabled={!enabled} checked={state.CP} onChange={handleChange('CP')} value="CP"/>
                    }
                    label="CP"
                />
                <FormControlLabel
                    control={
                        <Checkbox disabled={!enabled} checked={state.KP} onChange={handleChange('KP')} value="KP"/>
                    }
                    label="KP"
                />

            </FormGroup>
        </Grid>
    )
}

export default compose(
    withStyles(styles),
)(AttendanceResultAuthorizationSummaryDetails);


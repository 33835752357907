import React, {Fragment} from 'react';
import {BooleanField, Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import ChangePasswordDiaglog from "./ChangePasswordDiaglog";
import {Button} from '@material-ui/core';
import LockOpen from '@material-ui/icons/LockOpen';
import DownloadPasswordFile from "./DownloadPasswordFile";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = props => (
    <Fragment>
      <DownloadPasswordFile {...props} />
    </Fragment>
);

export const UserList = props => {
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState(false);
  const [userId, setUserId] = React.useState(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = (e, record) => {
    e.stopPropagation();
    setOpen(true);
    setUserName(record.userName);
    setUserId(record.id);
  };

    return (
      <React.Fragment>
        <List filters={<UserFilter />} {...props} exporter={false} bulkActionButtons={<PostBulkActionButtons/>}>
          <Datagrid rowClick="edit" >
            <TextField source="id" />
            <TextField source="userName" />
            <TextField source="fullName" />
            <ReferenceField source="defaultAccessDeviceId" reference="accessDevices" allowEmpty>
              <TextField source="name" />
            </ReferenceField>
            <BooleanField source="enabled" />
            <ChangePasswordButton label={"Đổi mật khẩu"} onClick={handleClickOpen}/>
          </Datagrid>
        </List>
        <ChangePasswordDiaglog close={handleCloseDialog} open={open} userName={userName} userId={userId}/>
      </React.Fragment>
  )
};

const ChangePasswordButton = ({ record, ...props }) => (
    <Button variant="contained"
            color={'primary'}
            component="span"
            style={{padding: 10, margin: 5}}
            onClick={(e) => props.onClick(e, record)}>
      <LockOpen/>
    </Button>

);

export default UserList

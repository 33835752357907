import React from 'react';
import { List, Datagrid, Filter, BooleanField, TextField, TextInput, LongTextInput } from 'react-admin';

const AccessDeviceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const AccessDeviceList = props => (
  <List filters={<AccessDeviceFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="deviceNo" />
      <TextField source="description" />
      <BooleanField source="sendNotification" />
      <BooleanField source="enabled" />
    </Datagrid>
</List>
);

export default AccessDeviceList

import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceArrayInput, required, Title, translate} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {compose} from 'redux';

const BcTheDienTu = ({...props}) => {

    const [values, setValues] = React.useState({
        unitIds: ''
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/bcTheDienTu?unitIds=` + values.unitIds;
    }

    const excelReport = () => {
        downloadFile(getReportUrl(), 'bc_the_dien_tu.xlsx')
    };

    const validate = (val) => {
        const errors = {};
        setValues({
            unitIds: val.unitIds,
        })

        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcTheDienTu.name"/>
            <CardContent>
                <ReportForm params={values}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <label>Danh sách lớp/phòng ban</label>
                            <div style={{maxHeight: '300px', overflow: 'auto'}}>
                            <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                 filter={{schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''}}
                                                 perPage={999} {...props} >
                                <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}
                                                   onChange={(e) => props.onChange(e)}/>
                            </ReferenceArrayInput>
                            </div>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    translate
)(BcTheDienTu)


import React, {useEffect, useState} from 'react';
import {SaveButton, SimpleForm, TextInput, Toolbar} from "react-admin";
import {Grid, showErrorNotify, showSuccessNotify} from "../../components";
import axiosInstance from "../../api/axios";
import {CircularProgress} from '@material-ui/core';
import {addField} from 'ra-core';
import CancelButton from "../../components/CancelButton";


const CustomToolbar = addField(props => {
    return <Toolbar {...props}>
        <SaveButton/>
        <CancelButton onClick={() => {
            window.location.replace('/#/face')
        }}/>
    </Toolbar>
})

const ImageInput = addField(props => {
    const {input} = props;

    const onChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                input.onChange(reader.result)
            }
        }
    }
    return <>
        <input
            type="file"
            name="myImage"
            accept="image/*"
            onChange={onChange}
        />
        <img src={input.value} width={150} height={120}/>
    </>
})


const FaceEdit = (props) => {
    const {history, location} = props
    const [value, setValue] = useState()
    useEffect(() => {
        axiosInstance
            .get(`/api${location.pathname}${location.search}`)
            .then(res => {
                if (res.status === 200) {
                    setValue({...res.data})
                } else {
                    history.goBack()
                }
            }, err => {
                showErrorNotify(err.message)
                history.goBack()
            })
    }, [])

    const save = value => {
        axiosInstance.put('/api/face', value).then(res => {
            setTimeout(() => {
                history.push({pathname: '/face'})
                showSuccessNotify('Cập nhật thành công')
            }, 1000)
        }, err => {
            showErrorNotify(err.message)
        })
    }
    const validate = (value) => {
        const {name} = value
        if (!name || name.trim() === '') {
            return {name: 'Bắt buộc'}
        }
    }

    if (!value) {
        return <CircularProgress/>
    }

    return <SimpleForm toolbar={<CustomToolbar/>}
                       save={save}
                       defaultValue={value}
                       validate={validate}
    >
        <Grid item xs={12} sm={6}>
            <TextInput source="id" label='faceID' disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="machineBoxId" disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="name"/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ImageInput source='faceUrl'/>
        </Grid>
    </SimpleForm>
}

export default FaceEdit

import React from 'react';
import {downloadFile, NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from "../../components";
import env from "../../env";
import {ReferenceInput, Title} from "react-admin";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

class CustomerHealthBatchTemplate extends React.Component {
    state = {
        loading: false,
        unitIds: [],
        schoolYearId: schoolYears[0].id,
    };

    getReportUrl = (fullDay, allStudent) => {
        this.setState({
            loading: true
        })
        downloadFile(`${env.baseURL}/api/customerHealthBatches/template?` +
            `fullDayOnly=` + fullDay +
            `&allStudent=` + allStudent +
            `&pSchoolYearId=` + this.state.schoolYearId +
            `&pUnitIds=` + this.state.unitIds, 'mau_suc_khoe_.xlsx')
            .then(() => {
                this.setState({
                    loading: false
                })
            }).catch(() => {
            this.setState({
                loading: false
            })
        })
    }

    billBatchTemplateAllClazzes = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, true)
    }

    fullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }

        this.getReportUrl(true, false)
    }

    notFullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, false)
    }

    validate = (values) => {const errors = {};
        this.setState({
            unitIds: values.unitIds,
        })
        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.customerHealthBatchTemplate.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                allClazzes={this.billBatchTemplateAllClazzes}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                                isLoading={this.state.loading}
                    >
                        <Grid container spacing={8}>
                          <Grid item xs={12} sm={6}>
                              <label>Danh sách lớp *</label>
                              <div style={{maxHeight: '450px', overflow: 'auto'}}>
                                      <ReferenceInput source="unitIds"
                                                      reference="units"
                                                      perPage={999}
                                                      filter={{
                                                          clazzWithParents: true,
                                                          schoolYear: this.state.schoolYearId
                                                      }}
                                                      {...this.props} >
                                          <TreeCheckboxInput optionValue="id" optionText="name"
                                                             expandTopLevel={true}/>
                                      </ReferenceInput>
                              </div>
                          </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default CustomerHealthBatchTemplate


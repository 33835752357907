import React from 'react';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {DateInput, ReferenceArrayInput, required, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class DiemDanhSo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventIds: [],
            unitIds: [],
            isLoading: false,
            month: moment().format('YYYY-MM-DD'),
        };
    }

    excelReport = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        if (!this.state.eventIds || this.state.eventIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách sự kiện")
            return;
        }
        this.setState({
            isLoading: true
        });
        downloadFile(this.getReportUrl(), 'diem_danh_so.xlsx')
            .then(() => {
                this.setState({
                    isLoading: false
                });
            }).catch(() => {
            this.setState({
                isLoading: false
            });
        })
    }

    getReportUrl = () => {
        return `${env.baseURL}/reports/diemDanhSo?` +
            `unitIds=` + this.state.unitIds +
            `&eventIds=` + this.state.eventIds +
            `&month=` + this.state.month;
    }

    validate = (values) => {
        const errors = {};
        // Copy input values to params
        this.setState({
            unitIds: values.unitIds,
            eventIds: values.eventIds,
            month: values.month,
        });
        return errors;
    }


    render() {
        return (
            <Card>
                <Title title="resources.diemDanhSo.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12}>
                                <DateInput label="resources.diemDanhSo.month" source="month"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Lớp *</label>
                                <div style={{maxHeight: '350px', overflow: 'auto'}}>
                                    <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                         filter={{schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''}}
                                                         perPage={999} {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceArrayInput>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Sự kiện *</label>
                                <div style={{maxHeight: '350px', overflow: 'auto'}}>
                                    <ReferenceArrayInput source="eventIds" reference="events"
                                                         filter={{filterPath: 'filterSimple', type: 'ATTENDANCE'}}
                                                         sort={{field: 'name', order: 'ASC'}}
                                                         perPage={999} {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceArrayInput>
                                </div>

                            </Grid>
                        </Grid>

                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default DiemDanhSo;

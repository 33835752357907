import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, submit} from 'redux-form';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import Swal from "sweetalert2";
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";

const styles = {};

class ActiveButton extends Component {
    showBtn = false;
    path = '';
    label = 'Bật/Tắt'
    title = "Bạn chắc chắn muốn thay đổi trạng thái cổng thanh toán?";
    text = "Nếu tắt sẽ không thể truy vấn thanh toán qua cổng thanh toán. ";
    successMessage = 'Thay đổi cấu hình thành công!';

    constructor(props) {
        super(props);
        if (props.record) {
            this.path = props.basePath;
        }
    }

    handleClick = () => {
        const record = this.props.record;
        if (record) {
            Swal.fire({
                title: this.title,
                text: this.text,
                type: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false
            })
                .then((result) => {
                    const newStt = !record.active
                    if (result.value) {
                        this.activeOrInactive(record.id, newStt)
                            .then(({data}) => {
                                showSuccessNotify(this.successMessage)
                                this.props.refreshView();
                            })
                            .catch(error => {
                                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                            })
                            .finally(() => {

                            });
                    }
                });
        } else {
            showErrorNotify("Vui lòng chọn dữ liệu")
        }

    };

    activeOrInactive = (id, newStt) => {
        const url = `/api${this.path}/${id}?active=${newStt}`;
        return axiosInstance.put(url);
    };

    render() {
        return (
            <Button onClick={this.handleClick} label={this.label}>
            </Button>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    ActiveButton
);

import React, {useState} from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import {Button, Grid} from '@material-ui/core';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  TextInput,
  translate
} from 'react-admin';

import {
  DaysOfWeekCheckboxInput,
  RecurringTypeInput,
  schoolYears,
  TreeCheckboxInput
} from '../../components'
import WhiteListDialog from "./WhiteListDialog";
import axiosInstance from "../../api/axios";
import TreeCheckBoxCustomerInput from "../../components/TreeCheckBoxCustomInput";

const styles = {
  checkboxGroup: {
    "& > div ": {
      flexDirection: 'column',
    }
  }
}

const EventFields_old = ({translate, classes, ...props}) => {
  const [values, setValues] = React.useState({
    unitIds: [],
    excludeUnitIds: [],
    customerIds: [],
    open: false
  });
  const [customers, setCustomers] = React.useState([]);

  React.useEffect(() => {
    if (Object.entries(props.record).length > 0) {
      setValues({
        unitIds: props.record.unitIds,
        excludeUnitIds: props.record.excludeUnitIds,
        customerIds: props.record.customerIds
      });
    }
    getCustomersWithUnits();
  }, []);
  
  function getCustomersWithUnits() {
    const url = `/api/customersByUnits`;
    axiosInstance.get(url).then(rs => {
      setCustomers(rs.data.content)
    });
  }

  const handleChange = prop => e => {
    setValues({...values, [prop]: e});
  };

  return (
      <FormDataConsumer>
        {({formData, dispatch, ...rest}) => (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextInput source="name" validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BooleanInput source="enabled" {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BooleanInput source="recurring" {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BooleanInput source="accessControlOnly" {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {formData && formData.recurring && <RecurringTypeInput
                    source="recurringType" {...props} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                {formData && formData.recurring && formData.recurringType
                === 'DAILY' && (
                    <TextInput source="separationCount"
                               label="resources.events.fields.separationCountDaily"
                               validate={required()} disabled
                               InputProps={{readOnly: true,}} {...props}
                    />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceInput source="eventGroupId"
                                reference="eventGroups" {...props} >
                  <AutocompleteInput source="name"/>
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6} >
                <ReferenceInput source="eventLocationId" reference="eventLocations" {...props} >
                  <AutocompleteInput source="name" />
                </ReferenceInput>
              </Grid>
              {formData && formData.recurring && formData.recurringType
              === 'WEEKLY' && (
                  <React.Fragment>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="separationCount"
                                 label="resources.events.fields.separationCountWeekly"
                                 validate={required()} disabled
                                 InputProps={{readOnly: true,}} {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DaysOfWeekCheckboxInput source="daysOfWeek" {...props}
                                               className={classes.checkboxGroup}
                      />
                    </Grid>
                  </React.Fragment>
              )}

              <Grid item xs={12} sm={6}>
                <DateInput source="startDate"
                           validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput source="endDate" {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput type="time" source="startTime" {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput type="time" source="endTime" {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BooleanInput source="needCheckIn" {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BooleanInput source="needCheckOut" {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="lateComePermit"
                           validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="earlyLeavePermit"
                           validate={required()} {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="openForCheckIn"
                           validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="endForCheckIn"
                           validate={required()} {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="openForCheckOut"
                           validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput type="number" source="endForCheckOut"
                           validate={required()} {...props} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="deviceIds"
                                     reference="accessDevices" {...props} >
                  <AutocompleteArrayInput/>
                </ReferenceArrayInput>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ReferenceArrayInput source="sourceEventIds"
                                     reference="events" {...props} >
                  <AutocompleteArrayInput/>
                </ReferenceArrayInput>
              </Grid>

              <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 50}}>
                <label>Đơn vị</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceArrayInput source="unitIds"
                                       reference="units"
                                       perPage={999}
                                       filter={{schoolYear: schoolYears[0].id}}
                                       {...props} >
                    <TreeCheckboxInput optionValue="id" optionText="name"
                                       expandTopLevel={true}
                                       onChecked={handleChange('unitIds')}
                    />
                  </ReferenceArrayInput>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 50}}>
                <label>Đơn vị loại trừ</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceArrayInput source="excludeUnitIds"
                                       reference="units"
                                       perPage={999}
                                       filter={{schoolYear: schoolYears[0].id}}
                                       {...props} >
                    <TreeCheckboxInput optionValue="id" optionText="name"
                                       expandTopLevel={true}
                                       onChecked={handleChange('excludeUnitIds')}/>
                  </ReferenceArrayInput>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 50}}>
                <label>Người tham dự</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceInput source="customerIds"
                                  reference="customers"
                                  filter={{schoolYear: schoolYears[0].id}}>
                    <TreeCheckBoxCustomerInput data={customers}
                                             selected={values.customerIds}
                                             expandTopLevel={true}
                                             onChange={handleChange('customerIds')}/>
                  </ReferenceInput>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} style={{marginTop: 30}}>
                {(values.unitIds.length > 0 || values.customerIds.length > 0) &&
                  <Button variant="outlined" color="primary"
                          onClick={handleChange('open', true)}>
                    Danh sách tham dự
                  </Button>
                }
              </Grid>

              <WhiteListDialog close={handleChange('open', false)}
                               open={values.open}
                               unitIds={values.unitIds}
                               customerIds={values.customerIds}
                               excludeUnitIds={values.excludeUnitIds}/>
            </React.Fragment>
        )}
      </FormDataConsumer>
  )
}

export default compose(
    translate,
    withStyles(styles)
)(EventFields_old)

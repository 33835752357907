import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import axiosInstance from "../../api/axios";
import {downloadFile, showErrorNotify, showSuccessNotify} from "../../components";
import env from "../../env";
import {downloadFilePost} from "../../components/FileDownload";

const ExportReportButton = (props) => {
    const exportPdf = () => {
        doExport('PDF')
    }
    const exportExcel = () => {
        doExport('XLS')
    }
    const doExport = (type) => {
        downloadFilePost(`${env.baseURL}/api${props.basePath}/export?type=` + type, 'danh_sach.xlsx', props.selectedIds)
    }

    return (
        <div>
            <Button style={{marginRight: '10px'}} variant="outlined" label="Xuất PDF" onClick={exportPdf}/>
            <Button style={{marginRight: '10px'}} variant="outlined" label="Xuất excel" onClick={exportExcel}/>
        </div>
    );
}

export default connect(null, {refreshView})(
    ExportReportButton
);


import BlockIcon from '@material-ui/icons/ViewList';

import ApartmentViewList from './ApartmentViewList';
import ApartmentViewCreate from './ApartmentViewCreate';
import ApartmentViewEdit from './ApartmentViewEdit';

export default {
  list: ApartmentViewList,
  create: ApartmentViewCreate,
  edit: ApartmentViewEdit,
  icon: BlockIcon,
};

import React, {useEffect, useState} from 'react';
import {Button, CheckboxGroupInput, Datagrid, Filter, List, showNotification, TextField, TextInput} from "react-admin";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import {CircularProgress} from '@material-ui/core';


const FaceImage = ({record}) => {
    return <img src={record.faceUrl} width={80} height={60}/>
}

const ListActions = () => {
    return <></>
}

const BulkActionButtons = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const faceIds = props.selectedIds
    const deviceNos = props.filterValues.deviceNos || []

    const onDelete = () => {
        setIsLoading(true)
        axiosInstance.post(`/api/face/delete?deviceNos=${deviceNos}`, faceIds).then(res => {
            showSuccessNotify('Xoá thành công')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
            setIsLoading(false)
        }, err => {
            showErrorNotify(err.message)
            setIsLoading(false)
        })
    }
    return <>
        {isLoading && <CircularProgress style={{marginLeft: 10}} color="secondary"/>}
        <Button disabled={isLoading} onClick={onDelete} label="ra.action.delete" style={{color: 'red'}}>
            <DeleteIcon/>
        </Button>
    </>
}

const EditButton = ({record}) => {
    const url = `/#/face/${record.id}?machineBoxId=${record.machineBoxId}`;
    return <Button label='ra.action.edit' onClick={() => window.location.replace(url)}/>
}

const Filters = (props) => {
    const [devices, setDevices] = useState([])

    useEffect(() => {
        axiosInstance.get('/api/accessDevices/search/findByType?type=FACE').then(res => {
            if (res.status === 200 && res.data.content[0]?.id) {
                setDevices(res.data.content)
            }
        })
    }, [])


    return <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <CheckboxGroupInput label='Thiết bị' source="deviceNos" alwaysOn
                            choices={devices.map(item => ({id: item.deviceNo, name: item.name}))}/>
    </Filter>
}

const FaceList = (props) => {
    return <>
        <List {...props}
              actions={<ListActions/>}
              bulkActionButtons={<BulkActionButtons/>}
              hasCreate={false}
              filters={<Filters/>}
        >
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField source="machineBoxId"/>
                <FaceImage label='Hình ảnh'/>
                <EditButton/>
            </Datagrid>
        </List>
    </>
}

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(FaceList)

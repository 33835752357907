import React from 'react';
import Button from '@material-ui/core/Button';

const style = {
  btnAuthorize: {
    marginLeft: 10
  }
}

const ImageUploadComponent = (props) => (
    <form>
      <h4>Chọn hình</h4>
      <input id="contained-button-file" type="file" accept=".png,.jpg,.jpeg"
             onChange={props.onFileChanged}/>
      <Button style={style.btnAuthorize} variant="contained" component="span"
              onClick={props.onFileUpload}>
        Cập nhật
      </Button>
    </form>
)

export default ImageUploadComponent;

import React from 'react';
import {BooleanField, Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

const AccessDeviceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const AccessTerminalList = props => (
    <List filters={<AccessDeviceFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="deviceNo"/>
            <BooleanField source="sendNotification"/>
            <BooleanField source="enabled"/>
            <ReferenceField source="tenantId" reference="tenants" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);

export default AccessTerminalList

import React from 'react';
import env from "../env";
import GetAppIcon from '@material-ui/icons/GetApp';
import {downloadFile} from "./FileDownload";
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {showNotification, Button} from 'react-admin';

const styles = {
    button: {
        margin: 1,
        width: 180
    },
};
const DownloadTemplateFileButton = (props) => {

    const {basePath, btnLabel} = props

    const handleClick = (e) => {
        e.stopPropagation();
        const url = env.baseURL + basePath;
        downloadFile(url, basePath + '.xlsx')
    };

    return (
        <React.Fragment>
            <Button color="primary" style={styles.button}
                    onClick={handleClick} label={btnLabel}>
                <GetAppIcon/>
            </Button>
        </React.Fragment>
    )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(styles)
)(DownloadTemplateFileButton)

import React from 'react';
import { List, Datagrid, Filter, ReferenceField, DateField, TextField, TextInput } from 'react-admin';

const AttendanceDetailsChangedHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const AttendanceDetailsChangedHistoryList = props => (
  <List filters={<AttendanceDetailsChangedHistoryFilter />} bulkActionButtons={false} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <ReferenceField source="changedObjId" reference="attendanceResultDetailses">
        <TextField source="id" />
      </ReferenceField>
        <TextField source="customerId" />
      <ReferenceField source="changedObjId" reference="attendanceResultDetailses" label={"Học sinh"}>
        <TextField source="customerName" />
      </ReferenceField>
      <TextField source="lastUpdatedBy" />
      <DateField source="changedDateTime" showTime />
      <TextField source="displayOldStt" />
      <TextField source="displayNewStt" />
    </Datagrid>
</List>
);

export default AttendanceDetailsChangedHistoryList

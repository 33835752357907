import React from 'react';
import { List, Datagrid, Filter, BooleanField, TextField, TextInput } from 'react-admin';

const SubMenuFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const SubMenuList = props => (
  <List filters={<SubMenuFilter />} {...props} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="enabled" />
    </Datagrid>
</List>
);

export default SubMenuList
import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";

class BaoCaoDiemDanhVang extends React.Component {
    state = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        eventIds: [],
        schoolYearId: schoolYears[0].id
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/baoCaoDiemDanhVang?` +
            `fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate +
            `&schoolYearId=` + this.state.schoolYearId +
            `&eventIds=` + this.state.eventIds;
    };


    excelReport = () => {
        downloadFile(this.getReportUrl(), 'bao_cao_diem_danh_vang.xlsx')
    };

    validate = (values) => {
        const errors = {};
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
            errors.toDate = 'To date must be greater than From date'
        }
        this.setState(values);

        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="resources.baoCaoDiemDanhVang.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách sự kiện:</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="eventIds"
                                                    reference="events"
                                                    perPage={999}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                            <ReferenceInput source="schoolYearId"
                                            reference="schoolYears"
                                            label="resources.schoolYears.name"
                                            perPage={50}
                                            validate={required()}
                                            {...this.props} >
                                <AutocompleteInput source="name" />
                            </ReferenceInput>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default BaoCaoDiemDanhVang


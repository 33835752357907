import AttendanceResultDetailsIcon from '@material-ui/icons/ViewList';

import AttendanceResultDetailsList from './AttendanceResultDetailsList';
// import AttendanceResultDetailsCreate from './AttendanceResultDetailsCreate';
// import AttendanceResultDetailsEdit from './AttendanceResultDetailsEdit';

export default {
  list: AttendanceResultDetailsList,
  // create: AttendanceResultDetailsCreate,
  // edit: AttendanceResultDetailsEdit,
  icon: AttendanceResultDetailsIcon,
};

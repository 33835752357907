import React from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import {showErrorNotify, showInfoNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Swal from "sweetalert2";
import env from "../../env";
import {put} from "axios";

class AuthorizesProfileButton extends React.Component {

    authorizeAllItem() {
        const url = `${env.baseURL}/api/profileChangeLogs/authorizeAll`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        return put(url, {}, config)
    }

    handleClick = (e) => {
        e.stopPropagation();
        Swal.fire({
            title: "Bạn chắc chắn muốn duyệt tất cả thay đổi này này?",
            text: "Sau khi duyệt, thông tin của tất cả những người này sẽ được thay đổi.",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                if (result.value) {
                    showInfoNotify("Đang duyệt, vui lòng đợi trong giây lát!");
                    this.forceUpdate()
                    this.authorizeAllItem().then(rs => {
                        if (rs.data.success) {
                            showSuccessNotify("Đã duyệt dữ liệu thành công!");
                            setTimeout(() => {
                                window.location.reload()
                            }, 3000)
                        } else {
                            showWarningNotify("Có dữ liệu duyệt không thành công!");
                            setTimeout(() => {
                                window.location.reload()
                            }, 3000)
                        }
                    })
                        .catch(error => {
                            showErrorNotify("Duyệt dữ liệu bị lỗi. Vui lòng liên hệ quản trị viên!");
                        });
                }
            });
    };

    render() {
        return (
            <Button color="primary"
                    onClick={this.handleClick} label={'Duyệt tất cả'}>
            </Button>
        )
    }
}


export default compose(
    connect(undefined, {refreshView}),
    withStyles({})
)(AuthorizesProfileButton)

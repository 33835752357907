import React from 'react';
import {Edit, SaveButton, SimpleForm, Toolbar} from 'react-admin';

import {CancelButton, Grid} from '../../components'
import MealRegistrationFields from './MealRegistrationFields'
import moment from "moment";

const CustomToolBar = props => {
    const {eatDate, finalizeDate, canEdit} = props.record
    const disabled = canEdit && !!finalizeDate && moment(eatDate).isSameOrBefore(moment(finalizeDate))
    return <Toolbar {...props}>
        <SaveButton disabled={disabled} redirect={props.redirect || "list"}/>
        <CancelButton/>
    </Toolbar>
}
export const MealRegistrationEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolBar {...props}/>}>
            <Grid container spacing={8}>
                <MealRegistrationFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default MealRegistrationEdit

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { compose } from 'redux';
import {connect} from 'react-redux';

const styles = {
  formControl: {
    margin: 1,
    minWidth: 200
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 3
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

function MultipleSelectComponent(props) {
  const {classes, data, selectedData, label, onChange, marginLeft} = props;
  const [displayData, setDisplayData] = React.useState([]);

  React.useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      let tmp = [];
      data.map(d => {
        if (selectedData.includes(d.key)) {
          tmp.push(d.value);
        }
      })
      setDisplayData(tmp);
    }
  }, [selectedData, data]);


  const handleChange = event => {
    const selectedVal = event.target.value;
    let tmp = [];
    data.map(d => {
      if (selectedVal.includes(d.value)) {
        tmp.push(d.key);
      }
    })
    onChange(tmp);
    setDisplayData(selectedVal);
  };

  const handleDelete = (val) => {
    let tmpData = displayData.filter(i => i !== val);
    setDisplayData(tmpData);
    let tmp = [];
    data.map(d => {
      if (tmpData.includes(d.value)) {
        tmp.push(d.key);
      }
    })
    onChange(tmp);
  }

  return (
      <FormControl className={classes.formControl} style={{marginLeft: marginLeft}}>
        <InputLabel id="mutiple-chip-label">{label}</InputLabel>
        <Select
            labelId="mutiple-chip-label"
            id="mutiple-chip"
            multiple
            value={displayData}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} onDelete={() => handleDelete(value)}/>
                  ))}
                </div>
            )}
            MenuProps={MenuProps}
        >
          {data.map((data) => (
              <MenuItem key={data.key} value={data.value} >
                {data.value}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}

export default compose(
    connect(null),
    withStyles(styles)
)(MultipleSelectComponent)

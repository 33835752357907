import React from 'react';
import {
    AutocompleteInput,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import {schoolYears} from "../../components";

const CompanyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <ReferenceInput source="unitId" reference="clazzes" alwaysOn allowEmpty
                        filter={{schoolYearId: schoolYears[0].id}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
                        label='Lớp'
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

export const CustomerHealthList = props => (
    <List actions={false} filters={<CompanyFilter/>} {...props} exporter={false} bulkActions={false}>
        <Datagrid rowClick="edit">
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="ordinal"/>
            <TextField source="weight"/>
            <TextField source="height"/>
            <TextField source="bmi"/>
            <TextField source="sight"/>
            <TextField source="nutritionalClassification"/>
            <TextField source="semesterHealth"/>
            <TextField source='note' />
            <TextField source='lastUpdatedBy'/>
        </Datagrid>
    </List>
);

export default CustomerHealthList

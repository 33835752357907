import React from 'react';
import Grid from '@material-ui/core/Grid'
import {ReferenceInput, required, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin';

const ExemptionFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="customerId" reference="customers"
                      filter={{studentOnly: true}}
                      sort={{ field: 'name', order: 'ASC' }}
                      perPage={30}
                      validate={required()} {...props} >
        <AutocompleteInput optionText="displayName" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceArrayInput source="exemptionPolicyIds" reference="exemptionPolicies" validate={required()} {...props} >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    </Grid>

  </React.Fragment>
)

export default ExemptionFields
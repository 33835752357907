import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../../components';
import env from '../../../env';
import moment from 'moment';

class GiaoDichTaiKhoan extends React.Component {
    state = {
        billAccountId: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/baoCaoGiaoDichTaiKhoan` +
            `?billAccountId=` + this.state.billAccountId +
            `&fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate;
    }

    excelReport = () => {
        downloadFile(this.getReportUrl(), 'bao_cao_giao_dich_tai_khoan_hoc_phi.xlsx')
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'Ngày kết thúc phải lớn hơn ngày bắt đầu'
        }

        this.setState(values)


        return errors;
    }


    render() {
        return (
            <Card>
                <Title title="resources.baoCaoGiaoDichTaiKhoan.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="billAccountId"
                                                reference="billAccounts"
                                                label="resources.billAccounts.name"
                                                perPage={50}
                                                {...this.props} >
                                    <AutocompleteInput optionText="accountName"/>
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default GiaoDichTaiKhoan


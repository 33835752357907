import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Show, SimpleForm, translate} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar'
import Grid from '../../components/CustomGrid'
import BillViewField from "./BillViewField";

const styles = {
  orderDetails: {
    // background: 'red',
    width: '100%',
    paddingTop: 16,
    "& > div > div:nth-child(1)": {
      minHeight: '10px',
    },
  }
}

export const BillViewShow = props => (
  <Show {...props}>
    <SimpleForm toolbar={<CustomToolbar viewOnly {...props} />}>
      <Grid item xs={12} sm={12} className={props.classes.orderDetails} >
        <BillViewField {...props}/>
      </Grid>

    </SimpleForm>
  </Show>
);

// export default OrderShow
export default compose(
  translate,
  withStyles(styles)
)(BillViewShow)


import React from 'react';
import Grid from '@material-ui/core/Grid'
import { ReferenceInput, SelectInput, TextInput, required } from 'react-admin';

const UnitFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" disabled validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="type" disabled {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="parentId" reference="units" disabled allowEmpty {...props} >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
  </React.Fragment>
)

export default UnitFields
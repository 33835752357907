import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const type = [
  { id: 'SCHEDULE', name: 'calendarType.SCHEDULE' },
  { id: 'EXAM',   name: 'calendarType.EXAM' },
]

export const CalendarTypeField = (props) => (
  <SelectField source={props.source} choices={type} {...props} />
)

export const CalendarTypeInput = (props) => (
  <SelectInput source={props.source} choices={type} {...props} />
)

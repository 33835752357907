import BillAccountBatchIcon from '@material-ui/icons/ViewList';

import BillAccountBatchList from './BillAccountBatchList';
import BillAccountBatchCreate from './BillAccountBatchCreate';
import BillAccountBatchEdit from './BillAccountBatchEdit';

export default {
  list: BillAccountBatchList,
  create: BillAccountBatchCreate,
  edit: BillAccountBatchEdit,
  icon: BillAccountBatchIcon,
};

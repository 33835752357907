import React from 'react';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from 'react-admin';

const DepartmentFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="parentId" reference="companies" validate={required()} allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="homeroomTeacher" reference="employeeEnrollments" allowEmpty {...props} >
                <AutocompleteInput optionText="customerName"/>
            </ReferenceInput>
        </Grid>

        <Grid item xs={12} sm={6}>
            <ReferenceArrayInput source="offRequestApprover" reference="customers" {...props}>
                <AutocompleteArrayInput optionText={'displayName'}/>
            </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12} sm={6}>
            <div style={{width: 400}}>
                <ReferenceArrayInput source="editStudentInfoIds"
                                     reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </div>
        </Grid>
        <Grid item xs={12} sm={6}>
            <div style={{width: 400}}>
                <ReferenceArrayInput source="authorizedStudentInfoIds"
                                     reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </div>
        </Grid>
    </React.Fragment>
)

export default DepartmentFields

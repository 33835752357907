import AccessDeviceIcon from '@material-ui/icons/ViewList';

import IntroductionFileList from './IntroductionFileList';
import IntroductionFileCreate from './IntroductionFileCreate';
import IntroductionFileEdit from './IntroductionFileEdit';

export default {
  list: IntroductionFileList,
  create: IntroductionFileCreate,
  edit: IntroductionFileEdit,
  icon: AccessDeviceIcon,
};

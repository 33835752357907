import React from 'react';
import { List, Datagrid, Filter, TextField, TextInput } from 'react-admin';

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const CompanyList = props => (
  <List filters={<CompanyFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="contactPhone" />
    </Datagrid>
</List>
);

export default CompanyList

import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';

import { translate } from 'react-admin'; // eslint-disable-line import/no-unresolved

const onClose = e => {
  window.history.back();
}

const styles = {
  button: {
    margin: '10px 24px',
    position: 'relative',
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
};

const CloseButtonView = ({ classes, label = 'ra.action.close', translate, ...props}) => (
  <Button className={classes.button} onClick={onClose}>
    <CloseIcon className={classes.iconPaddingStyle} />
    {label && translate(label, { _: label })}
  </Button>
);

CloseButtonView.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default compose(
  translate,
  withStyles(styles)
)(CloseButtonView);
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LensIcon from '@material-ui/icons/Lens';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

const classes = {
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  title: {
    margin: 2,
  },
  textField: {
    marginLeft: 10,
    marginRight: 1,
    width: 400,
  },
  icon: {
    height: 5,
    width: 5,
    marginTop: 15,
    color: 'black'
  },
  button: {
    backgroundColor: 'blue',
    width: 25,
    height: 25,
    color: 'white',
    marginLeft: 25
  }
};

export function QuestionComponent(props) {

  return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <TextField
                  id="standard-name"
                  label={"Nội dung câu hỏi"}
                  required={true}
                  value={props.questionText}
                  style={classes.textField}
                  onChange={(e) => props.onQuestionTextChanged(e, props.questionIdx)}
                  margin="normal"
              />
              <IconButton edge="end" aria-label="delete" onClick={() => props.deleteQuestion(props.questionIdx)}>
                <DeleteIcon />
              </IconButton>
              <div>
                <List>
                  {props.choices.map((choice, index) => (
                      <ListItem key={'list-item' + index}>
                        <ListItemAvatar>
                          <Avatar style={classes.icon}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <TextField
                            id={'txf' + index}
                            label={"Nội dung câu trả lời"}
                            required={true}
                            value={choice.choiceText}
                            style={classes.textField}
                            onChange={(e) => props.onChoiceTextChanged(e, props.questionIdx, index)}
                            margin="normal"
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => props.deleteChoice(props.questionIdx, index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                  ))}
                </List>
                <IconButton aria-label="add" style={classes.button} onClick={() => props.addChoice(props.questionIdx)}>
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
  );
}

import StudentEnrollmentBatchIcon from '@material-ui/icons/ViewList';

import PupilEnrollmentBatchList from './PupilEnrollmentBatchList';
import PupilEnrollmentBatchCreate from './PupilEnrollmentBatchCreate';
import PupilEnrollmentBatchEdit from './PupilEnrollmentBatchEdit';

export default {
    list: PupilEnrollmentBatchList,
    create: PupilEnrollmentBatchCreate,
    edit: PupilEnrollmentBatchEdit,
    icon: StudentEnrollmentBatchIcon,
};

import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import { DateInput, Title, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import {ReportForm, IFrameHtmlReport, schoolYears, downloadFile} from '../../components';
import env from '../../env';

class ClazzAttendance extends React.Component {
    state = {
        openHtmlReport: false,
        htmlReportUrl: '',
        clazzId: '',
        eventId: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    };

    getReportUrl = (type) => {
        const token = localStorage.getItem('token');
        return `${env.baseURL}/reports/clazz_attendance?` +
            `type=` + type +
            `&pClazzId=` + this.state.clazzId +
            `&pEventId=` + this.state.eventId +
            `&pFromDate=` + this.state.fromDate +
            `&pToDate=` + this.state.toDate +
            `&token=` + token;
    }

    getReportUrlToDownload = (type) => {
        return `${env.baseURL}/reports/clazz_attendance?` +
            `type=` + type +
            `&pClazzId=` + this.state.clazzId +
            `&pEventId=` + this.state.eventId +
            `&pFromDate=` + this.state.fromDate +
            `&pToDate=` + this.state.toDate;
    }

    htmlReport = () => {
        const htmlReportUrl = this.getReportUrl('HTML')

        this.setState({openHtmlReport: true, htmlReportUrl })
    }

    pdfReport = () => {
        downloadFile(this.getReportUrlToDownload('PDF'), 'bao_cao_diem_danh_lop.pdf')
    }

    excelReport = () => {
        downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_diem_danh_lop.xlsx')
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
            errors.toDate = 'To date must be greater than From date'
        }
        values.eventId = this.state.eventId;

        this.setState({
            clazzId: values.clazzId,
            fromDate: values.fromDate,
            toDate: values.toDate
        })

        return errors;
    }

    onClazzChanged(e, value) {
        this.setState({
            eventId: ''
        })
    }

    onEventChanged(e, value) {
        this.setState({
            eventId: value
        })
    }

    render() {
        return (
            <Card>
                <Title title="Báo cáo điểm danh lớp" />
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                htmlReport={this.htmlReport}
                                pdfReport={this.pdfReport}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6} >
                                <ReferenceInput source="clazzId" reference="clazzes"
                                                validate={required()}
                                                filter={{schoolYear: schoolYears[0].id, eventIncluded: true}}
                                                sort={{ field: 'name', order: 'ASC' }}
                                                onChange={(e, value) => this.onClazzChanged(e, value)}
                                                perPage={100}>
                                    <AutocompleteInput source="name" />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <ReferenceInput source="eventId"
                                                reference="events"
                                                filter={{filterPath: 'filterSimple', unitId: this.state.clazzId, accessControlOnly: false}}
                                                onChange={(e, value) => this.onEventChanged(e, value)}
                                                sort={{ field: 'name', order: 'ASC' }}
                                                perPage={50}>
                                    <AutocompleteInput source="name" />
                                </ReferenceInput>
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <DateInput source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <DateInput source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>

                    {/* The iframe to show HTML report */}
                    {
                        this.state.openHtmlReport &&
                        <IFrameHtmlReport src={this.state.htmlReportUrl} />
                    }
                </CardContent>
            </Card>
        )
    }
}

export default ClazzAttendance


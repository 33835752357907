import React from 'react';

const style = {
  marginTop: '10px', width: '100%', height: '600px', 
  border: '1px solid gray', borderRadius: '5px',
}

export default (props) => (
  <iframe title="Report" style={style} src={props.src} /> 
)

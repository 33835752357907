import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {
  downloadFile,
  PaymentMethodInput,
  ReportForm,
  schoolYears,
  showErrorNotify,
  TreeCheckboxInput
} from '../../components';
import env from '../../env';

class HsDaDongTienTheoDanhMuc extends React.Component {
  state = {
    htmlReportUrl: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    changeSchoolYear: false,
    unitIds: [],
    schoolYearId: schoolYears[0].id,
    productId: 0,
    paymentMethod: ''
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/hsDaDongTienTheoDanhMuc?` +
        `fromDate=` + this.state.fromDate +
        `&toDate=` + this.state.toDate +
        `&productId=` + this.state.productId +
        `&paymentMethod=` + this.state.paymentMethod +
        `&unitIds=` + this.state.unitIds +
        `&schoolYearId=` + this.state.schoolYearId;
  }

  excelReport = () => {
    if (!this.state.productId || this.state.productId === 0) {
      showErrorNotify("Vui lòng chọn danh sách phí")
      return;
    }
    downloadFile(this.getReportUrl(), 'bang_ke_danh_muc_theo_hs.xlsx')
  };

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }
    // Copy input values to params
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      paymentMethod: values.paymentMethod,
      productId: values.productId,
      schoolYearId: values.schoolYearId
    });
    if (this.state.changeSchoolYear) {
      this.setState({
        unitIds: [],
        changeSchoolYear: false
      })
    } else {
      this.setState({
        unitIds: values.unitIds
      })
    }
    return errors;
  };

  onSchoolYearChanged(e, value) {
    this.setState({
      schoolYearId: value,
      changeSchoolYear: true
    });
  }

  render() {
    return (
      <Card>
        <Title title="resources.hsDaDongTienTheoDanhMuc.name" />
        <CardContent>
          <ReportForm params={this.state}
            validate={this.validate}
            excelReport={this.excelReport}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <PaymentMethodInput source="paymentMethod" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceInput source="productId"
                                reference="billProducts"
                                label="resources.products.name"
                                perPage={999}
                                validate={required()}
                                {...this.props} >
                  <AutocompleteInput optionValue="id" optionText="name"/>
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <ReferenceInput source="schoolYearId"
                                reference="schoolYears"
                                label="resources.schoolYears.name"
                                perPage={50}
                                validate={required()}
                                onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                {...this.props} >
                  <AutocompleteInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Danh sách lớp *</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceInput source="unitIds"
                                  reference="units"
                                  perPage={999}
                                  filter={{clazzWithParents: true, schoolYear: this.state.schoolYearId}}
                                  {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                  </ReferenceInput>
                </div>
              </Grid>
            </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default HsDaDongTienTheoDanhMuc;


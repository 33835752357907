const ALPHABET = "23456789BCDFGHJKLMNPQRSTVWXYZ"
const BASE = ALPHABET.length;

const encode = (num) => {
  let result = ''
  while (num > 0) {
    let index = num % BASE;
    result = ALPHABET[index] + result

    num = Math.floor(num / BASE);
  }

  return result
}

export default {
  encode
}

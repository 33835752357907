import React from 'react';
import {
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {DateTimeField, DownloadTemplateFileButton, schoolYears} from "../../components";
import {CalendarTypeField} from "../../components/CalendarType";

const GoogleCalendarFilter = (props) => (
    <Filter {...props}>
        <SelectInput  label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                      alwaysOn allowEmpty={false}
        />
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <DownloadTemplateFileButton basePath="/api/googleCalendar/getTemplate" btnLabel="ra.action.downloadTemplate"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);
export const GoogleCalendarList = props => (
    <List filters={<GoogleCalendarFilter/>} filterDefaultValues={{schoolYear: schoolYears[0].id}}  {...props} actions={<PostActions/>} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"  style={{width: '20% !important'}}/>
            <CalendarTypeField source="type"  style={{width: '5% !important'}}/>
            <DateTimeField source="startDate" format="DD-MM-YYYY"  style={{width: '20% !important'}}/>
            <DateTimeField source="endDate" format="DD-MM-YYYY"  style={{width: '20% !important'}}/>
            <ReferenceField source="schoolYearId" reference="schoolYears" allowEmpty  style={{width: '20% !important'}}>
                <TextField source="name"/>
            </ReferenceField>
            <ViewGoogleCalendarButton label="Hành động"/>
        </Datagrid>
    </List>
);

export default GoogleCalendarList

const ViewGoogleCalendarButton = ({record}) => (
    <a className='button' onClick='event.preventDefault();' target="_blank" rel="noopener noreferrer"
       href={`https://calendar.google.com/calendar/embed?mode=WEEK&showCalendars=0&showPrint=0&src=` + record.googleCalendarId}>
        Xem lịch
    </a>

);
ViewGoogleCalendarButton.defaultProps = {addLabel: true};

import ExemptionIcon from '@material-ui/icons/ViewList';

import ExemptionList from './ExemptionList';
import ExemptionCreate from './ExemptionCreate';
import ExemptionEdit from './ExemptionEdit';

export default {
  list: ExemptionList,
  create: ExemptionCreate,
  edit: ExemptionEdit,
  icon: ExemptionIcon,
};

import React from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, ReferenceInput, required, TextInput} from 'react-admin';

const TeacherClazzFields = ({classes, ...props}) => {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <TextInput source="subject" validate={required()} {...props} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="customerId" reference="customers"
                                filter={{employeeOnly: true}}
                                sort={{field: 'name', order: 'ASC'}}
                                perPage={30}
                                validate={required()} {...props} >
                    <AutocompleteInput optionText="displayName"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="unitId" reference="clazzes" validate={required()} allowEmpty {...props} >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
            </Grid>
        </React.Fragment>
    )
}
export default TeacherClazzFields;

import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import Grid from '@material-ui/core/Grid'

import RolePrivilegesFields from './RolePrivilegesFields'
import SimpleToolbar from "../../components/SimpleToolbar";

export const RolePrivilegesCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<SimpleToolbar {...props} />} redirect="list" >
      <Grid container spacing={8}>
        <RolePrivilegesFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default RolePrivilegesCreate

import React from 'react';
import { ReferenceInput, SelectInput, TextInput, required } from 'react-admin';
import Grid from '@material-ui/core/Grid';

const ApartmentFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="parentId" reference="floors" validate={required()} allowEmpty {...props} >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
    </Grid>
  </React.Fragment>
)

export default ApartmentFields

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, isSubmitting, submit} from 'redux-form';
import {
  AutocompleteInput,
  Button,
  fetchEnd,
  fetchStart,
  ReferenceInput,
  refreshView,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import {downloadFile, Grid, printContent, showErrorNotify, showSuccessNotify} from '../components'
import PaymentIcon from '@material-ui/icons/Payment';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {get, post} from "axios";
import env from "../env";
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format';
import './popup.css';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {PaymentMethodInput} from "./PaymentMethod";
import axiosInstance from "../api/axios";

const styles = {
  suggestDialog: {
    zIndex: '1 !important',
  },
  width100: {
    width: '100%',
  }
};

class PartnerPostQuickPayBillButton extends Component {
  state = {
    showDialog: false,
    showBtn: true,
    disableInput: false,
    enableSelectTenant: false,
    totalPayment: 0,
    fullDay: true,
    enableElectricBill: false,
    enableShowPrint: true,
    payRoundBill: false,
    totalCanPayAmount: 0,
    monthChoices: [],
  };

  params = {
    customerId: 0,
    tenantId: 0,
    clazzName: '',
    relationshipName: '',
    relationshipAddress: '',
    totalPayment: 0,
    remainPaymentAmount: 0,
    numOfMonth: 1
  };

  constructor(props) {
    super(props);
    this.state.disableInput = true;
  }

  handleClick = () => {
    this.setState({
      showDialog: true,
    });
    const record = this.props.record;
    if (record) {
      this.params.customerId = record.customerId;
      this.params.tenantId = record.tenantId;
      this.setState({
        enableSelectTenant: false
      });
    } else {
      this.setState({
        enableSelectTenant: true
      });
    }

    this.processChangePaymentInfo();
  };

  changePaymentInfo = (e, customerId) => {
    this.params.customerId = customerId;
    this.processChangePaymentInfo();
  };

  changeTenant = (e, tenantId) => {
    this.setState({
      disableInput: false,
    });

    this.params = {}
    this.params.tenantId = tenantId;
  };

  processChangePaymentInfo() {
    if (this.params.customerId) {
      this.getPaymentInfo().then(({data}) => {
        this.params = {}
        if (data) {
          this.params = data;
          this.params.paymentMethod = 'CASH';
          this.params.numOfMonth = 1;
          this.setState({
            tenantId: data.tenantId,
            totalPayment: data.totalPayment,
            maxPaymentAmount: data.totalPayment,
            remainPaymentAmount: data.remainPaymentAmount,
            enableElectricBill: data.enableElectricBill,
            enableShowPrint: data.enableShowPrint,
            payRoundBill: data.payRoundBill,
            bills: data.bills,
            availableBills: data.availableBills,
            totalCanPayAmount: data.totalCanPayAmount,
            fullDay: data.fullDay
          });
          this.changeNumOfMonth(1);
        } else {
          console.log(' data null')
        }
      });
    }
  }

  getPaymentInfo() {
    const url = `${env.baseURL}/api/partnerBillViews/getPaymentInfo?customerId=` + this.params.customerId
    + `&tenantId=` + this.params.tenantId;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return get(url, config);
  }

  handleCloseClick = () => {
    this.state.totalPayment = 0;
    this.params = {}
    this.setState({
      showDialog: false,
      disableInput: true,
      bills: [],
      totalPayment: 0
    })
  };

  renderTable() {
    let table = [];
    if (this.state.bills && this.state.bills.length > 0) {
      this.state.monthChoices = [];
      table.push(<Table><TableBody><TableRow><TableCell colSpan={3}>Chi tiết thanh
        toán</TableCell></TableRow>{this.renderTableRow()}</TableBody></Table>)

    } else if (this.state.remainPaymentAmount) {
      table.push(<span>Số tiền thừa: {this.formatNumber(this.state.remainPaymentAmount)} <br/> Hiện chưa có phiếu học phí cần thanh toán</span>);
    } else if (this.params.customerId) {
      table.push(<span>Hiện chưa có phiếu học phí cần thanh toán</span>);
    }

    return table;
  }

  renderTableRow() {
    let total = 0;
    let table = []
    for (let i = 0; i < this.state.bills.length; i++) {
      this.state.monthChoices.push({
        id: i + 1,
        name: i + 1
      });
      const bill = this.state.bills[i];
      total += bill.billBalance;
      table.push(<TableRow key={i}><TableCell align="right">{bill.billNo}</TableCell><TableCell
        align="right">{bill.billMonth}</TableCell><TableCell
        align="right">{this.formatNumber(bill.billBalance)}</TableCell></TableRow>);
    }
    // table.push(<TableRow><TableCell colSpan={3}>Tổng số tiền cần thanh toán: {this.formatNumber(total)}</TableCell></TableRow>)
    // table.push(<TableRow><TableCell colSpan={3}>Tổng số tiền có thể thanh toán: {this.formatNumber(this.state.totalCanPayAmount)}</TableCell></TableRow>)
    return table;
  }

  formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
  }

  handleSaveClick = () => {
    const {submit} = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('payment-quick-create');
  };

  handleSubmit = () => {
    let numOfMonth = this.state.numOfMonth;
    if (numOfMonth <= 0 || this.state.availableBills.length < numOfMonth) {
      showErrorNotify('Vui lòng kiểm tra lại số tháng thanh toán.');
      return;
    }
    if (!this.state.totalPayment || this.state.totalPayment <= 0) {
      showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
      return;
    }
    if (this.state.totalPayment > this.state.totalCanPayAmount) {
      showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
      return;
    }
    Swal.fire({
      title: "Bạn chắc chắn muốn tạo thanh toán?",
      text: "Sau khi tạo, không thể hoàn trả thanh toán.",
      type: "warning",
      customClass: {
        container: 'popup-z-index',
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      focusConfirm: false,
    })
      .then((result) => {
        if (result.value) {
          const {fetchStart, fetchEnd} = this.props;
          fetchStart();

          this.sendPayment()
            .then(({data}) => {
              if (data == null || !data.paymentId) {
                showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
              } else {
                showSuccessNotify('Thanh toán học phí thành công!')
                this.handleCloseClick();
                this.props.refreshView();
                if (this.state.enableElectricBill) {
                  setTimeout(() => {
                    if (this.state.enableShowPrint) {
                      printContent(this.getPrintElectronicBillUrl(data.paymentId))
                    } else {
                      const url = `/api/partnerPaymentViews/print-both-receipt?` +
                          `paymentId=${data.paymentId}`;
                      axiosInstance.get(url)
                    }
                  }, 100)
                }
              }
            })
            .catch(error => {
              console.log(error)
              showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
            })
            .finally(() => {
              fetchEnd();
            });
        }
      });
  };


  pdfElectronicBill(paymentId) {
    downloadFile(this.getPrintElectronicBillUrl(paymentId), 'hoa_don.pdf')
  };


  getPrintElectronicBillUrl(paymentId) {
    return `${env.baseURL}/api/partnerPaymentViews/print-both-receipt?` +
        `paymentId=${paymentId}}`;
  };

  pdfPhieuThu(paymentId) {
    downloadFile(this.getPhieuThuUrl(paymentId), 'phieu_thu.xls')
  };


  getPhieuThuUrl(paymentId) {
    return `${env.baseURL}/api/partnerPaymentViews/print-phieuThu?` +
      `paymentId=` + paymentId;
  };

  validate = (values) => {
    const errors = {};
    // Validate
    if (!values.customerId) {
      errors.customerId = 'Vui lòng nhập dữ liệu'
    }
    if (!values.payerName) {
      errors.payerName = 'Vui lòng nhập dữ liệu'
    }
    // if (!values.payerAddress) {
    //   errors.payerAddress = 'Vui lòng nhập dữ liệu'
    // }
    // Copy input values to params
    this.params = values;

    return errors;
  };

  sendPayment() {
    const url = `${env.baseURL}/api/partnerBillViews/paymentManual`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    const values = {};
    values['tenantId'] = this.state.tenantId;
    values['customerId'] = this.params.customerId;
    values['paymentAmount'] = this.params.totalPayment;
    values['payerName'] = this.params.relationshipName;
    values['payerAddress'] = this.params.relationshipAddress;
    values['paymentMethod'] = this.params.paymentMethod;
    return post(url, values, config);
  }

  changePaymentAmount(e) {
    this.params.totalPayment = e.floatValue
    this.setState({totalPayment: e.floatValue})
  }

  changeNumOfMonth(numOfMonth) {
    if (!this.state.availableBills || this.state.availableBills.length === 0) {
      return;
    }
    if (numOfMonth > this.state.availableBills.length) {
      showErrorNotify('Vui lòng kiểm tra lại số tháng thanh toán.');
      return;
    }
    let paymentAmount = 0;
    for (let i = 0; i < numOfMonth; i++) {
      const bill = this.state.availableBills[i];
      paymentAmount += bill.billBalance;
    }

    this.params.totalPayment = paymentAmount;
    this.setState({totalPayment: paymentAmount})
    this.forceUpdate();
  }

  render() {
    const {isSubmitting, classes} = this.props;
    const {showDialog} = this.state;

    return (
      <Fragment>
        {/*buton in row*/}
        {this.state.disableInput && <Button onClick={this.handleClick} label="ra.action.pay">
          <PaymentIcon/>
        </Button>}
        {/*buton on top list*/}
        {!this.state.disableInput &&
        <Button variant="contained" color="primary" onClick={this.handleClick} label="ra.action.pay">
          <PaymentIcon/>
        </Button>}


        <Dialog
          className={classes.suggestDialog}
          fullWidth={true}
          disableBackdropClick={true}
          maxWidth='md'
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Thanh toán"
        >
          <DialogTitle>Thanh toán học phí</DialogTitle>
          <DialogContent>
            <SimpleForm
              record={this.params}
              form="payment-quick-create"
              onSubmit={this.handleSubmit}
              toolbar={null}
              validate={this.validate}
            >
              <Grid container spacing={0} className={classes.width100}>
                <Grid item xs={12} sm={5}>
                  {this.state.enableSelectTenant ?
                    <ReferenceInput label="resources.partnerPaymentViews.fields.tenantId"
                                    source="tenantId" reference="partnerSchools"
                                    onChange={(event, customerId) => this.changeTenant(event, customerId)}>
                      <AutocompleteInput optionText="name"/>
                    </ReferenceInput> :
                    <TextInput source="tenantName" disabled={true}
                               label="resources.partnerPaymentViews.fields.tenantId" />
                  }

                  {this.state.disableInput ?
                    <TextInput source="customerId" disabled={true}
                               label="resources.partnerPaymentViews.fields.customerId" validate={required()}/> :
                    <ReferenceInput label="resources.partnerPaymentViews.fields.customerId"
                                    filter={{tenantId: this.params.tenantId}}
                                    source="customerId" reference="partnerCustomers"
                                    onChange={(event, customerId) => this.changePaymentInfo(event, customerId)}>
                      <AutocompleteInput optionText="id"/>
                    </ReferenceInput>
                  }
                  {this.state.disableInput ?
                    <TextInput source="customerName" disabled={true}
                               label="resources.partnerPaymentViews.fields.customerName" validate={required()}/> :
                    <ReferenceInput label="resources.partnerPaymentViews.fields.customerName"
                                    filter={{tenantId: this.params.tenantId}}
                                    source="customerId" reference="partnerCustomers"
                                    onChange={(event, customerId) => this.changePaymentInfo(event, customerId)}>
                      <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                  }

                  <TextInput source="relationshipName" disabled={true}
                             label="resources.partnerPaymentViews.fields.payerName" validate={required()}/>

                  <TextInput source="relationshipAddress"
                              label="resources.partnerPaymentViews.fields.payerAddress" />

                </Grid>
                <Grid item xs={12} sm={1}>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderTable()}

                  {this.state.bills && this.state.bills.length > 0 ?
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="numOfMonth" onChange={(e, v) => this.changeNumOfMonth(v)}
                                   label="resources.partnerPaymentViews.fields.numOfMonth"
                                   choices={this.state.monthChoices}/>
                    </Grid> : ''
                  }
                  {this.state.bills && this.state.bills.length > 0 ?
                    <Grid item xs={12} sm={6}>
                      <PaymentMethodInput source="paymentMethod" />
                    </Grid> : ''
                  }
                  {this.state.bills && this.state.bills.length > 0 ?
                    <NumberFormat thousandSeparator={true} allowNegative={false}
                                  customInput={TextField}
                                  value={this.state.totalPayment}
                                  label="Số tiền thanh toán"
                                  required={true} disabled={true}/> : ''
                  }
                </Grid>

              </Grid>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="ra.action.pay"
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('payment-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
  PartnerPostQuickPayBillButton
);

import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import UserFields from './UserFields'


const defaultValue = {
    userType: 'INTERNAL',
    enabled: true
}

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list" defaultValue={defaultValue}>
            <Grid container spacing={8}>
                <UserFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default UserCreate

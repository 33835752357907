import ArchiveMessageLogIcon from '@material-ui/icons/ViewList';

import SmsMessageLogList from './SmsMessageLogList';
import SmsMessageLogEdit from './SmsMessageLogEdit';

export default {
  list: SmsMessageLogList,
  edit: SmsMessageLogEdit,
  icon: ArchiveMessageLogIcon,
};

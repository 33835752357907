import ClazzIcon from '@material-ui/icons/ViewList';

import ClazzList from './ClazzList';
import ClazzCreate from './ClazzCreate';
import ClazzEdit from './ClazzEdit';

export default {
  list: ClazzList,
  create: ClazzCreate,
  edit: ClazzEdit,
  icon: ClazzIcon,
};

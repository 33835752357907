import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const DiligenceRangeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const DiligenceRangeList = props => (
    <List filters={<DiligenceRangeFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="fromPoint"/>
            <TextField source="toPoint"/>
        </Datagrid>
    </List>
);

export default DiligenceRangeList

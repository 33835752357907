import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { Grid } from '../../components'
import DiligenceRangeFields from './DiligenceRangeFields'
import SimpleToolbar from "../../components/SimpleToolbar";

export const DiligenceRangeCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<SimpleToolbar {...props} />} redirect="list" >
      <Grid container spacing={8}>
        <DiligenceRangeFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default DiligenceRangeCreate

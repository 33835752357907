import StudentEnrollmentIcon from '@material-ui/icons/ViewList';

import StudentEnrollmentList from './StudentEnrollmentList';
import StudentEnrollmentCreate from './StudentEnrollmentCreate';
import StudentEnrollmentEdit from './StudentEnrollmentEdit';

export default {
  list: StudentEnrollmentList,
  create: StudentEnrollmentCreate,
  edit: StudentEnrollmentEdit,
  icon: StudentEnrollmentIcon,
};

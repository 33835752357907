import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  SingleFieldList,
  TextField,
  TextInput,
  ReferenceArrayField
} from 'react-admin';

const ClazzSubjectFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const ClazzSubjectList = props => (
  <List filters={<ClazzSubjectFilter />} {...props}>
    <Datagrid rowClick="edit" >
      <TextField source="unitName" sortBy="unit_id" />
      <ReferenceArrayField reference="subjects" source="subjectIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
</List>
);

export default ClazzSubjectList

import DiligenceRangeIcon from '@material-ui/icons/ViewList';

import DiligenceRangeList from './DiligenceRangeList';
import DiligenceRangeCreate from './DiligenceRangeCreate';
import DiligenceRangeEdit from './DiligenceRangeEdit';

export default {
  list: DiligenceRangeList,
  create: DiligenceRangeCreate,
  edit: DiligenceRangeEdit,
  icon: DiligenceRangeIcon,
};

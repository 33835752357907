import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, ReferenceInput, required, Title} from 'react-admin';
import {NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {downloadFilePost} from "../../components/FileDownload";

class ContributionBillBatchTemplate extends React.Component {
    state = {
        changeSchoolYear: false,
        unitIds: [],
        productIds: [],
        schoolYearId: schoolYears[0].id,
    };

    getReportUrl = (fullDay, allStudent) => {
        const data = {
            fullDayOnly: fullDay,
            allStudent: allStudent,
            schoolYearId: this.state.schoolYearId,
            productIds: this.state.productIds,
            unitIds: this.state.unitIds
        }
        downloadFilePost(`${env.baseURL}/reports/contributionBillBatchTemplate`, 'ke_hoach_dong_gop.xlsx', data)
    }

    billBatchTemplateAllClazzes = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        if (!this.state.productIds || this.state.productIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách đóng góp")
            return;
        }
        this.getReportUrl(false, true)
    }

    fullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        if (!this.state.productIds || this.state.productIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách đóng góp")
            return;
        }
        this.getReportUrl(true, false)
    }

    notFullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        if (!this.state.productIds || this.state.productIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách đóng góp")
            return;
        }
        this.getReportUrl(false, false)
    }

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        if (this.state.changeSchoolYear) {
            this.setState({
                unitIds: [],
                productIds: [],
                changeSchoolYear: false
            })
        } else {
            this.setState({
                unitIds: values.unitIds,
                productIds: values.productIds
            })
        }

        return errors;
    }

    onSchoolYearChanged(e, value) {
        this.setState({
            schoolYearId: value,
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.contributionBillBatchTemplate.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                allClazzes={this.billBatchTemplateAllClazzes}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="schoolYearId"
                                                reference="schoolYears"
                                                label="resources.schoolYears.name"
                                                perPage={50}
                                                validate={required()}
                                                onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                                {...this.props} >
                                    <AutocompleteInput source="name"/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <label>Danh sách đóng góp *</label>
                                <ReferenceInput source="productIds"
                                                reference="contributionProducts"
                                                perPage={999}
                                                validate={required()}
                                                filter={{filterPath: 'findByEnableTrueOrderByPriorityDesc'}}
                                                {...this.props} >
                                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                </ReferenceInput>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '400px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{
                                                        clazzWithParents: true,
                                                        schoolYear: this.state.schoolYearId
                                                    }}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}
                                                           schoolYear={this.state.schoolYearId}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default ContributionBillBatchTemplate


import React from 'react';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {AutocompleteInput, DateInput, ReferenceArrayInput, ReferenceInput, required, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class SoDiemDanh extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unitIds: [],
            isLoading: false,
            fromDate: moment().format('YYYY-MM-DD'),
        };
    }

    excelReport = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách phòng ban")
            return;
        }
        this.setState({
            isLoading: true
        });
        downloadFile(this.getReportUrl(), 'so_diem_danh.xlsx')
            .then(() => {
                this.setState({
                    isLoading: false
                });
            }).catch(() => {
            this.setState({
                isLoading: false
            });
        })
    }

    getReportUrl = () => {
        return `${env.baseURL}/reports/soDiemDanh?` +
            `unitIds=` + this.state.unitIds +
            `&eventId=` + this.state.eventId +
            `&fromDate=` + this.state.fromDate;
    }

    validate = (values) => {
        const errors = {};
        values.eventId = this.state.eventId;
        // Copy input values to params
        this.setState({
            unitIds: values.unitIds,
            fromDate: values.fromDate,
        });
        return errors;
    }


    onEventChanged(e, value) {
        this.setState({
            eventId: value
        })
    }

    render() {
        return (
            <Card>
                <Title title="resources.soDiemDanh.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Lớp *</label>
                                <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                     filter={{schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''}}
                                                     perPage={999} {...this.props} >
                                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                </ReferenceArrayInput>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="eventId"
                                            reference="events"
                                            label="resources.soDiemDanh.eventId"
                                            perPage={50}
                                            filter={{filterPath: 'filterSimple'}}
                                            validate={required()}
                                            onChange={(e, value) => this.onEventChanged(e, value)}
                                            sort={{field: 'name', order: 'ASC'}}>
                                <AutocompleteInput source="name"/>
                            </ReferenceInput>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default SoDiemDanh;

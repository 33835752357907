import React from 'react';
import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

import {schoolYears} from '../../components';

const ClazzFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const TeacherClazzList = ({...props}) => (
        <List filters={<ClazzFilter/>} filterDefaultValues={{schoolYear: schoolYears[0].id}} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <ReferenceField source="unitId" reference="clazzes" allowEmpty>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="customerId" reference="customers" allowEmpty>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="subject"/>
            </Datagrid>
        </List>
    )
;

export default TeacherClazzList;

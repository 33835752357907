import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import GoogleCalendarFields from './GoogleCalendarFields'

const defaultValue = {
    type: 'SCHEDULE'
}

export const GoogleCalendarCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" defaultValue={defaultValue} >
      <Grid container spacing={8}>
        <GoogleCalendarFields  />
      </Grid>
    </SimpleForm>
  </Create>
);

export default GoogleCalendarCreate

import React from 'react';
import { List, Datagrid, Filter, ReferenceField, TextField, TextInput,SelectField } from 'react-admin';

const ExemptionPolicyDetailFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const ExemptionPolicyDetailList = props => (
  <List filters={<ExemptionPolicyDetailFilter />} {...props}  exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <ReferenceField source="exemptionPolicyId" reference="exemptionPolicies" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="productId" reference="billProducts" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="value" />
      <SelectField source="reduceType" choices={[
        {
          id: 'PERCENT',
          name: 'Phần trăm'
        }, {
          id: 'DIRECT',
          name: 'Trực tiếp'
        }]} />
    </Datagrid>
</List>
);

export default ExemptionPolicyDetailList

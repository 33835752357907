import React from 'react';

import moment from 'moment'

import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';

import {
    AutocompleteInput,
    DateInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    Title,
    translate
} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {compose} from 'redux';
import cachedApis from "../../api/cachedApis";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";

const BangGioDiemDanh = ({...props}) => {

    const [values, setValues] = React.useState({
        enrollmentId: '',
        customers: [],
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    });

    const [loadingCustomers, setLoadingCustomers] = React.useState(true);

    const getReportUrl = () => {
        return `${env.baseURL}/reports/bangGioDiemDanh?fromDate=` + values.fromDate +
            `&toDate=` + values.toDate +
            `&enrollmentId=` + values.enrollmentId;
    }
    function getStudentsByUnit() {
        cachedApis.getEmployeeUnits('e').then(rs => {
            setValues({...values, customers: rs});
            setLoadingCustomers(false);
        })
    }
    const excelReport = () => {
        downloadFile(getReportUrl(), 'bang_gio_diem_danh.xlsx')
    };

    const validate = (val) => {
        const errors = {};

        if (val.fromDate && val.toDate && (val.fromDate > val.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        setValues({
            enrollmentId: val.enrollmentId,
            fromDate: val.fromDate,
            toDate: val.toDate
        })

        return errors;
    };

    function onCustomerSelected(value) {
        const customerIdIds = [];
        const unitIds = [];
        value.forEach(i => {
            const temp = "" + i;
            let zeroIdx = temp.indexOf("0000");
            let lastZeroIdx = -1;
            while (zeroIdx >= 0) {
                lastZeroIdx = zeroIdx;
                zeroIdx = temp.indexOf("0000", zeroIdx + 1);
            }
            if (lastZeroIdx !== -1 && lastZeroIdx < temp.length - 1) {
                let stdId = Number(temp.substr(lastZeroIdx + 4));
                if (!customerIdIds.includes(stdId)) {
                    customerIdIds.push(stdId);
                }
                let unitId = Number(temp.substr(0, lastZeroIdx));
                if (!unitIds.includes(unitId)) {
                    unitIds.push(unitId);
                }
            }
        });
        setValues(
            {...values, customerIds: customerIdIds, unitIds: unitIds});
    }
    return (
        <Card>
            <Title title="resources.bangGioDiemDanh.name"/>
            <CardContent>
                <ReportForm params={values}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="enrollmentId" reference="employeeEnrollments" label="resources.bangGioDiemDanh.enrollmentId"  {...props} >
                                <AutocompleteInput optionText="customerName"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    translate
)(BangGioDiemDanh)


import React, {useEffect, useState} from 'react';
import {Title} from "react-admin";
import {Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Button} from '@material-ui/core';
import axiosInstance from "../../api/axios";
import {DateTimeField, downloadFile} from "../../components";
import env from "../../env";


const ProfileChangeLogCartHistory = props => {

    const [items, setItems] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const res = await axiosInstance.get('/api/profileChangeLogCartHistories?sort=id%2CDESC')
        if(res.status === 200 && res.data.page.totalElements > 0) {
            setItems(res.data.content)
        }
    }

    const onDownload = async (id) => {
        downloadFile(`${env.baseURL}/api/profileChangeLogCartHistory/export/${id}`, 'lich_su_yeu_cau_cap_the.xlxs')
    }

    return <Card>
        <Title title="Lịch sử yêu cầu cấp thẻ"/>
        <CardContent>
            <Table>
                <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                    <TableRow>
                        <TableCell width={50}>STT</TableCell>
                        <TableCell>Người yêu cầu</TableCell>
                        <TableCell>Số lượng thẻ</TableCell>
                        <TableCell>Thời gian gởi yêu cầu</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell width={50}>{idx + 1}</TableCell>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell width={100}>{row.cardApplicationIds?.split(",").length}</TableCell>
                            <TableCell>
                                <DateTimeField record={{...row}} source='createdOn' format='DD/MM/YYYY HH:mm:ss'/>
                            </TableCell>
                            <TableCell>
                                <Button style={{maxWidth: 130}} color='primary'
                                        onClick={() => onDownload(row.id)}>
                                    TẢI FILE
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </CardContent>
    </Card>
};

export default ProfileChangeLogCartHistory;

import React from 'react';
import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import {HocKiField} from "../../components";

const BlockFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const CongBoDiemList = props => (
    <List filters={<BlockFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <HocKiField source="hocKy"/>
            <ReferenceField source="unitId" reference="baseUnits">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="schoolYearId" reference="schoolYears">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="lastUpdatedBy"/>
        </Datagrid>
    </List>
);

export default CongBoDiemList

import ProductIcon from '@material-ui/icons/ViewList';

import ContributionBillList from './ContributionBillList';
import ContributionBillCreate from './ContributionBillCreate';
import ContributionBillEdit from './ContributionBillEdit';

export default {
  list: ContributionBillList,
  create: ContributionBillCreate,
  edit: ContributionBillEdit,
  icon: ProductIcon,
};

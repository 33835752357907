import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, Title, required, TextInput, ReferenceInput} from 'react-admin';
import {downloadFile, PaymentMethodInput, ReportForm, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

class ListPaidByProduct extends React.Component {
  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    pAccountNumber: '',
    pUnitNumber: '',
    pDistrict: '',
    pProductIds: [],
    paymentMethod: ''
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/listPaidByProducts?` +
      `pFromDate=` + this.state.fromDate +
      `&pToDate=` + this.state.toDate +
      `&pAccountNumber=` + this.state.pAccountNumber +
      `&pUnitNumber=` + this.state.pUnitNumber +
      `&pDistrict=` + this.state.pDistrict +
      `&pProductIds=` + this.state.pProductIds +
      `&paymentMethod=` + this.state.paymentMethod;
  }

  excelReport = () => {
    if (!this.state.pProductIds || this.state.pProductIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phí")
      return;
    }
    downloadFile(this.getReportUrl(), 'bang_ke_thu_theo_danh_muc.xlsx')
  }

  validate = (values) => {
    const errors = {};

    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }

    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      pUnitNumber: values.pUnitNumber,
      pDistrict: values.pDistrict,
      pAccountNumber: values.pAccountNumber,
      pProductIds: values.productIds,
      paymentMethod: values.paymentMethod,
    })

    return errors;
  }


  render() {
    return (
      <Card>
        <Title title="resources.listPaidByProducts.name"/>
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
            <Grid container spacing={8}>
              Báo cáo tính theo sô tiền thu được trong khoảng thời gian truy xuất dữ liệu
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaymentMethodInput source="paymentMethod" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput label="resources.listPaidByProducts.unitNumber" source="pUnitNumber"
                           validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput label="resources.listPaidByProducts.accountNumber" source="pAccountNumber"
                           validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput label="resources.listPaidByProducts.district" source="pDistrict"
                           validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Danh sách phí *</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceInput source="productIds"
                                  reference="billProducts"
                                  perPage={999}
                                  {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true} />
                  </ReferenceInput>
                </div>
              </Grid>
            </Grid>
          </ReportForm>

        </CardContent>
      </Card>
    )
  }
}

export default ListPaidByProduct


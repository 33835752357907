import React, {Component} from 'react';
import {Edit, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {CalendarUpload, CustomToolbar, Grid} from '../../components'
import {CalendarTypeInput} from "../../components/CalendarType";


export class GoogleCalendarEdit extends Component {

    render() {
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="name" validate={required()} {...this.props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CalendarTypeInput source="type" validate={required()} {...this.props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()}
                                            allowEmpty {...this.props} >
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CalendarUpload file="this.props.file"/>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default GoogleCalendarEdit

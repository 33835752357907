import React from 'react';

import { DeleteButton } from 'react-admin';

class DeleteButtonWithAuthStatus extends React.Component {

  state = {
    showBtn: false
  };

  constructor(props) {
    super(props);
    if (props.record) {
      this.state.showBtn = props.record.authStatus === 'DRAFT' || props.record.authStatus === 'UNAUTHORIZED';
    }
  }

  render() {
    const { ...props} = this.props;
    return (
      <div>
        {this.state.showBtn ?
         <DeleteButton {...props} />
         : <div></div>
        }

      </div>
  )}
}

export default DeleteButtonWithAuthStatus

import AttendanceResultAuthorizationIcon from '@material-ui/icons/ViewList';

import AttendanceResultAuthorizationList from './AttendanceResultAuthorizationList';
import AttendanceResultAuthorizationEdit from './AttendanceResultAuthorizationEdit';

export default {
  list: AttendanceResultAuthorizationList,
  edit: AttendanceResultAuthorizationEdit,
  icon: AttendanceResultAuthorizationIcon,
};

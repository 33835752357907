import AccessDeviceIcon from '@material-ui/icons/ViewList';

import CustomerHealthBatchList from './CustomerHealthBatchList';
import IntroductionFileCreate from './CustomerHealthBatchCreate';
import IntroductionFileEdit from './CustomerHealthBatchEdit';

export default {
  list: CustomerHealthBatchList,
  create: IntroductionFileCreate,
  edit: IntroductionFileEdit,
  icon: AccessDeviceIcon,
};

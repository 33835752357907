import Swal from 'sweetalert2'
import './popup.css';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 7000,
});


export const closeNotify = () => {
  Toast.close();
}

export const showSuccessNotify = (msg) => {
  Toast.fire({
    icon: 'success',
    title: msg,
    customClass: {
      container: 'popup-z-index',
      popup: 'success-bg',
      title: 'popup-text'
    }
  })
};

export const showInfoNotify = (msg) => {
  Toast.fire({
    icon: 'info',
    title: msg,
    customClass: {
      container: 'popup-z-index',
      popup: 'info-bg',
      title: 'popup-text'
    }
  })
};

export const showErrorNotify = (msg) => {
  Toast.fire({
    icon: 'error',
    title: msg,
    customClass: {
      container: 'popup-z-index',
      popup: 'error-bg',
      title: 'popup-text'
    }
  })
};


export const showWarningNotify = (msg) => {
  Toast.fire({
    icon: 'warning',
    title: msg,
    customClass: {
      container: 'popup-z-index',
      popup: 'warning-bg',
      title: 'popup-text'
    }
  })
};

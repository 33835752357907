import React from 'react';
import {List, Datagrid, Filter, BooleanField, TextField, TextInput} from 'react-admin';
import ViewFile from "./ViewFile";

const IntroductionFileFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const IntroductionFileList = props => (
    <List filters={<IntroductionFileFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="description"/>
            <BooleanField source="enable"/>
            <TextField source="ver"/>
            <TextField source="priority"/>
            <ViewFile/>
        </Datagrid>
    </List>
);

export default IntroductionFileList

import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const statues = [
  {id: 'DRAFT', name: 'Chưa thanh toán'},
  {id: 'PAID', name: 'Đã thanh toán'},
  {id: 'DELETED', name: 'Đã huỷ'}
]

export const ContributionBillField = (props) => (
  <SelectField source={props.source} choices={statues} {...props} />
);

export const ContributionBillInput = (props) => (
  <SelectInput source={props.source} choices={statues} {...props}  label="resources.paymentMethod.name"/>
);

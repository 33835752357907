import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import Grid from '@material-ui/core/Grid';
import {ReferenceInput, required, SelectInput} from 'react-admin';
import env from "../../env";

class MissingVotingReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pollId: ''
    };
    this.getReportUrl = this.getReportUrl.bind(this);
    this.validate = this.validate.bind(this);
  }

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/missing-voting-report?` +
        `pollId=` + this.state.pollId;
  }

  validate = (values) => {
    const errors = {};

    // Copy input values to params
    this.setState({
      pollId: values.pollId
    })

    return errors;
  }

  render() {
    return (
        <React.Fragment>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <ReferenceInput source="pollId" reference="polls" validate={required()}>
                  <SelectInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6} >
              </Grid>
            </Grid>
          </ReportForm>
        </React.Fragment>
    )
  }
}

export default MissingVotingReport;

import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";

class ThongKeSmsTheoNguoi extends React.Component {
    state = {
        isLoading: false,
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        params: {
            unitIds: []
        }
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/thongKeSmsTheoNguoi?` +
            `fromDate=` + this.state.params.fromDate +
            `&toDate=` + this.state.params.toDate +
            `&unitIds=` + this.state.params.unitIds;
    };


    notifyAllStudents = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp/phòng ban")
            return;
        }

        downloadFile(this.getReportUrl(), 'thong_ke_gui_tin_theo_nguoi.xlsx')
    };


    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.thongKeSmsTheoNguoi.name"/>
                <CardContent>
                    <ReportForm validate={this.validate}
                                excelReport={this.notifyAllStudents}
                                isLoading={this.state.isLoading}
                                params={this.state}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>

                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp/phòng ban *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default ThongKeSmsTheoNguoi


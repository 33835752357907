import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import TenantBankAccountFields from './TenantBankAccountFields'

export const TenantBankAccountCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />} redirect="list" >
      <Grid container spacing={8}>
        <TenantBankAccountFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default TenantBankAccountCreate

import StudentsByUnitsIcon from '@material-ui/icons/ViewList';

import StudentsByUnitsList from './StudentsByUnitsList';
import StudentsByUnitsCreate from './StudentsByUnitsCreate';
import StudentsByUnitsEdit from './StudentsByUnitsEdit';

export default {
  list: StudentsByUnitsList,
  create: StudentsByUnitsCreate,
  edit: StudentsByUnitsEdit,
  icon: StudentsByUnitsIcon,
};

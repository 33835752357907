import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {EFoodMenuField} from "../../components/EFoodMenu";
import {DateTimeField} from "../../components";

const AuthorizedAttendanceHistoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Tìm tên" source="q" alwaysOn/>
    </Filter>
);

export const MealRegistrationChangedHistoryList = props => {
    return (
        <List filters={<AuthorizedAttendanceHistoryFilter date={new Date()}/>}
              bulkActions={false}
              exporter={false}
              perPage={25}
              {...props}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="customerName" style={{width: 150}}/>
                <TextField source="baseUnitName" style={{width: 80}}/>
                <TextField source="unitName" style={{width: 80}}/>
                <DateTimeField source="changedDateTime" format="kk:mm DD-MM-YYYY" style={{width: 120}}/>
                <DateTimeField source="changedDateTime" format="kk:mm DD-MM-YYYY" style={{width: 120}} />
                <EFoodMenuField source="oldFoodMenu" style={{width: 80}}/>
                <EFoodMenuField source="newFoodMenu" style={{width: 80}}/>
                <TextField source="authorizerUserName" style={{width: 150}}/>
                <TextField source="reason"/>
            </Datagrid>
        </List>
    )
}

export default MealRegistrationChangedHistoryList

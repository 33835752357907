import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import BillProductFields from './BillProductFields'

export const BillProductEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <BillProductFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default BillProductEdit

import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import BillProductFields from './BillProductFields'

const defaultValue = {
    priority: 100
}

export const BillProductCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} defaultValue={defaultValue} redirect="list">
            <Grid container spacing={8}>
                <BillProductFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default BillProductCreate

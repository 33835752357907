import React from 'react';
import Grid from '@material-ui/core/Grid'
import {required, TextInput} from 'react-admin';
import ECardTypeSelectInput from "./ECardTypeSelectInput";

const ECardTemplateFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ECardTypeSelectInput source="type" {...props} />
    </Grid>
  </React.Fragment>
)

export default ECardTemplateFields

import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {ReferenceInput, required, SelectInput, Title} from 'react-admin';
import env from "../../env";

class VnEduStudentReport extends React.Component {

  state = {
    tenantId: ''
  }

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao_ds_hoc_sinh_moi.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/vnEduStudentReport?` +
        `&tenantId=` + this.state.tenantId
  }

  validate = (values) => {
    const errors = {};

    this.setState({
      tenantId: values.tenantId
    })

    return errors;
  }

  onTenantChanged(e, value) {
    this.setState({
      tenantId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="Báo cáo danh sách học sinh từ vn_edu"/>
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput source="tenantId"
                                  validate={required()}
                                  reference="tenants"
                                  perPage={50}
                                  onChange={(e, value) => this.onTenantChanged(e,
                                      value)}
                                  sort={{field: 'name', order: 'ASC'}}>
                    <SelectInput source="name"/>
                  </ReferenceInput>
                </Grid>

              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default VnEduStudentReport;

import UnitProductIcon from '@material-ui/icons/ViewList';

import UnitProductList from './UnitProductList';
import UnitProductCreate from './UnitProductCreate';
import UnitProductEdit from './UnitProductEdit';

export default {
  list: UnitProductList,
  create: UnitProductCreate,
  edit: UnitProductEdit,
  icon: UnitProductIcon,
};

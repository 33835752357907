import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../../components';
import env from '../../../env';

class BaoCaoTamUngNapThe extends React.Component {
  state = {
    shiftDailyId: '',
    counterId: '',
    changeCounter: false,
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  };

  getReportUrl = (type) => {
    const shiftDailyId = this.state.shiftDailyId ? this.state.shiftDailyId : ''
    const counterId = this.state.counterId ? this.state.counterId : ''
    return `${env.baseURL}/reports/baoCaoTamUngNapThe?type=` + type +
        `&shiftDailyId=` + shiftDailyId +
        `&counterId=` + counterId +
        `&pFromDate=` + this.state.fromDate +
        `&pToDate=` + this.state.toDate;
  }

  excelReport = () => {
    downloadFile(this.getReportUrl('XLS'), 'bao_cao_tam_ung_nap_the.xlsx')
  };

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'To date must be greater than From date'
    }
    // Copy input values to params
    if (this.state.changeCounter) {
      this.setState({
        fromDate: values.fromDate,
        toDate: values.toDate,
        counterId: values.counterId,
        shiftDailyId: null,
        changeCounter: false
      })
    } else {
      this.setState({
        fromDate: values.fromDate,
        toDate: values.toDate,
        counterId: values.counterId,
        shiftDailyId: values.shiftDailyId
      })
    }
    return errors;
  };

  onCounterChange(e, value) {
    this.setState({
      counterId: value,
      changeCounter: true
    });
  }

  render() {
    return (
      <Card>
        <Title title="resources.baoCaoTamUngNapThe.name" />
        <CardContent>
          <ReportForm params={this.state}
            validate={this.validate}
            excelReport={this.excelReport}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()}/>
              </Grid>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.toDate" source="toDate" />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <ReferenceInput label="resources.externalClientCounters.name"
                                source="counterId" reference="externalClientCounters"
                                onChange={(e, value) => this.onCounterChange(e, value)}
                                alwaysOn allowEmpty {...this.props} >
                  <AutocompleteInput optionText="name"/>
                </ReferenceInput>
              </Grid>
              {this.state.counterId &&
              <Grid item xs={12} sm={6}>
                <ReferenceInput label="resources.shiftDailies.name"
                                source="shiftDailyId" reference="shiftDailies"
                                filter={{q: this.state.fromDate, counterId: this.state.counterId}}
                                sort={{field: 'openOn', order: 'ASC'}}
                                alwaysOn allowEmpty {...this.props} >
                  <AutocompleteInput optionText="description"/>
                </ReferenceInput>
              </Grid>
              }
            </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default BaoCaoTamUngNapThe;


import React from 'react';
import {Datagrid, List, TextField} from 'react-admin';


export const HistoryFaceCheckinList = props => {

    return <List {...props}
                 bulkActionButtons={false}
                 exporter={false}
    >
        <Datagrid>
            <TextField source="faceId" label='ID'/>
            <TextField source="machineBoxId"/>
            <TextField source='checkinAt'/>
        </Datagrid>
    </List>
};

export default HistoryFaceCheckinList

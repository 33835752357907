import React from 'react';

import moment from 'moment'

import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';

import {
    AutocompleteInput,
    DateInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    Title,
    translate
} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {compose} from 'redux';
import cachedApis from "../../api/cachedApis";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";

const TongHopDiemDanhHocPhan = ({...props}) => {

    const [values, setValues] = React.useState({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    });


    const getReportUrl = () => {
        return `${env.baseURL}/reports/tongHopDiemDanhHocPhan?fromDate=` + values.fromDate +
            `&toDate=` + values.toDate;
    }
    const excelReport = () => {
        downloadFile(getReportUrl(), 'tong_hop_diem_danh_hoc_phan.xlsx')
    };

    const validate = (val) => {
        const errors = {};

        if (val.fromDate && val.toDate && (val.fromDate > val.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        setValues({
            fromDate: val.fromDate,
            toDate: val.toDate
        })

        return errors;
    };

    return (
        <Card>
            <Title title="resources.tongHopDiemDanhHocPhan.name"/>
            <CardContent>
                <ReportForm params={values}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    translate
)(TongHopDiemDanhHocPhan)


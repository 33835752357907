import React from 'react';
import compose from 'recompose/compose';

import {SaveButton, Toolbar, translate} from 'react-admin';
import {Button} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const AttendanceResultCustomToolbar = ({
  viewOnly,
  noCancelButton,
  basePath,
  record,
  resource,
  undoable,
  translate, 
  redirect,
  permissions,
  ...props
}) => (
  <Toolbar record={record} {...props}>
    <Button disabled={!props.editable} variant="contained"
            onClick={props.authorize}
            component="span" color={"primary"}>
      <SaveIcon/> Duyệt
    </Button>
    <Button disabled={!props.editable} variant="contained"
            component="span" color={"primary"}
            onClick={props.preAuthorize}
            style={{marginLeft: 10}}>
      <SaveIcon/> Cập nhật
    </Button>
    <Button variant="contained" component="span"
            style={{marginLeft: 10}} onClick={props.cancel}>
      <CancelIcon/> Hủy
    </Button>
  </Toolbar>
);

export default compose(
  translate,
)(AttendanceResultCustomToolbar);

import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from 'moment';

class PaidFullDayStudentsReport extends React.Component {
    state = {
        billMonth: moment().format('YYYY-MM-DD')
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/payments/paidFullDayStudentsReport` +
            `?billMonth=${this.state.billMonth}`;
    }

    excelReport = () => {
        downloadFile(this.getReportUrl(), 'bao_cao_HS_ban_tru_da_dong_tien.xlsx')
    }

    validate = (values) => {
        const errors = {};
        if (!values.billMonth) {
            errors.billMonth = 'Vui lòng chọn tháng'
        }
        this.setState(values)

        return errors;
    }


    render() {
        return (
            <Card>
                <Title title="resources.paidFullDayStudentsReport.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.paidFullDayStudentsReport.billMonth"
                                           source="billMonth" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default PaidFullDayStudentsReport


import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import AttendanceDetailsChangedHistoryFields from './AttendanceDetailsChangedHistoryFields'

export const AttendanceDetailsChangedHistoryEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />}>
      <Grid container spacing={8}>
        <AttendanceDetailsChangedHistoryFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default AttendanceDetailsChangedHistoryEdit

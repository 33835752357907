import React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput
} from 'react-admin';

const TenantFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const TenantList = props => (
  <List filters={<TenantFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <BooleanField source="enableEdit" />
      <BooleanField source="enablePayment" />
      <BooleanField source="showAdBanner" />
      <BooleanField source="requirePhoneForCustomer" />
    </Datagrid>
</List>
);

export default TenantList

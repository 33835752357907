import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import AccessTerminalFields from './AccessTerminalFields'

export const AccessTerminalEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <AccessTerminalFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default AccessTerminalEdit

import React, {Component} from 'react';
import {Responsive, showNotification, Title, withDataProvider} from 'react-admin';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import SmsUsage from "./SmsUsage";
import {Grid} from '@material-ui/core';
import SendReview from "./SendReview";

class SmsDashboard extends Component {

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
        }
    }

    showError = (msg) => {
        this.props.showNotification(msg, 'warning');
    }


    render() {
        return (
            <Responsive
                xsmall={
                    <div>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12}>
                                <SmsUsage
                                    onError={this.showError}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <SendReview/>
                            </Grid>
                        </Grid>
                    </div>
                }
                small={
                    <div>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12}>
                                <SmsUsage
                                    onError={this.showError}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <SendReview/>
                            </Grid>
                        </Grid>
                    </div>
                }
                medium={
                    <div>
                        <Title title="resources.smsDashboard.name"/>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sm={3}>
                                <SmsUsage
                                    onError={this.showError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <SendReview onError={this.showError}/>
                            </Grid>
                        </Grid>
                    </div>

                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps, {showNotification}),
    withDataProvider
)(SmsDashboard);

import BlockIcon from '@material-ui/icons/ViewList';

import ApartmentList from './ApartmentList';
import ApartmentCreate from './ApartmentCreate';
import ApartmentEdit from './ApartmentEdit';

export default {
  list: ApartmentList,
  create: ApartmentCreate,
  edit: ApartmentEdit,
  icon: BlockIcon,
};

import React from 'react';
import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

const TenantBankAccountFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const TenantBankAccountList = props => (
    <List filters={<TenantBankAccountFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="accountName"/>
            <ReferenceField source="bankId" reference="bankInfoes" allowEmpty>
                <TextField source="displayName"/>
            </ReferenceField>
            <TextField source="branch"/>
            <TextField source="accountNumber"/>
        </Datagrid>
    </List>
);

export default TenantBankAccountList

import AccessDeviceIcon from '@material-ui/icons/ViewList';

import KiotConfigList from './KiotConfigList';
import KiotConfigCreate from './KiotConfigCreate';
import KiotConfigEdit from './KiotConfigEdit';

export default {
  list: KiotConfigList,
  create: KiotConfigCreate,
  edit: KiotConfigEdit,
  icon: AccessDeviceIcon,
};

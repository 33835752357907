import React from 'react';
import {Datagrid, EditButton, Filter, List, refreshView, TextField, TextInput, translate} from 'react-admin';
import {BillAuthStatusField, DateTimeField} from '../../components'
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {SmsMessageCategoryField} from "../../components/SmsMessageCategory";

const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const SmsMessageList = props => (
    <List filters={<MessageFilter/>} {...props} exporter={false} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
            <SmsMessageCategoryField source="category"/>
            <TextField source="freeText"/>
            <BillAuthStatusField source="authStatus"/>
            <DateTimeField source="lastUpdatedOn" format="DD/MM/YYYY HH:mm"/>
            <TextField source="lastUpdatedBy"/>
            <TextField source="result"/>
            <EditButton label={""} variant="outlined" {...props}/>
        </Datagrid>
    </List>
);

export default compose(
    connect(undefined, {refreshView}),
    translate,
    withStyles(null)
)(SmsMessageList)

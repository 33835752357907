import React from 'react';
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { REDUX_FORM_NAME, TextInput } from 'react-admin';


class UidInput extends React.Component {
  componentDidMount(){
    const electron = window.electron || (window.require ? window.require("electron") : null)
    if (electron && electron.ipcRenderer) {
      electron.ipcRenderer.on('uid', this.onUID)
    }    
  }

  componentWillUnmount() {
    const electron = window.electron || (window.require ? window.require("electron") : null)
    if (electron && electron.ipcRenderer) {
      electron.ipcRenderer.removeListener('uid', this.onUID)
    }
  }

  onUID = (event, uid) => {
    const { dispatch, source } = this.props;
    dispatch(change(REDUX_FORM_NAME, source, uid));
  };

  render() {
    const { ...props } = this.props;

    return (
      <TextInput {...props} disabled/>
    )
  }
}

export default connect(null, null)(UidInput)

import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ContributionBillFields from './ContributionBillFields'

export const ContributionBillEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <ContributionBillFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ContributionBillEdit

import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    Title
} from 'react-admin';
import {downloadFile, ReportForm, schoolYears} from '../components';
import env from '../env';

class DiligenceReport extends React.Component {
    state = {
        clazzId: '',
        eventIds: [],
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    };

    getReportUrlToDownload = () => {
        return `${env.baseURL}/reports/diligence-report?` +
            `&unitId=` + this.state.clazzId +
            `&eventIds=` + this.state.eventIds +
            `&fDate=` + this.state.fromDate +
            `&tDate=` + this.state.toDate;
    }

    excelReport = () => {
        downloadFile(this.getReportUrlToDownload(), 'bao_cao_chuyen_can.xlsx')
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
            errors.toDate = 'To date must be greater than From date'
        }
        values.eventIds = this.state.eventIds;

        this.setState({
            clazzId: values.clazzId,
            fromDate: values.fromDate,
            toDate: values.toDate,
        })

        return errors;
    }

    onClazzChanged(e, value) {
        this.setState({
            eventIds: []
        })
    }

    onEventChanged(e, value) {
        this.setState({
            eventIds: value
        })
    }

    render() {
        return (
            <Card>
                <Title title="Báo cáo chuyên cần" />
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6} >
                                <ReferenceInput source="clazzId" reference="clazzes"
                                                validate={required()}
                                                filter={{schoolYear: schoolYears[0].id, eventIncluded: true}}
                                                sort={{ field: 'name', order: 'ASC' }}
                                                onChange={(e, value) => this.onClazzChanged(e, value)}
                                                perPage={100}>
                                    <AutocompleteInput source="name" />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                {this.state.clazzId ?
                                    <ReferenceArrayInput source="eventIds"
                                                         reference="events"
                                                         validate={required()}
                                                         label={'Sự kiện'}
                                                         filter={{unitId: this.state.clazzId, accessControlOnly: false}}
                                                         perPage={999}
                                                         style={{
                                                             minWidth: 200,
                                                         }}
                                                         onChange={(e, value) => this.onEventChanged(e, value)}
                                                         sort={{ field: 'name', order: 'ASC' }}>
                                        <AutocompleteArrayInput optionText="name"
                                                          onChange={(e) => this.props.onChange(e)}/>
                                    </ReferenceArrayInput>
                                    :
                                    <ReferenceArrayInput source="eventIds"
                                                         reference="events"
                                                         validate={required()}
                                                         label={'Sự kiện'}
                                                         filter={{accessControlOnly: false}}
                                                         perPage={999}
                                                         style={{
                                                             minWidth: 200,
                                                         }}
                                                         onChange={(e, value) => this.onEventChanged(e, value)}
                                                         sort={{ field: 'name', order: 'ASC' }}>
                                        <AutocompleteArrayInput optionText="name"
                                                          onChange={(e) => this.props.onChange(e)}/>
                                    </ReferenceArrayInput>
                                }
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <DateInput source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <DateInput source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default DiligenceReport;


import UserIcon from '@material-ui/icons/ViewList';

import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
};

import React from 'react';
import { List, Datagrid, Filter, ReferenceField, TextField, TextInput } from 'react-admin';

const BlockFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const BlockList = props => (
  <List filters={<BlockFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="parentId" reference="companies" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
</List>
);

export default BlockList

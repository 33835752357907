import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import {CustomToolbar, Grid, TreeCheckboxInput} from '../../components'
import {  ReferenceInput,SelectInput, required } from 'react-admin';

export const ClazzSubjectEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <React.Fragment>
          <Grid item xs={12} sm={6} >
            <ReferenceInput source="unitId" reference="units" disabled={true} {...props} >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6} >
            <label>Danh sách môn học *</label>
            <ReferenceInput source="subjectIds"
                            reference="subjects"
                            perPage={999}
                            validate={required()}
                            {...props} >
              <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
            </ReferenceInput>
          </Grid>

        </React.Fragment>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ClazzSubjectEdit

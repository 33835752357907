import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import './bankStatementStyle.css'
import {PrintReceiptButton} from "../../components";

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  icon: {
    fontSize: 18,
  },
};

class BankStatementSaveItemResult extends Component {

  render() {
    return (
      <div style={classes.root}>
        <Paper style={classes.paper}>
          <div className='summaryInfo'><b>{this.props.countSuccess}</b><span> giao dịch lưu và xuất hoá đơn thành công</span></div>
          <div className='summaryInfo'><span className='errorInfo'>{this.props.countError}</span><span> giao dịch xuất hoá đơn thất bại</span></div>
          {this.props.countError > 0 && <Table style={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Mã biên lai</TableCell>
                <TableCell align="right">Học sinh</TableCell>
                <TableCell align="right">Lớp</TableCell>
                <TableCell align="right">Ghi chú</TableCell>
                <TableCell align="right">Tiền thanh toán</TableCell>
                <TableCell align="right">Số HĐĐT</TableCell>
                <TableCell align="right">Ngày HĐĐT</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell align="right">{row.paymentNo}</TableCell>
                  <TableCell align="right">{row.customerName}</TableCell>
                  <TableCell align="right">{row.unitName}</TableCell>
                  <TableCell align="right">{row.note}</TableCell>
                  <TableCell align="right">{row.paymentAmount}</TableCell>
                  <TableCell align="right">{row.electronicBillNum}</TableCell>
                  <TableCell align="right">{row.electronicBillDate}</TableCell>
                  <TableCell align="right"><PrintReceiptButton/></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          }
          {this.props.countError > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={this.props.total}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            backIconButtonprops={{
            'aria-label': 'Previous Page',
          }}
            nextIconButtonprops={{
            'aria-label': 'Next Page',
          }}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          }
        </Paper>
      </div>
    );
  }
}

export default BankStatementSaveItemResult;

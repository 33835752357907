import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {DownloadFileContentButton} from "../../components";
import {ECardTypeSelectField} from "./ECardTypeSelectInput";

const ECardTemplateFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const ECardTemplateList = props => (
    <List filters={<ECardTemplateFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <ECardTypeSelectField source="type"/>
            <DownloadFileContentButton extension={'.png'}/>
        </Datagrid>
    </List>
);

export default ECardTemplateList

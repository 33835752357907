import GradeIcon from '@material-ui/icons/ViewList';

import GradeList from './GradeList';
import GradeCreate from './GradeCreate';
import GradeEdit from './GradeEdit';

export default {
  list: GradeList,
  create: GradeCreate,
  edit: GradeEdit,
  icon: GradeIcon,
};

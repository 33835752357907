import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import CompanyFields from './CompanyFields'
import ImageUploadWrapper from "../../components/ImageUploadWrapper";

export const CompanyEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <CompanyFields />
          <Grid item xs={12} sm={6}>
              <ImageUploadWrapper {...props} url={'/api/photo/unit/'}/>
          </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default CompanyEdit

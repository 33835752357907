import React, {Component} from 'react';
import {Responsive, showNotification, Title, withDataProvider} from 'react-admin';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import TopClazzesReview from "./TopClazzesReview";
import AttendanceReview from "./AttendanceReview";
import {Grid} from '@material-ui/core';

class Dashboard extends Component {
  tenantType;

  componentDidMount() {
    this.tenantType = localStorage.getItem("tenantType");
  }
  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
    }
  }

  showError = (msg) => {
    this.props.showNotification(msg, 'warning');
  }

  render() {
    return (
              <Responsive
                  xsmall={
                    this.tenantType === 'SCHOOL' ?
                    <div>
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} >
                          <TopClazzesReview
                              positive={true}
                              onError={this.showError}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                          <TopClazzesReview
                              positive={false}
                              onError={this.showError}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                          <AttendanceReview />
                        </Grid>
                      </Grid>
                    </div> : <div></div>
                  }
                  small={
                    this.tenantType === 'SCHOOL' ?
                    <Grid container spacing={8}>
                      <Grid item xs={12} sm={12} >
                        <TopClazzesReview
                            positive={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} >
                        <TopClazzesReview
                            positive={false}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} >
                        <AttendanceReview />
                      </Grid>
                    </Grid>
                        : <div></div>
                  }
                  medium={
                    this.tenantType === 'SCHOOL' ?
                    <div>
                      <Title title={localStorage.getItem('tenant')} />
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} >
                          <TopClazzesReview
                              positive={true}
                              onError={this.showError}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} >
                          <TopClazzesReview
                              positive={false}
                              onError={this.showError}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                          <AttendanceReview onError={this.showError}/>
                        </Grid>
                      </Grid>
                    </div>
                    : <div> <Title title={localStorage.getItem('tenant')} />
                    </div>
                  }
              />


    );
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps, { showNotification }),
    withDataProvider
)(Dashboard);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CircularProgress, Grid} from '@material-ui/core'
import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';
import {CustomToolbar, FileUploadComponent, showErrorNotify, showInfoNotify, showSuccessNotify} from "../../components";
import env from "../../env";
import {post} from "axios";
import {Redirect} from 'react-router-dom';
import {CalendarTypeInput} from "../../components/CalendarType";
import Button from '@material-ui/core/Button';

export class CalendarBatchEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            batchId: props.id,
            isLoading: false,
            showUploadFile: true,
            useImage: props.useImage,
            images: []
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (this.state.file == null) {
            return;
        }
        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        this.fileUpload(this.state.file).then((response) => {
            if (response.data.success) {
                this.setState({
                    isLoading: false,
                    isRedirect: true
                });
                showSuccessNotify("Đã xử lý thành công");
            } else {
                this.setState({
                    isLoading: false,
                });
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
            }
        })
            .catch(error => {
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                this.setState({
                    isLoading: false,
                })
            });
    }

    fileUpload(file) {
        const url = `${env.baseURL}/api/calendarBatchUpload/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', this.state.batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    changeUseImage = (val) => {
        this.setState({useImage: val, images: []});
    }
    changeImage = (e) => {
        const files = e.target.files
        if (files && files.length > 3) {
            showErrorNotify('Chọn tối đa 3 hình')
            return
        }
        this.setState({images: files})
    }

    uploadImages = () => {
        const url = `${env.baseURL}/api/calendarBatchUpload/upload-image`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        const {images} = this.state;
        for (let i = 0; i < images.length; i++) {
            formData.append('file', images[i]);
        }
        formData.append('batchId', this.state.batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        post(url, formData, config).then((response) => {
            if (response.data.success) {
                this.setState({
                    isLoading: false,
                    isRedirect: true
                });
                showSuccessNotify("Đã xử lý thành công");
            } else {
                this.setState({
                    isLoading: false,
                });
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
            }
        })
            .catch(error => {
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                this.setState({
                    isLoading: false,
                })
            });
    }

    changeType = (val) => {
        this.setState({type: val});
    }
    changeStartDate = (val) => {
        this.setState({startDate: val});
    }

    changeEndDate = (val) => {
        this.setState({endDate: val});
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/calendarBatches'/>;
        }
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="name" validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="schoolYearId"
                                            validate={required()}
                                            reference="schoolYears"
                                            {...this.props}
                                            allowEmpty
                                            sort={{field: 'name', order: 'DESC'}}
                                            perPage={999}>
                                <AutocompleteInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput source="startDate" onChange={(e, value) => this.changeStartDate(value)}
                                       validate={required()} {...this.props}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput source="endDate" onChange={(e, value) => this.changeEndDate(value)}
                                       validate={required()} {...this.props} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <CalendarTypeInput source="type" validate={required()}
                                               onChange={(e, value) => this.changeType(value)}  {...this.props}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <BooleanInput onChange={(e, value) => this.changeUseImage(value)}
                                          {...this.props}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        {this.state.useImage &&
                        <>
                            <Grid item xs={12} sm={12}>
                                <form style={{width: '90%'}}>
                                    <div style={{flexDirection: 'row'}}>
                                        <h4>Chọn hình (tối đa 3 hình)</h4>
                                        <input id="contained-button-file" type="file" multiple
                                               accept=".png,.jpg,.jpeg"
                                               onChange={(e) => this.changeImage(e)}/>
                                        {this.state.images && this.state.images.length > 0 &&
                                        <Button component="span" variant="contained"
                                                disabled={this.state.isLoading}
                                                onClick={this.uploadImages}>
                                            Cập nhật hình
                                        </Button>
                                        }
                                    </div>
                                    <ol>
                                        {[...this.state.images].map((f, i) => (
                                            <li key={i}>{f.name}</li>
                                        ))}
                                    </ol>
                                </form>
                            </Grid>
                        </>
                        }
                        {!this.state.useImage &&
                        this.state.showUploadFile && <Grid item xs={12} sm={6}>
                            <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                 onFileUpload={this.onFileUpload}
                                                 showValidateBtn={this.state.showValidateBtn}
                            />
                            {
                                this.state.isLoading && <CircularProgress color="secondary"/>
                            }
                        </Grid>
                        }
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default connect(null, null)(CalendarBatchEdit);

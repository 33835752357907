import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import {ReferenceInput, required, SelectInput, TextInput, translate} from 'react-admin';
import {CalendarTypeInput} from "../../components/CalendarType";

const styles = {
    checkboxGroup: {
        "& > fieldset > div ": {
            flexDirection: 'column',
        }
    }
}

const GoogleCalendarFields = ({classes, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CalendarTypeInput source="type" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()} allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
    </React.Fragment>
);

export default compose(
    translate,
    withStyles(styles)
)(GoogleCalendarFields)

import MealRegistrationIcon from '@material-ui/icons/ViewList';

import MealRegistrationList from './MealRegistrationList';
import MealRegistrationEdit from './MealRegistrationEdit';

export default {
  list: MealRegistrationList,
  edit: MealRegistrationEdit,
  icon: MealRegistrationIcon,
};

import React from 'react';
import {BooleanInput, LongTextInput, NumberInput, required, TextInput} from 'react-admin';
import Grid from '@material-ui/core/Grid';

const SettingFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="category" validate={required()} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="description" style={{width: '100%'}} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="intValue" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="boolValue" {...props} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="stringValue" style={{width: '100%'}} {...props} />
        </Grid>
    </React.Fragment>
)

export default SettingFields

import React from 'react';
import Grid from '@material-ui/core/Grid'
import {NumberInput, required, TextInput} from 'react-admin';

const DiligenceRangeFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
    </Grid>

    <Grid item xs={12} sm={6} >
      <NumberInput source="fromPoint" validate={required()} defaultValue={0} {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <NumberInput source="toPoint" validate={required()} defaultValue={1} {...props} />
    </Grid>
  </React.Fragment>
)

export default DiligenceRangeFields

import AttendanceIcon from '@material-ui/icons/ViewList';

import AttendanceList from './AttendanceList';
// import AttendanceCreate from './AttendanceCreate';
// import AttendanceEdit from './AttendanceEdit';

export default {
  list: AttendanceList,
  // create: AttendanceCreate,
  // edit: AttendanceEdit,
  icon: AttendanceIcon,
};

import React from 'react';
import Button from '@material-ui/core/Button';

const style = {
    btnAuthorize: {
        marginLeft: 10
    }
}

const FileUploadComponent = (props) => (
    <form>
        <h4>Chọn tập tin</h4>
        <input id="contained-button-file" type="file" accept={props.accept ? props.accept : "*"}
               onChange={props.onFileChanged}/>
        <Button disabled={props.loading} style={style.btnAuthorize} variant="contained" component="span"
                onClick={props.onFileUpload}>
            Nhập dữ liệu
        </Button>
        {props.showValidateBtn &&
        <Button disabled={props.loading} style={style.btnAuthorize} variant="contained" color={'primary'}
                component="span"
                onClick={props.onSubmit}>
            Duyệt
        </Button>
        }
        {props.showValidateBtn && props.onSubmit2 &&
        <Button disabled={props.loading} style={style.btnAuthorize} variant="contained" color={'primary'}
                component="span"
                onClick={props.onSubmit2}>
            Duyệt và thanh toán
        </Button>
        }
    </form>
)

export default FileUploadComponent;

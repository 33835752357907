import React from 'react';
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm
} from 'react-admin';

import {CustomToolbar, Grid, TreeCheckboxInput} from '../../components'

export const EventProductEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <React.Fragment>
          <Grid item xs={12} sm={6} >
            <ReferenceInput source="eventId" reference="events" disabled={true} {...props} >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6} >
            <label>Danh sách phí *</label>
            <ReferenceInput source="productIds"
                            reference="billProducts"
                            perPage={999}
                            validate={required()}
                            {...props} >
              <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
            </ReferenceInput>
          </Grid>

        </React.Fragment>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default EventProductEdit

import React, {useEffect, useState} from 'react';
import {Datagrid, DateInput, Filter, List, SelectInput, TextField} from 'react-admin';
import {IconButton} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import {withStyles} from "@material-ui/core/styles";
import axiosInstance from "../../api/axios";
import AttendanceResultListHeader from "./AttendanceResultListHeader";

const styles = theme => ({
  araButton: {
    backgroundColor: '#2B78FC',
    height: 30,
    width: 40,
    borderRadius: '10%',
    paddingTop: '0px !important',
  },
  araButton2: {
    height: 30,
    width: 40,
    borderRadius: '10%',
    border: 'solid 1px',
    paddingTop: '0px !important',
  },
});

const AttendanceResultFilter = (props) => {
  const {defaultTime} = props;
  const [eventTimes, setEventTimes] = useState([]);

  useEffect(() => {
    let eventTimes = JSON.parse(localStorage.getItem('eventTimes'));
    if (!eventTimes || eventTimes.length == 0) {
      loadEventTimes();
    } else {
      setEventTimes(eventTimes);
    }
  }, [defaultTime]);

  function loadEventTimes() {
    const url = `/api/eventTimes?page=0&size=999`;
    axiosInstance.get(url).then(rs => {
      let times = [];
      rs.data.content.map(event => times.push({
        id: event.startTime, name: event.startTime
      }))
      setEventTimes(times);
      localStorage.setItem('eventTimes', JSON.stringify(times));
    });
  }
  return <Filter {...props}>
    <SelectInput source="time"
                 choices={eventTimes}
                 defaultValue={defaultTime}
                 onChange={(e, value) => {
                   props.onChangeTime(value);
                 }}
                 alwaysOn/>

    <DateInput source="date" label="Ngày" alwaysOn
               onChange={(e, value) => {
                 props.onChangeDate(value);
               }}
               options={{
                 mode: "portrait",
                 locale: "vn",
                 defaultDate: props.date,
               }} />
  </Filter>
}

export const AttendanceResultAuthorizationList = props => {
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const searchCrits = decodeURIComponent(props.location.search);
    if (searchCrits) {
      let filterDateTime = searchCrits.split('&')[0].replace('?', '');
      let dateTime = filterDateTime.substring(filterDateTime.indexOf('{'), filterDateTime.indexOf('}') + 1);
      let dateTimeObj = JSON.parse(dateTime);
      setTime(dateTimeObj.time);
      setDate(dateTimeObj.date);
    }
  }, [time, date]);

  function AuthorizeButton({ record, ...props }) {
    if (record.authStatus === 'AUTHORIZED') {
      return (
          <IconButton className={props.classes.araButton}>
            <DoneIcon />
          </IconButton>
      );
    }
    return (
        <IconButton className={props.classes.araButton2}>
          <DoneIcon />
        </IconButton>
    );
  }

  const timeChange = (val) => {
    setTime(val);
  }

  const dateChange = (val) => {
    setDate(val);
  }

  return (
      <React.Fragment>
        <AttendanceResultListHeader time={time} date={date}/>
        <List {...props}
              filters={<AttendanceResultFilter defaultTime={time}
                                               onChangeTime={timeChange}
                                               onChangeDate={dateChange}/>}
              title={"Duyệt điểm danh"}
              exporter={false}
              bulkActions={false}>
          <Datagrid rowClick="edit">
            <TextField source="unitName" />
            <TextField source="present"/>
            <TextField source="totalOnTimes"/>
            <TextField source="totalLates"/>
            <TextField source="totalCms"/>
            <TextField source="missingCard"/>
            <TextField source="absentWithPermission"/>
            <TextField source="absentWithoutPermission"/>
            <AuthorizeButton label="Duyệt" {...props}/>
          </Datagrid>
        </List>
      </React.Fragment>
  );
};

export default withStyles(styles)(AttendanceResultAuthorizationList);

import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {Grid} from '@material-ui/core';

import {CustomToolbar} from '../../components'
import CardPermissionFields from "./CardPermissionFields";


export const CardPermissionCreate = ({showToolbar = true, redirect="list", noCancelButton, event, ...props}) => (
    <Create {...props}>
      <SimpleForm
          toolbar={ showToolbar ? <CustomToolbar redirect={redirect} noCancelButton={noCancelButton} /> : null }
          redirect={redirect}
      >
        <Grid container spacing={8}>
          <CardPermissionFields />
        </Grid>
      </SimpleForm>
    </Create>
);

export default CardPermissionCreate;

import React from 'react';
import {Datagrid, Filter, List, ReferenceField, SelectInput, TextField, TextInput} from 'react-admin';
import {schoolYears} from "../../components";

const GroupFilter = (props) => (
    <Filter {...props}>
        <SelectInput  label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                     alwaysOn allowEmpty={false}
        />
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const GroupList = props => (
    <List filters={<GroupFilter/>} filterDefaultValues={{schoolYear: schoolYears[0].id}}  {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="contactName"/>
            <TextField source="phone"/>
            <ReferenceField source="schoolYearId" reference="schoolYears" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);

export default GroupList

import React from 'react';
import {connect} from 'react-redux';
import {change, submit} from 'redux-form';
import {DateInput, fetchEnd, fetchStart, refreshView, required, showNotification, SimpleForm} from 'react-admin';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import StopIcon from '@material-ui/icons/Stop';
import axiosInstance from "../../api/axios";
import moment from 'moment'

const styles = {
    suggestDialog: {
        zIndex: '1 !important',
    },
    width100: {
        width: '100%',
    }
};

const FinalizeButton = (props) => {
    const [values, setValues] = React.useState({
        showDialog: false,
        date: null,
    })

    function showFinalizeDialog() {
        setValues({showDialog: true, date: null});
    }

    function onChanged(prop, val) {
        setValues({...values, [prop]: val});
    }

    function onSubmit() {
        setValues({showDialog: false});
        const url = `/api/finalizeMealRegistration`;
        const data = {
            clazzIds: props.selectedIds,
            date: values.date,
        }
        axiosInstance.put(url, data).then(rs => {
            if (rs.data.success) {
                props.showNotification('Chốt dữ liệu thành công');
                setTimeout(() => {
                    props.refreshView();
                }, 3000);// refresh after 5s
            }
        }, err => {
            props.showNotification(err.message, 'warning');
        });
    }

    function onClose() {
        setValues({showDialog: false});
    }

    return (
        <React.Fragment>
            <Button color="primary" variant="contained"
                    onClick={showFinalizeDialog}>
                <StopIcon/>
                Chốt điểm danh
            </Button>
            <Dialog open={values.showDialog}
                    maxWidth={'md'}
                    scroll={'paper'}
                    onBackdropClick={onClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div>Chọn ngày chốt</div>
                    {!values.error &&
                        <div style={{fontSize: 10, color: 'red'}}>
                            (*) Dữ liệu thực đơn bán trú phát sinh trước ngày này sẽ không thể chỉnh sửa.
                        </div>
                    }
                    {values.error &&
                        <div style={{fontSize: 10, color: 'red'}}>
                            {values.error}
                        </div>
                    }
                </DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={null}>
                        <DateInput
                            label="resources.finalizeMealRegistration.fields.date"
                            validate={required()}
                            onChange={(e, v) => onChanged('date', v)}
                            inputProps={{
                                max: moment().format('YYYY-MM-DD'),
                            }}

                        />
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit} color="primary" variant="contained"
                            disabled={!values.date}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView,
    showNotification
};

export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    FinalizeButton
);

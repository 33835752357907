import React from 'react';
import {Redirect} from 'react-router-dom';
import {Grid, Button} from '@material-ui/core'
import {DateInput, required, TextInput} from 'react-admin';
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

const styles = {
  button: {
    margin: 1,
  },
  cancelBtn: {
    marginLeft: 20
  }
};

const DayOffFields = props => {
  const [values, setValues] = React.useState({
    name: '',
    startDate: '',
    endDate: '',
    isFirstTimeLoad: true,
    isRedirect: false
  });
  const [customers, setCustomers] = React.useState([]);
  const {defaultValue, save} = props;

  React.useEffect(() => {
    if (values.isFirstTimeLoad) {
      getCustomersWithUnits();
      setValues({...values, isFirstTimeLoad: false});
    }
    if (defaultValue.isRedirect) {
      setValues({isRedirect: true});
    }
  }, [defaultValue.isRedirect]);

  function getCustomersWithUnits() {
    cachedApis.getCustomerUnits(false).then(rs => {
      setCustomers(rs);
    })
  }

  function cancel() {
    setValues({isRedirect: true});
  }

  if (values.isRedirect) {
    return <Redirect to='/dayOffs'/>;
  } else {
    return (
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <TextInput source="name"
                       defaultValue={defaultValue.name}
                       validate={required()}
                       onChange={props.onChange('name')}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput source="startDate"
                       defaultValue={defaultValue.startDate}
                       validate={required()}
                       style={{minWidth: 200}}
                       onChange={props.onChange('startDate')}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput type="time"
                       defaultValue={defaultValue.startTime}
                       source="startTime"
                       style={{minWidth: 200}}
                       onChange={props.onChange('startTime')}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput type="time"
                       defaultValue={defaultValue.endTime}
                       style={{minWidth: 200}}
                       source="endTime"
                       onChange={props.onChange('endTime')}/>
          </Grid>
          <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 50}}>
            <label>Người tham dự</label>
            <div style={{maxHeight: '300px', overflow: 'auto'}}>
              <TreeCheckBoxCustomInput data={customers}
                                         selected={defaultValue.customerIds}
                                         expandTopLevel={true}
                                         onChange={props.onChange('cuzIds')}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput source="endDate"
                       defaultValue={defaultValue.endDate}
                       style={{minWidth: 200}}
                       validate={required()}
                       onChange={props.onChange('endDate')}/>
          </Grid>
          <Grid item xs={12} sm={6} style={{marginTop: 50}}>
            <Button variant="contained"
                    color="primary"
                    style={styles.button}
                    disabled={defaultValue.saveDisabled}
                    onClick={save}>
              <SaveIcon/> Lưu
            </Button>
            <Button variant="contained" style={styles.cancelBtn}
                    onClick={cancel}>
              <CancelIcon/> Hủy
            </Button>
          </Grid>
        </React.Fragment>
    )
  }
}

export default DayOffFields

import React from 'react';
import {connect} from 'react-redux';
import {change, submit} from 'redux-form';
import {
  DateInput,
  fetchEnd,
  fetchStart,
  refreshView,
  SimpleForm,
  TextInput,
  showNotification
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import StopIcon from '@material-ui/icons/Stop';
import axiosInstance from "../../api/axios";
import moment from 'moment'

const styles = {
  suggestDialog: {
    zIndex: '1 !important',
  },
  width100: {
    width: '100%',
  }
};

const FinalizeButton = (props) => {

  const [values, setValues] = React.useState({
    showDialog: false,
    date: null,
    time: null
  })

  React.useEffect(() => {
  }, [values.date]);

  function showFinalizeDialog() {
    setValues({showDialog: true, date: null});
  }

  function onChanged(prop, val) {
    setValues({...values, [prop]: val});
  }

  function onSubmit() {
    const today = moment().format('YYYY-MM-DD');
    if (values.date > today) {
      setValues(
          {...values, error: 'Ngày chốt không được lớn hơn ngày hiện tại'});
      return;
    }
    setValues({showDialog: false});
    const url = `/api/finalizeAttendanceData`;
    const data = {
      clazzIds: props.selectedIds,
      date: values.date,
      time: values.time
    }
    axiosInstance.put(url, data).then(rs => {
      if (rs.data.success) {
        props.showNotification('Chốt dữ liệu thành công');
        setTimeout(() => {
          props.refreshView();
        }, 3000);// refresh after 5s
      }
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  function onClose() {
    setValues({showDialog: false});
  }

  return (
      <React.Fragment>
        <Button color="primary" variant="contained"
                onClick={showFinalizeDialog}>
          <StopIcon/>
          Chốt điểm danh
        </Button>
        <Dialog open={values.showDialog}
                maxWidth={'md'}
                scroll={'paper'}
                onBackdropClick={onClose}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div>Chọn ngày chốt</div>
            {!values.error &&
            <div style={{fontSize: 10, color: 'red'}}>(*) Dữ liệu điểm danh phát
              sinh trước
              ngày này sẽ không thể chỉnh sửa.
            </div>
            }
            {values.error &&
            <div style={{fontSize: 10, color: 'red'}}>
              {values.error}
            </div>
            }
          </DialogTitle>
          <DialogContent>
            <SimpleForm toolbar={null}>
              <Table aria-label="simple table">
                <TableRow>
                  <TableCell>
                    <DateInput
                        label="resources.finalizeAttendanceData.fields.date"
                        onChange={(e, v) => onChanged('date', v)}/>
                  </TableCell>

                  <TableCell>
                    <TextInput type="time" InputLabelProps={{shrink: true}}
                               label="resources.finalizeAttendanceData.fields.time"
                               onChange={(e, v) => onChanged('time', v)}/>
                  </TableCell>
                </TableRow>
              </Table>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSubmit} color="primary" variant="contained"
                    disabled={!values.date}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  )
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  refreshView,
  showNotification
};

export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    FinalizeButton
);

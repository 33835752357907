import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import moment from 'moment';

import { CustomToolbar, Grid } from '../../components'
import EventFields from './EventFields_old'

const defaultEvent = {
  enabled: true,
  recurring: true,
  recurringType: 'WEEKLY',
  separationCount: 1,
  daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
  startDate: moment(),
  // endDate: moment().add(1, 'months'),
  startTime: '08:00',
  endTime: '11:30',
  needCheckIn: true,
  lateComePermit: 5,
  earlyLeavePermit: 0,
  openForCheckIn: 0,
  endForCheckIn: 0,
  openForCheckOut: 0,
  endForCheckOut: 0
}

export const EventCreate_old = ({showToolbar = true, redirect="list", noCancelButton, event, ...props}) => {
  return (
      <Create {...props}>
        <SimpleForm
            defaultValue={ Object.assign({}, defaultEvent, event) }
            toolbar={ showToolbar ? <CustomToolbar redirect={redirect} noCancelButton={noCancelButton} /> : null }
            redirect={redirect}
        >
          <Grid container spacing={8}>
            <EventFields />
          </Grid>
        </SimpleForm>
      </Create>
  )
};

export default EventCreate_old

import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class BaoCaoDiemDanhGiaoVien extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventGroupId: '',
            toDay: moment().format('YYYY-MM-DD'),
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            isLoading: false
        };
    }

    excelReport = () => {
        this.setState({isLoading: true})
        downloadFile(this.getReportUrl(), 'bao_cao_diem_danh_gv.xlsx')
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }

    getReportUrl = () => {
        return `${env.baseURL}/reports/baoCaoDiemDanhGiaoVien?` +
            `eventGroupId=` + this.state.eventGroupId +
            `&pFromDate=` + this.state.fromDate +
            `&pToDate=` + this.state.toDate;
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'Phải lớn hơn Từ ngày'
        }
        // Validate
        if (values.toDate > this.state.toDay) {
            errors.toDate = 'Không được vượt quá hiện tại'
        }
        // Copy input values to params
        this.setState(values);

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.baoCaoDiemDanhGiaoVien.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                isLoading={this.state.isLoading}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="eventGroupId"
                                                reference="eventGroups"
                                                label="resources.eventGroups.name"
                                                perPage={50}
                                                validate={required()}
                                                sort={{field: 'name', order: 'ASC'}}>
                                    <AutocompleteInput source="name"/>
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </ReportForm>
                    <p>Dữ liệu xuất ra là dữ liệu tạm thời cập nhật tại thời điểm thực hiện báo cáo và có thể thay đổi
                        theo thời gian chạm thẻ thực tế của người dùng.</p>
                    <p>Dữ liệu báo cáo sẽ được chốt lại khi sự kiện kết thúc</p>
                </CardContent>
            </Card>
        )
    }
}

export default BaoCaoDiemDanhGiaoVien;

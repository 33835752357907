import React, {useEffect, useState} from 'react';
import env from "../../env";
import {refreshView} from "react-admin";
import {compose} from 'redux';
import {connect} from 'react-redux';

const ImageProfile = (props) => {

    const [url, setUrl] = useState(null);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        const {photoPath} = props.record;
        if (photoPath != null) {
            setUrl(photoPath)
        }
        setShowBtn(photoPath != null)
    }, [props.record.photoPath])

    return (
        <>
            {showBtn &&
            <img src={`${env.baseURL}/cus-photo/${url}`}
                 alt="" style={{maxWidth: '140px', maxHeight: 120}}
            />
            }
        </>
    )
}


export default compose(connect(null, {refreshView}))(
    ImageProfile
);

import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import {DateTimeField} from "../../components";

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  icon: {
    fontSize: 18,
  },
};

class CustomerHealthBatchValidation extends Component {

  render() {
    return (
        <div style={classes.root}>
          <Paper style={classes.paper}>
            <Table style={classes.table} size="small">
              <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                <TableRow>
                  <TableCell>Hợp Lệ</TableCell>
                  <TableCell>Mô tả lỗi</TableCell>
                  <TableCell>Lớp</TableCell>
                  <TableCell>Mã định danh Bộ GD&ĐT</TableCell>
                  <TableCell>Mã VinaID</TableCell>
                  <TableCell>Họ tên</TableCell>
                  <TableCell>Ngày sinh</TableCell>
                  <TableCell>Giới tính</TableCell>
                  <TableCell>Cân nặng</TableCell>
                  <TableCell>Chiều cao</TableCell>
                  <TableCell>BMI</TableCell>
                  <TableCell>Phân loại dinh dưỡng</TableCell>
                  <TableCell>Bệnh về mắt</TableCell>
                  <TableCell>Tai mũi họng</TableCell>
                  <TableCell>Răng hàm mặt</TableCell>
                  <TableCell>Bệnh cột sống</TableCell>
                  <TableCell>Bệnh truyền nhiễm</TableCell>
                  <TableCell>Bệnh ngoài da</TableCell>
                  <TableCell>Bệnh tim mạch</TableCell>
                  <TableCell>Bệnh hô hấp</TableCell>
                  <TableCell>Tai nạn</TableCell>
                  <TableCell>Biết bơi</TableCell>
                  <TableCell>Sức khoẻ học kỳ</TableCell>
                  <TableCell>Ghi chú</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        {row.valid &&
                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                        }
                        {!row.valid &&
                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                        }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.errorMessage}
                      </TableCell>
                      <TableCell>{row.unitName}</TableCell>
                      <TableCell>{row.internalCode}</TableCell>
                      <TableCell>{row.customerId}</TableCell>
                      <TableCell>{row.customerName}</TableCell>
                      <TableCell>
                        <DateTimeField record={{...row}} source='dob' format='DD/MM/yyyy'/>
                      </TableCell>
                      <TableCell>{row.gender}</TableCell>
                      <TableCell>{row.weight}</TableCell>
                      <TableCell>{row.height}</TableCell>
                      <TableCell>{row.bmi}</TableCell>
                      <TableCell>{row.nutritionalClassification}</TableCell>
                      <TableCell>{row.sight}</TableCell>
                      <TableCell>{row.earNoseThroat}</TableCell>
                      <TableCell>{row.dentomaxillofacial}</TableCell>
                      <TableCell>{row.benhCotSong}</TableCell>
                      <TableCell>{row.benhTruyenNhiem}</TableCell>
                      <TableCell>{row.benhNgoaiDa}</TableCell>
                      <TableCell>{row.benhTimMach}</TableCell>
                      <TableCell>{row.benhHoHap}</TableCell>
                      <TableCell>{row.taiNan}</TableCell>
                      <TableCell>{row.canSwim ? 'x' : ''}</TableCell>
                      <TableCell>{row.semesterHealth}</TableCell>
                      <TableCell>{row.note}</TableCell>

                    </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={this.props.total}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                backIconButtonprops={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonprops={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          </Paper>
        </div>
    );
  }
}

export default CustomerHealthBatchValidation;

import React from 'react';
import {List, Datagrid, TextField, Filter, TextInput} from 'react-admin';

const InternalCodeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn />
    </Filter>
);

const CustomerInternalCodeList = (props) => {
    return (
        <List {...props} filters={<InternalCodeFilter />}
              sort={{field: 'issue_date', order: 'DESC'}}
              bulkActions={false}
              exporter={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="customerId" />
                <TextField source="cardNumber" />
                <TextField source="customerName" />
                <TextField source="internalCode" />
            </Datagrid>
        </List>
    );
};

export default CustomerInternalCodeList;

import React from 'react';
import { List, Datagrid, Filter, BooleanField, ReferenceField, TextField, TextInput } from 'react-admin';

const RolePrivilegesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const RolePrivilegesList = props => (
  <List filters={<RolePrivilegesFilter />} {...props} >
    <Datagrid rowClick="edit" >
      <ReferenceField source="roleId" reference="roles" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="privilegeName" />
    </Datagrid>
</List>
);

export default RolePrivilegesList

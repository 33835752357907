import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import TenantFields from './TenantFields'
import ImageUploadWrapper from "../../components/ImageUploadWrapper";

export const TenantEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <TenantFields/>
                <ImageUploadWrapper {...props} url={'/api/photo/tenant/'}/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default TenantEdit

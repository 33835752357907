import BankStatementIcon from '@material-ui/icons/ViewList';

import BankStatementList from './BankStatementList';
import BankStatementCreate from './BankStatementCreate';
import BankStatementEdit from './BankStatementEdit';

export default {
  list: BankStatementList,
  create: BankStatementCreate,
  edit: BankStatementEdit,
  icon: BankStatementIcon,
};

import React, {useState} from 'react';
import {Create, showNotification, SimpleForm} from 'react-admin';

import {Grid, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components'
import MessageFields from './MessageFields'
import utils from "../../utils/utils";
import axiosInstance from "../../api/axios";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

const defaultValue = {
    channel: 'APP',
    authStatus: 'NOT_AUTHORIZED',
    subject: 'Thông báo',
    linkDisplay: 'Xem đường dẫn',
    category: 'SENT_MANUALLY_BY_USER'
}
let timeout;
export const MessageCreate = props => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        text: '',
        channel: defaultValue.channel,
        authStatus: defaultValue.authStatus,
        customerIds: [],
        files: [],
        linkDisplay: defaultValue.linkDisplay,
        link: '',
        pin: false,
        subject: defaultValue.subject,
        category: defaultValue.category,
        cuzIds: [],
        isRedirect: false,
    });

    const handleSelectChange = (prop, e) => {
        if (!e) {
            return
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: e});
        }, 500);
    }

    const handleChange = prop => e => {
        if (!e) {
            return
        }

        let value
        if (['cuzIds'].includes(prop)) {
            value = e;
        } else {
            if (e.target) {
                value = e.target.value ? e.target.value : e.target.checked;
            } else {
                value = e
            }
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function changeFile(e) {
        const files = e.target.files
        if (files && files.length > 3) {
            showErrorNotify('Chọn tối đa 3 hình')
            return
        }
        setValues({...values, files: files});
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    function save() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const formData = new FormData();
        const files = values.files
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
        }
        formData.append('channel', values.channel)
        formData.append('customerIds', values.customerIds)
        formData.append('text', values.text)
        formData.append('category', values.category)
        formData.append('link', values.link)
        formData.append('linkDisplay', values.linkDisplay)
        formData.append('pin', values.pin)
        formData.append('subject', values.subject)
        formData.append('authStatus', values.authStatus)
        const url = `/api/messages`;
        axiosInstance.post(url, formData).then(rs => {
            setLoading(false)
            props.showNotification('Tạo thành công');
            setValues({...values, isRedirect: true});
        }, err => {
            setLoading(false)
            props.showNotification(err.message, 'warning');
        })
    }

    function authorize() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const formData = new FormData();
        const files = values.files
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
        }
        formData.append('channel', values.channel)
        formData.append('customerIds', values.customerIds)
        formData.append('text', values.text)
        formData.append('category', values.category)
        formData.append('link', values.link)
        formData.append('linkDisplay', values.linkDisplay)
        formData.append('pin', values.pin)
        formData.append('subject', values.subject)
        formData.append('authStatus', 'AUTHORIZED')
        const url = `/api/messages`;
        showInfoNotify('Đang gửi tin nhắn, vui lòng chờ trong giây lát')
        axiosInstance.post(url, formData).then(rs => {
            setLoading(false)
            showSuccessNotify('Gửi tin nhắn thành công')
            setValues({...values, isRedirect: true});
        }, err => {
            setLoading(false)
            showErrorNotify(err.message)
        })
    }

    const validate = () => {
        if (!values.text || values.text.trim() === '') {
            props.showNotification('Cần nhập nội dung', 'warning');
            return false;
        }
        if (!values.cuzIds || values.cuzIds.length === 0) {
            props.showNotification('Cần chọn danh sách người nhận', 'warning');
            return false;
        }
        return true
    }

    return (
        <Create {...props}>
            <SimpleForm toolbar={null}>
                <Grid container spacing={8}>
                    <MessageFields onChange={handleChange}
                                   handleSelectChange={handleSelectChange}
                                   defaultValue={values}
                                   permissions={props.permissions}
                                   save={save}
                                   files={values.files}
                                   authorize={authorize}
                                   changeFile={changeFile}
                                   onDirectChange={onDirectChange} loading={loading}/>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(MessageCreate)

import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import FloorFields from './FloorFields'

export const FloorCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <FloorFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default FloorCreate

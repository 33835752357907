import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceArrayInput, required, Title} from 'react-admin';
import {downloadFile, IFrameHtmlReport, ReportForm, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

class InReport extends React.Component {
  state = {
    openHtmlReport: false,
    htmlReportUrl: '',
    unitIds: [],
    date: moment().format('YYYY-MM-DD'),
  };

  getReportUrl = (type) => {
    const token = localStorage.getItem('token');
    return `${env.baseURL}/reports/in-report?type=` + type +
        `&unitIds=` + this.state.unitIds +
        `&date=` + this.state.date +
        `&token=` + token;
  }
  getReportUrlToDownload = (type) => {
    return `${env.baseURL}/reports/in-report?type=` + type +
        `&unitIds=` + this.state.unitIds +
        `&date=` + this.state.date;
  }

  htmlReport = () => {
    if (!this.state.unitIds || this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phòng ban")
      return;
    }
    const htmlReportUrl = this.getReportUrl('HTML')
    this.setState({openHtmlReport: true, htmlReportUrl })
  }

  pdfReport = () => {
    if (!this.state.unitIds || this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phòng ban")
      return;
    }
    downloadFile(this.getReportUrlToDownload('PDF'), 'bao_cao_vao.pdf')
  }

  excelReport = () => {
    if (!this.state.unitIds || this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách phòng ban")
      return;
    }
    downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_vao.xlsx')
  }

  validate = (values) => {
    const errors = {};

    // Copy input values to params
    this.setState({
      unitIds: values.unitIds,
      date: values.date,
    })

    return errors;
  }
  
  render() {
    return (
      <Card>
        <Title title="resources.inReport.name" />
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      htmlReport={this.htmlReport}
                      pdfReport={this.pdfReport}
                      excelReport={this.excelReport}
          >
            <span>
              Mô tả: Báo cáo dựa trên dữ liệu chạm thẻ
            </span>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <label>Phòng ban *</label>
                <ReferenceArrayInput source="unitIds"
                                     reference="units"
                                     perPage={999}
                                     filter={{departmentWithParents: true}}
                                     {...this.props} >
                  <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.date" source="date" validate={required()} {...this.props} />
              </Grid>

            </Grid>
          </ReportForm>

          {/* The iframe to show HTML report */}
          { 
            this.state.openHtmlReport && 
            <IFrameHtmlReport src={this.state.htmlReportUrl} />
          }
        </CardContent>
      </Card>      
    )
  }
}

export default InReport;


import React from 'react';
import {Create, showNotification, SimpleForm} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'redux';

import DayOffFields from './DayOffFields'
import {Grid} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";

let timeout = null;
export const DayOffCreate = props => {
  const [values, setValues] = React.useState({
    customerIds: [],
    name: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    saveDisabled: true,
    isRedirect: false,
    cuzIds: []
  });

  React.useEffect(() => {
    validate(false);
  }, [values.name, values.startDate, values.endDate]);

  function save() {
    if (!validate( true)) {
      return;
    }
    const url = `/api/dayOffs`;
    const data = {
      customerIds: utils.extractCustomerIds(values.cuzIds),
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate,
      startTime: values.startTime,
      endTime: values.endTime
    }
    axiosInstance.post(url, data).then(rs => {
      props.showNotification('Tạo ngày nghỉ thành công');
      setValues({isRedirect: true});
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  const handleChange = prop => e => {
    let val = null;
    if (prop === 'startDate' || prop === 'endDate') {
      val = Object.values(e).join('').substr(0, 10);
    } else {
      if (prop === 'savedDisabled' || prop === 'cuzIds') {
        val = e;
      } else {
        val = e.target.value;
      }
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setValues({...values, [prop]: val});
    }, 500);
  };

  function validate(isShowNotification) {
    if (isShowNotification) {//show details error
      if (values.startDate > values.endDate) {
        props.showNotification('Ngày bắt đầu phải nhỏ hơn ngày kết thúc', 'warning');
        return false;
      }
      if (!values.name) {
        props.showNotification('Bắt buộc nhập tên ngày nghỉ', 'warning');
        return false;
      }
      if (!values.startDate) {
        props.showNotification('Bắt buộc nhập ngày bắt đầu', 'warning');
        return false;
      }
      if (!values.endDate) {
        props.showNotification('Bắt buộc nhập ngày kết thúc', 'warning');
        return false;
      }
    } else {
      if (!values.name || !values.startDate || !values.endDate) {
        setValues({...values, saveDisabled: true});
        return false;
      }
    }
    setValues({...values, saveDisabled: false});
    return true;
  }

  return (
      <Create {...props}>
        <SimpleForm toolbar={null}>
          <Grid container spacing={8}>
            <DayOffFields defaultValue={values}
                          onChange={handleChange}
                          save={save}/>
          </Grid>
        </SimpleForm>
      </Create>
  )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(DayOffCreate)

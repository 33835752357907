import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {ReferenceArrayInput, Title} from 'react-admin';
import {downloadFile, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import ReportForm from "../../components/ReportForm";

class CustomerImageBatch extends React.Component {
    state = {
        changeSchoolYear: false,
        unitIds: [],
        schoolYearId: schoolYears[0].id,
    };

    getReportUrl = () => {
        downloadFile(`${env.baseURL}/reports/customerImageBatch?` +
            `unitIds=` + this.state.unitIds, 'Lo_hinh_anh.zip')
    }

    downLoadUserImageBatch = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl()
    }

    validate = (values) => {
        const errors = {};
        this.setState({
            unitIds: values.unitIds
        })

        return errors;
    }

    onSchoolYearChanged(e, value) {
        this.setState({
            schoolYearId: value,
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.customerImageBatch.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                zipReport={this.downLoadUserImageBatch}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp/phòng ban *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                         filter={{schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''}}
                                                         perPage={999} {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceArrayInput>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>

                </CardContent>
            </Card>
        )
    }
}

export default CustomerImageBatch


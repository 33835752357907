import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";

const TongHopDuyetDiemDanh = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        eventIds: [],
        isLoading: false
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/tongHopDuyetDiemDanh?fromDate=` + moment(state.fromDate).format('YYYY-MM-DD')
            + '&eventIds=' + state.eventIds;
    };

    const excelReport = () => {
        setState({...state, isLoading: true})
        downloadFile(getReportUrl(), 'tong_hop_duyet_diem_danh.xlsx')
            .then(() => {
                setState({...state, isLoading: false})
            })
            .catch(() => {
                setState({...state, isLoading: false})
            })
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.tongHopDuyetDiemDanh.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={state.isLoading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="eventIds"
                                            reference="events"
                                            perPage={999}
                                            validate={required()}
                                            filter={{filterPath: 'filterSimple'}}
                                            {...props} >
                                <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                            </ReferenceInput>

                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default TongHopDuyetDiemDanh


import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const paymentMethods = [
  {id: 'BANK_TRANSFER', name: 'Chuyển khoản'},
  {id: 'CASH', name: 'Tiền mặt'},
]

export const PaymentMethodField = (props) => (
  <SelectField source={props.source} choices={paymentMethods} {...props} />
);

export const PaymentMethodInput = (props) => (
  <SelectInput source={props.source} choices={paymentMethods} {...props}  label="resources.paymentMethod.name"/>
);

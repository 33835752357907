import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../../components';
import env from '../../../env';

class BaoCaoSoDuTaiKhoan extends React.Component {
  state = {
    htmlReportUrl: '',
  };

  getReportUrl = (type) => {
    return `${env.baseURL}/reports/baoCaoSoDuTaiKhoan?type=` + type;
  }

  excelReport = () => {
    downloadFile(this.getReportUrl('XLS'), 'bao_cao_so_du_tai_khoan.xlsx')
  };

  validate = (values) => {
    const errors = {};
    // Copy input values to params
    this.setState(values);

    return errors;
  };
  
  render() {
    return (
      <Card>
        <Title title="resources.baoCaoSoDuTaiKhoan.name" />
        <CardContent>
          <ReportForm params={this.state}
            validate={this.validate} 
            excelReport={this.excelReport}
          >
          </ReportForm>
        </CardContent>
      </Card>      
    )
  }
}

export default BaoCaoSoDuTaiKhoan;


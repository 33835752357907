import RoleSubMenuIcon from '@material-ui/icons/ViewList';

import RoleSubMenuList from './RoleSubMenuList';
import RoleSubMenuCreate from './RoleSubMenuCreate';
import RoleSubMenuEdit from './RoleSubMenuEdit';

export default {
  list: RoleSubMenuList,
  create: RoleSubMenuCreate,
  edit: RoleSubMenuEdit,
  icon: RoleSubMenuIcon,
};

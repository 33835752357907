import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from 'moment';

class LuotKhamDungThe extends React.Component {
  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/luotKhamDungThe` +
        `?fromDate=` + this.state.fromDate +
        `&toDate=` + this.state.toDate;
  };

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'luôt_kham_dung_the.xlsx')
  };

  validate = (values) => {
    const errors = {};
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'Thời gian không hợp lệ'
    }
    // Copy input values to params
    this.setState(values);
    return errors;
  };

  render() {
    return (
      <Card>
        <Title title="resources.luotKhamDungThe.name"/>
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                </Grid>
              </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default LuotKhamDungThe


import AccessDeviceIcon from '@material-ui/icons/ViewList';

import CalendarBatchList from './CalendarBatchList';
import IntroductionFileCreate from './CalendarBatchCreate';
import IntroductionFileEdit from './CalendarBatchEdit';

export default {
  list: CalendarBatchList,
  create: IntroductionFileCreate,
  edit: IntroductionFileEdit,
  icon: AccessDeviceIcon,
};

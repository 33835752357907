import React, {useCallback, useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import {ReportForm, schoolYears, TreeCheckboxInput} from "../../components";
import {
    AutocompleteInput,
    DateInput,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    Title,
    Toolbar,
} from "react-admin";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axiosInstance from "../../api/axios";
import {CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import CancelButton from "../../components/CancelButton";
import {addField} from 'ra-core';
import Button from '@material-ui/core/Button';
import env from "../../env";
import {downloadFilePost} from "../../components/FileDownload";
import Input from '@material-ui/core/Input';



const CustomToolbar = addField(({onCancel, excelReport, loading}) => {
    return <Toolbar>
        <Button color="secondary" disabled={loading} variant="raised" onClick={excelReport} label={''}>
            Xuất excel
        </Button>
        {loading && <CircularProgress style={{marginLeft: 10}} color="secondary"/>}
        <CancelButton onClick={onCancel} label='Chọn lại'/>
    </Toolbar>
})

const CustomerTableRow = React.memo(props => {
    const {customerId, unitName, customerName, offDate, reason, index, defaultAbsentReasons, onValueChange} = props
    const ref = useRef(reason)

    return <TableRow key={index}>
        <TableCell padding={'normal'} width={50}>{index + 1}</TableCell>
        <TableCell width={75}>{customerId}</TableCell>
        <TableCell width={75}>{unitName}</TableCell>
        <TableCell width={175}>{customerName}</TableCell>
        <TableCell width={75}>{moment(offDate).format('DD-MM-YYYY')}</TableCell>
        <TableCell>
           <Input fullWidth defaultValue={reason} inputRef={ref} onChange={(e) => onValueChange(`reason-${index}`, e.target.value)}/>
        </TableCell>
        <TableCell>
            <RadioButtonGroupInput label={false}
                                   source={`default-reason-${index}`}
                                   choices={defaultAbsentReasons}
                                   onChange={(_, value) => {
                                       onValueChange(`reason-${index}`, value)
                                       ref.current.value = value
                                   }}
                                   options={{style: {flexDirection: 'row', value: 'Không lí do'}}}
            />
        </TableCell>
    </TableRow>
})

const FormDetail = ({setShowDetail, data, selectedValues, defaultAbsentReasons}) => {

    const [dataSource, setDataSource] = useState(data)
    const [loading, setLoading] = useState(false)
    const onValueChange = useCallback((key, value) => {
        const [field, rowNum] = key.split('-')
        setDataSource(prev => prev.map((item, idx) => ({...item, [field]: idx == rowNum ? value : item[field]})))
    }, [])
    const excelReport = async () => {
        setLoading(true)
        const {unitIds, date} = selectedValues
        await downloadFilePost(`${env.baseURL}/reports/attendanceBook?unitIds=${unitIds}&date=${date}`, 'bc_diem_danh.xlsx', dataSource)
        setLoading(false)
    }

    const onCancel = () => {
        setShowDetail(false)
    }

    return <div>
        <div style={{textAlign: 'center', fontWeight: 500}}>Danh sách học sinh vắng</div>
        <div style={{marginLeft: 24, marginTop: 16}}>Tổng số: {data.length}</div>
        <ReportForm toolbar={<CustomToolbar loading={loading} onCancel={onCancel} excelReport={excelReport}/>}>
            <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell padding='normal' width={50}>STT</TableCell>
                        <TableCell width={75}>Mã VinaID</TableCell>
                        <TableCell width={75}>Lớp</TableCell>
                        <TableCell width={175}>Họ và Tên</TableCell>
                        <TableCell width={75}>Ngày nghỉ</TableCell>
                        <TableCell>Lí do</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataSource.map((item, index) => <CustomerTableRow key={index}
                                                                       index={index}
                                                                       onValueChange={onValueChange}
                                                                       defaultAbsentReasons={defaultAbsentReasons}
                                                                       {...item}
                    /> )}
                </TableBody>
            </Table>
        </ReportForm>
    </div>
}

const StudentAttendanceBookReport = (props) => {

    const [showDetail, setShowDetail] = useState(false)
    const [data, setData] = useState([])
    const [selectedValues, setSelectedValues] = useState({unitIds: []})
    const [loading, setLoading] = useState(false)
    const [defaultAbsentReasons, setDefaultAbsentReasons] = useState([])

    useEffect(() => {
        axiosInstance.get(`/api/attendance/defaultAbsentReasons`)
            .then(res => setDefaultAbsentReasons(res.data?.map((item) => ({id: item, name: item}))))
    }, [])

    const loadAbsentStudent = async (value) => {
        const {unitIds = [], eventId, date} = value
        setLoading(true)
        const response = await axiosInstance.get(`/api/attendance/absentStudent?unitIds=${unitIds}&eventId=${eventId}&date=${date}`);
        setSelectedValues({unitIds, eventId, date})
        setData(response.data || [])
        setShowDetail(true)
        setLoading(false)
    }
    return <>
        <Card>
            <Title title="resources.soDiemDanh.name"/>
            <CardContent>
                {!showDetail && <ReportForm params={{...selectedValues}}
                                            excelReport={() => {
                                            }}
                                            save={loadAbsentStudent}
                                            label={'Tải dữ liệu'}
                                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.date" source="date"
                                       validate={required()}
                                       inputProps={{max: moment().format('YYYY-MM-DD')}} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label>Lớp *</label>
                            <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                 filter={{
                                                     schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : '',
                                                     clazzOnly: true
                                                 }}
                                                 perPage={999} {...props}
                                                 label='Lớp'
                            >
                                <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                            </ReferenceArrayInput>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="eventId"
                                        reference="events"
                                        label="resources.soDiemDanh.eventId"
                                        perPage={50}
                                        filter={{filterPath: 'filterSimple'}}
                                        validate={required()}
                                        sort={{field: 'name', order: 'ASC'}}>
                            <AutocompleteInput source="name"/>
                        </ReferenceInput>
                    </Grid>
                </ReportForm>}

                {showDetail && <FormDetail defaultAbsentReasons={defaultAbsentReasons} data={data} selectedValues={selectedValues} setShowDetail={setShowDetail}/>}
            </CardContent>
        </Card>
    </>
}

export default StudentAttendanceBookReport

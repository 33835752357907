import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const hocKiEnum = [
  { id: 'GK1', name: 'Giữa kỳ I' },
  { id: 'HK1', name: 'Cuối kỳ I' },
  { id: 'HK2',   name: 'Giữa kỳ II' },
  { id: 'CA_NAM',   name: 'Cuối năm' },
]

export const HocKiField = (props) => (
    <SelectField source={props.source} choices={hocKiEnum} {...props} />
)

export const HocKiInput = (props) => (
    <SelectInput source={props.source} choices={hocKiEnum} {...props} />
)

import React, {useEffect, useState} from 'react';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import {showErrorNotify, showSuccessNotify} from './ShowNotify'
import Swal from "sweetalert2";
import axiosInstance from "../api/axios";
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, submit} from "redux-form";
import withStyles from '@material-ui/core/styles/withStyles';


const styles = {
};

const DeleteRecordButton = (props) => {
    const {record, title, path, basePath} = props;
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        setShowBtn(record.id && !record.canceledBy && !record.deleted)
    }, [record])

    const handleClick = async () => {
        if (record) {
            const {value: reason} = await Swal.fire({
                title: title,
                text: "Nhập lý do xoá/huỷ",
                type: "warning",
                input: 'text',
                inputValue: '',
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
                inputValidator: (value) => {
                    if (!value) {
                        return 'Bạn cần nhập lý do xoá/huỷ!'
                    }
                }
            })

            if (reason) {
                deleteReceipt(record.id, reason)
                    .then(({data}) => {
                        if (data == null || !data.success) {
                            showErrorNotify('Xoá/Huỷ không thành công, vui lòng kiểm tra lại hoặc liên hệ quản trị viên!')
                        } else {
                            // return is list payment ids
                            showSuccessNotify('Xoá/Huỷ thành công!')
                            props.refreshView();
                        }

                    })
                    .catch(error => {
                        showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                    })
                    .finally(() => {

                    });
            }
        } else {
            showErrorNotify("Vui lòng chọn dữ liệu")
        }

    };

    const deleteReceipt = (id, reason) => {
        return axiosInstance.delete((path ? path : '/api' + basePath), {
            headers: {
                Authorization: localStorage.getItem('token')
            },
            data: {
                id,
                reason
            }
        });
    };

    return (
        showBtn &&
        <Button style={{fontSize: '0.7rem', color: 'red'}} onClick={handleClick} label="ra.action.destroy">
            <DeleteIcon/>
        </Button>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    DeleteRecordButton
);

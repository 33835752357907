import InsuranceBatchIcon from '@material-ui/icons/ViewList';

import InsuranceBatchList from './InsuranceBatchList';
import InsuranceBatchCreate from './InsuranceBatchCreate';
import InsuranceBatchEdit from './InsuranceBatchEdit';

export default {
  list: InsuranceBatchList,
  create: InsuranceBatchCreate,
  edit: InsuranceBatchEdit,
  icon: InsuranceBatchIcon,
};

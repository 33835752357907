import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import GradeFields from './GradeFields'

export const GradeEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <GradeFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default GradeEdit

import React from 'react';
import Grid from '@material-ui/core/Grid'
import {NumberInput, required, SelectInput} from 'react-admin';

export const gateways = [
  { id: 'VNPAY', name: 'VNPAY' },
  { id: 'MASTERCARD', name: 'MASTERCARD' },
]

export const paymentTypes = [
  { id: 'VISA/MASTER', name: 'VISA/MASTER' },
  { id: 'ATM', name: 'ATM' },
]
const PaymentGatewayFeesFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <SelectInput source="gateway"
                   choices={gateways}
                   label={'Cổng thanh toán'}
                   validate={required()}/>
    </Grid>
    <Grid item xs={12} sm={6} >
      <SelectInput source="name"
                   choices={paymentTypes}
                   label={'Loại thanh toán'}
                   validate={required()}/>
    </Grid>
    <Grid item xs={12} sm={6} >
      <NumberInput source="fixAmount"
                   label={'Hạn mức tĩnh(vnd)'}
                   validate={required()}/>
    </Grid>
    <Grid item xs={12} sm={6} >
      <NumberInput source="percentage"
                   label={'Phí giao dịch(%)'}
                   validate={required()}/>
    </Grid>
  </React.Fragment>
)

export default PaymentGatewayFeesFields

import UnitIcon from '@material-ui/icons/ViewList';

// import UnitList from './UnitList';
// import UnitCreate from './UnitCreate';
import UnitEdit from './UnitEdit';

export default {
  // list: UnitList,
  // create: UnitCreate,
  edit: UnitEdit,
  icon: UnitIcon,
};

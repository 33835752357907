import ProductIcon from '@material-ui/icons/ViewList';

import ContrbibutionProductList from './ContributionProductList';
import ContributionProductCreate from './ContributionProductCreate';
import ContributionProductEdit from './ContributionProductEdit';

export default {
  list: ContrbibutionProductList,
  create: ContributionProductCreate,
  edit: ContributionProductEdit,
  icon: ProductIcon,
};

import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  Pagination,
  ReferenceInput,
  showNotification,
  TextField
} from 'react-admin';
import {withStyles} from "@material-ui/core/styles";
import compose from 'recompose/compose';
import {connect} from 'react-redux'
import FinalizeButton from "./FinalizeButton";

const styles = theme => ({
  araButton: {
    backgroundColor: '#2B78FC',
    height: 30,
    width: 40,
    borderRadius: '10%',
    paddingTop: '0px !important',
  },
  araButton2: {
    height: 30,
    width: 40,
    borderRadius: '10%',
    border: 'solid 1px',
    paddingTop: '0px !important',
  },
});

class ManualAttendanceFilter extends React.Component {
  state = {
    eventId: '',
    unitId: '',
  };

  onEventChange = (e, value) => {
    this.setState({eventId: value})
  };

  onUnitChange = (e, value) => {
    this.setState({unitId: value})
  };

  render() {
    return (
    <Filter {...this.props}>
      <ReferenceInput source="eventId"
                      onChange={this.onEventChange}
                      {...this.props}
                      label="Tìm theo event"
                      reference="events"
                      allowEmpty
                      sort={{field: 'name', order: 'ASC'}}
                      filter={{filterPath: 'filterSimple'}}
                      perPage={20}
                      alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="unitId"
                      {...this.props}
                      label="Tìm theo lớp/phòng ban/căn hộ"
                      onChange={this.onUnitChange}
                      reference="units"
                      allowEmpty
                      sort={{field: 'name', order: 'ASC'}}
                      filter={{eventId: this.state.eventId, filterPath: 'filterWithEvent'}}
                      perPage={20}
                      alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="customerId"
                      {...this.props}
                      label="Tìm theo tên"
                      reference="customers"
                      allowEmpty
                      sort={{field: 'name', order: 'ASC'}}
                      filter={{unitId: this.state.unitId}}
                      perPage={20}
                      alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </Filter>
    )
  }
};

const ManualAttendancePagination = props => <Pagination
  rowsPerPageOptions={[25, 50, 100]} perPage={100} {...props} />

export const ManualAttendanceList = props => {

    return (
      <List filters={<ManualAttendanceFilter/>}
            bulkActionButtons={<FinalizeButton/>}
            pagination={<ManualAttendancePagination/>} {...props}  exporter={false}  >
        <Datagrid rowClick="edit">
          <TextField source="id"/>
          <TextField source="name"/>
        </Datagrid>
      </List>
    )
  }
;

export default compose(
  withStyles(styles),
  connect(null, {showNotification}),
)(ManualAttendanceList);

import React from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import {downloadFile} from "../../components";
import env from "../../env";

const DownloadPasswordFile = (props) => {

    function onSubmit() {
        downloadFile(`${env.baseURL}/api/users/download-password-file?userIds=` + props.selectedIds, 'nguoi_dung.xlsx')
    }

    return (
        <Button variant="outlined" label="Tải file mật khẩu" onClick={onSubmit}/>
    );
}

export default connect(null, {refreshView})(
    DownloadPasswordFile
);


import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const foodTypes = [
    {
        id: 'MAN',
        name: 'Món mặn'
    },
    {
        id: 'XAO',
        name: 'Món xào'
    },
    {
        id: 'CANH',
        name: 'Món canh'
    },
    {
        id: 'TRANG_MIENG',
        name: 'Tráng miệng'
    },
    {
        id: 'AN_XE',
        name: 'Ăn xế'
    },
    {
        id: 'COM',
        name: 'Cơm'
    },
    {
        id: 'CHAY',
        name: 'Chay'
    },
    {
        id: 'CHAO',
        name: 'Cháo'
    }
]

export const FoodTypeField = (props) => (
    <SelectField source={props.source} choices={foodTypes} {...props} />
)

export const FoodTypeInput = (props) => (
    <SelectInput source={props.source} choices={foodTypes} {...props} />
)


import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {
  DateInput,
  required,
  Title,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import moment from 'moment'
import env from "../../env";

class PresentDeputyReport extends React.Component {

  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    eventId: ''
  }

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'danh_sach_dai_bieu_co_mat.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/present-deputy?` +
        `&eventId=` + this.state.eventId +
        `&fDate=` + this.state.fromDate +
        `&tDate=` + this.state.toDate
  }

  validate = (values) => {
    const errors = {};

    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }

    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      eventId: values.eventId
    })

    return errors;
  }

  onEventChanged(e, value) {
    this.setState({
      eventId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="Danh sách đại biểu có mặt"/>
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput source="eventId"
                                  validate={required()}
                                  reference="events"
                                  perPage={50}
                                  filter={{filterPath: 'filterSimple'}}
                                  onChange={(e, value) => this.onEventChanged(e,
                                      value)}
                                  sort={{field: 'name', order: 'ASC'}}>
                    <SelectInput source="name"/>
                  </ReferenceInput>
                </Grid>

                <Grid item xs={12} sm={6} >
                <DateInput source="fromDate" validate={required()} {...this.props} />
              </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput source="toDate" validate={required()} {...this.props} />
                </Grid>
              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default PresentDeputyReport;

import BlockIcon from '@material-ui/icons/ViewList';

import BlockList from './BlockList';
import BlockCreate from './BlockCreate';
import BlockEdit from './BlockEdit';

export default {
  list: BlockList,
  create: BlockCreate,
  edit: BlockEdit,
  icon: BlockIcon,
};

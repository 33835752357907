import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import ActiveButton from "./ActiveButton";

const PaymentGatewayConfigurationsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const PaymentGatewayConfigurationsList = props => (
    <List filters={<PaymentGatewayConfigurationsFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="paymentGateway"/>
            <TextField source="merchantCode"/>
            <BooleanField source="active"/>
            <ActiveButton/>
        </Datagrid>
    </List>
);

export default PaymentGatewayConfigurationsList

import ExemptionPolicyIcon from '@material-ui/icons/ViewList';

import ExemptionPolicyList from './ExemptionPolicyList';
import ExemptionPolicyCreate from './ExemptionPolicyCreate';
import ExemptionPolicyEdit from './ExemptionPolicyEdit';

export default {
  list: ExemptionPolicyList,
  create: ExemptionPolicyCreate,
  edit: ExemptionPolicyEdit,
  icon: ExemptionPolicyIcon,
};

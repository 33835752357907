import React from 'react';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress} from '@material-ui/core';
import {showNotification} from 'react-admin';
import axiosInstance from "../../api/axios";
import FileUploadComponent from "../../components/FileUploadComponent";
import CustomerFeeValidation from "../Customer/CustomerFeeValidation";
import SchoolFeeTimeSelectComponent
  from "../../components/SchoolFeeTimeSelectComponent";
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  tableCell: {
    paddingLeft: 2,
    paddingRight: 2,
  }
}
class SchoolFeeImport extends React.Component {

  state = {
    showValidateBtn: false,
    showValidationData: false,
    validationData: [],
    page: 0,
    rowsPerPage: 25,
    showData: [],
    selectedFeeTime: "",
    feeTimes: [],
    isLoading: false
  }

  onFileUpload = (e) => {
    e.preventDefault(); // Stop form submit
    if (this.state.file == null) {
      return;
    }
    this.setState({
      isLoading: true
    })
    this.fileUpload(this.state.file).then(rs => {
      if (rs.data.success) {
        this.setState({
          showValidationData: true,
          validationData: rs.data.data,
          feeTimes: rs.data.feeTimes,
          isLoading: false
        });
        const startIdx = this.state.page * this.state.rowsPerPage;
        const endIdx = (this.state.page + 1) * this.state.rowsPerPage - 1;
        this.setShowData(startIdx, endIdx, null);
      }
    }, err => {
      this.setState({
        isLoading: false
      })
      this.props.showNotification(err.message, 'warning');
    })
  }

  handleChangePage = (event, newPage) => {
    const startIdx = newPage * this.state.rowsPerPage;
    const endIdx = (newPage + 1) * this.state.rowsPerPage - 1;
    this.setShowData(startIdx, endIdx, this.state.selectedFeeTime);
    this.setState({page: newPage});
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: event.target.value});
    const startIdx = this.state.page * event.target.value;
    const endIdx = (this.state.page + 1) * event.target.value - 1;
    this.setShowData(startIdx, endIdx, this.state.selectedFeeTime);
  }

  handleChangeFeeTime = (e) => {
    this.setState({
      selectedFeeTime: e.target.value,
    })
    const startIdx = this.state.page * this.state.rowsPerPage;
    const endIdx = (this.state.page + 1) * this.state.rowsPerPage - 1;
    this.setShowData(startIdx, endIdx, e.target.value);
  }

  setShowData = (startIdx, endIdx, filterFeeTime) => {
    const data = [];
    let filteredData = this.state.validationData;
    if (filterFeeTime) {
      filteredData = this.state.validationData.filter(i => {
        return i.feeTime === filterFeeTime;
      })
    }
    if (endIdx > filteredData.length) {
      endIdx = filteredData.length - 1;
    }
    for (let i = startIdx; i <= endIdx; i++) {
      data.push(filteredData[i]);
    }
    this.setState({showData: data});
  }

  fileUpload(file) {
    const url = `/api/customers/validate-school-fee`;
    const formData = new FormData();
    formData.append('file', file)
    return axiosInstance.post(url, formData);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const rq = {
      data: this.state.validationData.filter(e => e.valid)
    };
    if (this.state.selectedFeeTime) {
      rq.data = rq.data.filter(e => e.feeTime === this.state.selectedFeeTime);
    }
    this.sendFeeMsg(rq).then(rs => {
      if (rs.data.success) {
        this.props.showNotification('Messages has been sent');
        this.setState({
          showValidateBtn: false,
          showValidationData: false,
          validationData: [],
          page: 0,
          rowsPerPage: 10,
          showData: []
        })
      }
    });
  }

  sendFeeMsg(data) {
    const url = `/api/messages/school-fee`;
    return axiosInstance.post(url, data);
  }

  render() {
    return (
        <React.Fragment>
          <Card>
            <CardContent>
              <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                   onFileUpload={this.onFileUpload}/>
              {
                this.state.isLoading && <CircularProgress color="secondary"/>
              }
              {
                this.state.showValidationData && <SchoolFeeTimeSelectComponent
                    onChangeFeeTime={this.handleChangeFeeTime}
                    feeTimes={this.state.feeTimes}
                    selectedFeeTime={this.state.selectedFeeTime}
                    onSubmit={this.onSubmit}/>
              }
              {
                this.state.showValidationData && <CustomerFeeValidation
                    data={this.state.showData}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    handleChangePage={this.handleChangePage}
                    total={this.state.validationData.length}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
              }
            </CardContent>
          </Card>
        </React.Fragment>
    );
  }

}

export default compose(
    connect(null, { showNotification }),
    withStyles(styles)
)(SchoolFeeImport)

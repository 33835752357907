import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const height = '38px';
const myTheme = createMuiTheme({
  palette: {
    secondary: blue,
  },
  overrides:{
    MuiTableRow: {
      root: {
        height: height,
        "& > td ": {
          height: height,
        },
        "& > td > span ": {
          height: height,
          lineHeight: height,
          overflow: 'hidden',
        }
      },
      head: {
        height: height,
        backgroundColor: 'lightgray',
        "& > th ": {
          color: 'black',
          fontWeight: 'bold',
        }
      },
    },
    // Reduce margin-top
    MuiFormControl: {
      marginNormal: {
        marginTop: '10px',
      }
    },
    // Place the 'Switch' in lower position by adding more margin-top
    MuiSwitch: {
      root: {
        marginTop: '14px',
        "& + span ": {
          marginTop: '14px',
        }
      },
    },
    // Add '!important' to <Grid container spacing={8}>
    MuiGrid: {
      container: {
        '&$spacing-xs-8': {
          width: 'calc(100% + 8px) !important',
        }
      }
    }
  }
})

export default myTheme;

import React, {useEffect, useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components'
import MessageFields from './MessageFields'
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

let timeout = null;
export const MessageEdit = ({
                                translate,
                                classes,
                                showToolbar = true,
                                redirect = "list",
                                noCancelButton,
                                event,
                                ...props
                            }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        id: null,
        text: '',
        channel: '',
        category: '',
        link: '',
        linkDisplay: '',
        pin: false,
        subject: '',
        file: '',
        fileUris: [],
        files: [],
        messageImageId: '',
        authStatus: '',
        customerIds: [],
        cuzIds: [],
        images: [],
        isRedirect: false,
        isFistTimeCheck: true
    });

    useEffect(() => {
        if (props.id && values.isFistTimeCheck) {
            getEventDetails();
        }
    }, [values.isFistTimeCheck]);

    function getEventDetails() {
        const url = `/api/messages/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            const merged = Object.assign(values, data);
            setValues({...values, merged});
            merged.isFistTimeCheck = false;
            merged.cuzIds = data.customerIds;
            setValues(merged);
        });
    }

    const handleSelectChange = (prop, e) => {
        if (!e) {
            return
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: e});
        }, 500);
    }
    const handleChange = prop => e => {
        if (!e) {
            return
        }
        let value;
        if (['cuzIds'].includes(prop)) {
            value = e;
        } else {
            if (e.target) {
                value = e.target.value ? e.target.value : e.target.checked;
            } else {
                value = e
            }
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function save() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const formData = new FormData();
        const files = values.files
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
        }

        formData.append('channel', values.channel)
        formData.append('fileUris', values.fileUris)
        formData.append('customerIds', values.customerIds)
        formData.append('text', values.text)
        formData.append('category', values.category)
        formData.append('link', values.link)
        formData.append('linkDisplay', values.linkDisplay)
        formData.append('pin', values.pin)
        formData.append('subject', values.subject)
        formData.append('authStatus', values.authStatus)

        const url = `/api/messages/` + props.id;

        axiosInstance.put(url, formData).then(rs => {
            setLoading(false)
            props.showNotification('Sửa tin nhắn thành công');
            setValues({...values, isRedirect: true});
            props.refreshView()
        }, err => {
            setLoading(false)
            props.showNotification(err.message, 'warning');
        })
    }

    function authorize() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const formData = new FormData();
        const files = values.files
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
        }
        formData.append('channel', values.channel)
        formData.append('fileUris', values.fileUris)
        formData.append('customerIds', values.customerIds)
        formData.append('text', values.text)
        formData.append('category', values.category)
        formData.append('link', values.link)
        formData.append('linkDisplay', values.linkDisplay)
        formData.append('pin', values.pin)
        formData.append('subject', values.subject)
        formData.append('authStatus', 'AUTHORIZED')
        const url = `/api/messages/` + props.id;
        showInfoNotify('Đang gửi tin nhắn, vui lòng chờ trong giây lát')
        axiosInstance.put(url, formData).then(rs => {
            setLoading(false)
            showSuccessNotify('Gửi tin nhắn thành công')
            setValues({...values, isRedirect: true});
            props.refreshView()
        }, err => {
            setLoading(false)
            showErrorNotify(err.message)
        })
    }

    const validate = () => {
        if (!values.text || values.text.trim() === '') {
            props.showNotification('Cần nhập nội dung', 'warning');
            return false;
        }
        if (!values.cuzIds || values.cuzIds.length === 0) {
            props.showNotification('Cần chọn danh sách người nhận', 'warning');
            return false;
        }
        return true
    }


    function changeFile(e) {
        if (e.target.files && e.target.files.length > 3) {
            showErrorNotify('Chọn tối đa 3 hình')
            return
        }
        setValues({...values, files: e.target.files, fileUris: []});
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8} alignItems="flex-start" classes={classes}>
                <MessageFields onChange={handleChange}
                               handleSelectChange={handleSelectChange}
                               defaultValue={values}
                               permissions={props.permissions}
                               files={values.files}
                               save={save}
                               changeFile={changeFile}
                               authorize={authorize}
                               onDirectChange={onDirectChange}
                               loading={loading}
                />
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(null)
)(MessageEdit)

import React, {Component} from 'react';
import {post} from 'axios';
import './style.css';
import env from "../../env";
import {get} from "axios/index";
import {Title} from "react-admin";
import moment from 'moment';
import Swal from 'sweetalert2'

class AttendanceSimu extends Component {

  participants = [];
  validParticipants = [];
  devices = [];
  deviceName = '';
  deviceNo = '';
  maxLengthAttendanceList = 15;
  sendServerTime = 1000 * 10; // 10s
  clearAttendanceTableTime = 1000 * 600 * 5; // 5 minutes
  attendanceBatchKey = 'attendanceBatchKey';
  validDevice = false;
  popupTime = 5000; //popup display time

  componentWillMount() {
    this.getDeviceNo().then(rs => {
      if (rs.status === 200 && rs.data.success) {
        this.deviceName = rs.data.deviceName;
        this.deviceNo = rs.data.deviceNo;
        this.validDevice = true;

        const electron = window.electron || (window.require ? window.require("electron") : null)
        if (electron) {
          electron.ipcRenderer.on('uid', this.onUID)
        }
        if (localStorage.getItem(this.attendanceBatchKey)) {
          this.participants = JSON.parse(localStorage.getItem(this.attendanceBatchKey));
        } else {
          localStorage.setItem(this.attendanceBatchKey, JSON.stringify([]));
        }
        setInterval(this.sendBatchAttendanceInterval.bind(this), this.sendServerTime);

        // temporary disable auto clear, clear when click button only
        // setInterval(this.clearSendServerParticipants.bind(this), this.clearAttendanceTableTime);

        this.getWhiteList().then(response => {
          if (response.status === 200 && response.data.success) {
            this.validParticipants = response.data.cards;
          }
        })

      }
      this.forceUpdate();
    });
  }

  getDeviceNo() {
    const url = `${env.baseURL}/api/attendanceSimu/getDevice`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return get(url, config);
  }

  getWhiteList() {
    const url = `${env.baseURL}/api/attendanceSimu/getUidWhiteList/` + this.deviceNo;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return get(url, config);
  }

  componentWillUnmount() {
    if (this.validDevice) {
      const electron = window.electron || (window.require ? window.require("electron") : null)
      if (electron) {
        electron.ipcRenderer.removeListener('uid', this.onUID)
      }

      this.sendBatchAttendanceInterval();
    }

  }

  clearSendServerParticipants() {
    for (let i = 0; i < this.participants.length; i++) {
      const participant = this.participants[i];
      if (participant.sendServer) {
        this.participants.splice(i, 1);
        i--;
      }
    }
    this.forceUpdate();
  }

  sendBatchAttendanceInterval() {
    const attendanceSimuArr = JSON.parse(localStorage.getItem(this.attendanceBatchKey));
    if (attendanceSimuArr && attendanceSimuArr.length > 0) {
      this.sendBatchAttendance(attendanceSimuArr).then(rs => {
        let responseAttendanceArr = rs.data;
        if (responseAttendanceArr) {
          for (let j = 0; j < responseAttendanceArr.length; j++) {
            const responseAttendance = responseAttendanceArr[j];
            if (attendanceSimuArr !== undefined) {
              for (let i = 0; i < attendanceSimuArr.length; i++) {
                const attendanceSimu = attendanceSimuArr[i];
                if (responseAttendance.uid === attendanceSimu.uid) {
                  attendanceSimuArr.splice(i, 1);
                  i--;
                }
              }
            }
            for (let i = 0; i < this.participants.length; i++) {
              const participant = this.participants[i];
              if (responseAttendance.uid === participant.uid) {
                participant.fullName = responseAttendance.fullName;
                participant.sendServer = true;
              }
            }
          }
          localStorage.setItem(this.attendanceBatchKey, JSON.stringify(attendanceSimuArr));
          this.forceUpdate();
        }
      });
    }
  }

  getCardInfo(uid) {
    for (let i = 0; i < this.validParticipants.length; i++) {
      const card = this.validParticipants[i];
      if (card.uid === uid) {
        return card;
      }
    }
    return null;
  }

  onUID = (event, uid) => {
    if (this.deviceNo) {
      const card = this.getCardInfo(uid);
      const newAttendance = {};
      newAttendance.deviceNo = this.deviceNo;
      newAttendance.uid = uid;
      const atdDate = new Date();
      newAttendance.attendanceTime = moment(atdDate).format('YYYYMMDDHHmmss');
      newAttendance.attendanceTimeDisplay = moment(atdDate).format('HH:mm');
      if (card !== null) {
        newAttendance.fullName = card.cardHolderName;
        newAttendance.valid = true;
        Swal.fire({
          title: "Xin chào \n" + card.cardHolderName,
          timer: this.popupTime,
          showConfirmButton: false,
          customClass: {
            popup: 'green-bg',
            title: 'alert-text',
          }
        });
      } else {
        newAttendance.fullName = uid;
        newAttendance.valid = false;
        Swal.fire({
          title: "Thẻ chưa đăng kí",
          timer: this.popupTime,
          showConfirmButton: false,
          customClass: {
            popup: 'red-bg',
            title: 'alert-text',
          }
        });
      }

      newAttendance.sendServer = false;

      if (this.participants.length === this.maxLengthAttendanceList) {
        this.participants.shift();
      }
      this.participants.push(newAttendance);
      this.forceUpdate();

      let attendanceSimu = JSON.parse(localStorage.getItem(this.attendanceBatchKey));
      attendanceSimu.push(newAttendance);
      localStorage.setItem(this.attendanceBatchKey, JSON.stringify(attendanceSimu));
    }
  };

  sendBatchAttendance(data) {
    const url = `${env.baseURL}/api/attendanceSimu/upload`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return post(url, data, config);
  }

  endEvent = () => {
    this.participants = [];
    this.forceUpdate();
  }


  createHeader = () => {
    let header = [];
    if (this.deviceNo) {
      header.push(<label className="selectAtdSimuLbl">Thiết bị: {this.deviceName}</label>)
      header.push(<button onClick={this.endEvent} className="endEvtBtn button">Kết thúc</button>)
    } else {
      header.push(<label className="errorMsg">Không có thiết bị điểm danh, vui lòng liên hệ quản trị viên để thiết
        lập</label>)
    }
    return header;
  };

  createTable = () => {
    let table = [];
    for (let i = 0; i < this.participants.length; i++) {
      let children = [];
      const participant = this.participants[i];
      children.push(<td key={`${i} _td1`}>{participant.attendanceTimeDisplay}</td>);
      children.push(<td key={`${i} _td2`}>{participant.fullName}</td>);
      if (participant.valid) {
        children.push(<td key={`${i} _td3`} className='validUser'>&#x2713;</td>);
      } else {
        children.push(<td key={`${i} _td3`} className='invalidUser'>&#x2717;</td>);
      }

      table.push(<tr key={`${i} _tr`}>{children}</tr>)
    }
    return table;
  };

  render() {
    return (
      <div>
        <Title title="resources.attendanceSimu.name"/>
        <div className='attendanceSimuContainerDiv'>
          <div className="attendanceSimuSelectContainer">
            {this.createHeader()}

          </div>
          <div className="attendanceSimuTblContainer">
            <span className="atdSimuTblLbl">DANH SÁCH ĐI XE</span>
            <br/><span>({moment(new Date()).format('DD-MM-YYYY')})</span>
            <table className='attendanceSimuTbl'>
              <thead>
              <tr>
                <th>Thời gian</th>
                <th>Họ Tên</th>
                <th>Hợp lệ</th>
              </tr>
              </thead>
              <tbody>
              {this.createTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default AttendanceSimu

import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ContributionProductFields from './ContributionProductFields'

export const ContributionProductEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <ContributionProductFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ContributionProductEdit

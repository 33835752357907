import React from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, ReferenceInput, required, TextInput} from 'react-admin';
import env from "../../env";

const ResidentEnrollmentFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="unitId" reference="apartmentViews"
                            sort={{field: 'displayName', order: 'ASC'}}
                            validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionText="displayName"/>
            </ReferenceInput>
        </Grid>

        <Grid item xs={12} sm={6}>
            <ReferenceInput source="customerId" reference="customers" validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionText="displayName"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="internalCode" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="title" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            {props.record.photoName &&
            <img src={`${env.baseURL}/cus-photo/${props.record.photoName}`}
                 alt="" style={{maxWidth: '250px'}}
            />
            }
        </Grid>

    </React.Fragment>
)

export default ResidentEnrollmentFields

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, submit} from 'redux-form';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import {showErrorNotify, showSuccessNotify} from './ShowNotify'
import Swal from "sweetalert2";
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../api/axios";

const styles = {
    redColor: {
        color: 'red',
    }
};

class DeleteBillBatchButton extends Component {
    showBtn = false;
    path = '';
    title =  "Bạn chắc chắn muốn huỷ kế hoạch?";
    text =  "Những hoá đơn chưa thanh toán cũng sẽ bị huỷ. Không thể hoàn tác sau khi huỷ. ";
    successMessage =  'Huỷ kế hoạch thành công!';

    constructor(props) {
        super(props);
        if (props.record) {
            this.showBtn = props.record.id;
            this.path = props.basePath;

            if (props.title) {
                this.title = props.title
            }
            if (props.text) {
                this.text = props.text
            }
            if (props.successMessage) {
                this.successMessage = props.successMessage
            }
        }
    }

    handleClick = () => {
        const record = this.props.record;
        if (record) {
            Swal.fire({
                title: this.title,
                text: this.text,
                type: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
            })
                .then((result) => {
                    if (result.value) {
                        this.deleteBatch(record.id)
                            .then(({data}) => {
                                showSuccessNotify(this.successMessage)
                                this.props.refreshView();
                                // if (data == null || !data.success) {
                                //     showErrorNotify('Huỷ kế hoạch không thành công, vui lòng kiểm tra lại hoặc liên hệ quản trị viên!')
                                // } else {
                                //     // return is list payment ids
                                //     showSuccessNotify('Huỷ kế hoạch thành công!')
                                //     this.props.refreshView();
                                // }
                            })
                            .catch(error => {
                                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                            })
                            .finally(() => {

                            });
                    }
                });
        } else {
            showErrorNotify("Vui lòng chọn dữ liệu")
        }

    };

    deleteBatch = (batchId) => {
        const url = `/api${this.path}/${batchId}`;
        return axiosInstance.delete(url);
    };

    render() {
        const {classes} = this.props;
        return (
            this.showBtn &&
            <Button onClick={this.handleClick} label="ra.action.destroy" className={classes.redColor}>
                <DeleteIcon/>
            </Button>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    DeleteBillBatchButton
);

import React from 'react';
import {Datagrid, EditButton, Filter, List, refreshView, TextField, TextInput, translate} from 'react-admin';
import {AuthStatusField, DateTimeField, DeleteReceiptButton} from '../../components'
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {MessageCategoryField} from "../../components/MessageCaategory";

const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const MessageList = props => (
    <List filters={<MessageFilter/>} {...props} exporter={false} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="excerpt"/>
            <MessageCategoryField source="category"/>
            <AuthStatusField source="authStatus"/>
            <DateTimeField source="lastUpdatedOn" format="DD/MM/YYYY HH:mm"/>
            <TextField source="lastUpdatedBy"/>
            <EditButton label={""} variant="outlined" {...props}/>
            <DeleteReceiptButton title={'Không thể hoàn tác sau khi xoá, Bạn chắc chắn muốn xoá tin nhắn?'}
                                 path={'/api/messages'}/>
        </Datagrid>
    </List>
);

export default compose(
    connect(undefined, {refreshView}),
    translate,
    withStyles(null)
)(MessageList)
